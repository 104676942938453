import React, { Component } from 'react';
import { connect } from 'react-redux';
import { facebookSdkOptions } from 'src/config';
import ModalHandler from 'src/components/ModalHandler';
import { prepareFacebookSDK } from 'src/actions/facebookSDK';
import { prepareGoogleTagManager } from 'src/actions/appEvents';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/appPublic.scss';
import NotificationHandler from 'src/components/NotificationHandler';

class AppPublic extends Component {
    componentDidMount() {
        const {
            prepareFacebookSdkAction,
            prepareGoogleTagManagerAction
        } = this.props;
        prepareGoogleTagManagerAction(process.env.NODE_ENV !== 'production');
        prepareFacebookSdkAction(facebookSdkOptions);
    }

    render() {
        const { content } = this.props;
        return (
            <div className={styles.publicAppWrapper}>
                <div className={styles.content}>
                    {content}
                </div>
                <ModalHandler />
                <NotificationHandler />
            </div>
        );
    }
}

AppPublic.propTypes = {
    content: PropTypes.node.isRequired,
    prepareFacebookSdkAction: PropTypes.func.isRequired,
    prepareGoogleTagManagerAction: PropTypes.func.isRequired
};

export default connect(null, {
    prepareFacebookSdkAction: prepareFacebookSDK,
    prepareGoogleTagManagerAction: prepareGoogleTagManager
})(AppPublic);

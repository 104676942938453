import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { defaultLoadingState } from 'src/selectors/utils';

export const selectHash = (state) => state.externalNetworkAuthentication.hash;
export const selectCreatedByUserName = (state) => state.externalNetworkAuthentication.createdByUserName;
export const getChosenAuthAdAccountIdsByPlatform = (state) => state.externalNetworkAuthentication.chosenAuthAdAccountIdsByPlatform;

export const getAsyncStatesByAction = (state) => state.externalNetworkAuthentication.asyncStatesByAction;

export const selectExternalNetworkAuthenticationBootstrappingState = createSelector(
    [
        getAsyncStatesByAction
    ],
    (asyncActions) => _get(asyncActions, ['fetch', 'state'], defaultLoadingState)
);

export const selectExternalNetworkAuthenticationConnectState = createSelector(
    [
        getAsyncStatesByAction
    ],
    (asyncActions) => _get(asyncActions, ['connect', 'state'], defaultLoadingState)
);

export const selectExternalNetworkAuthenticationConfirm = createSelector(
    [
        getAsyncStatesByAction
    ],
    (asyncActions) => _get(asyncActions, ['confirm', 'state'], defaultLoadingState)
);

export const makeSelectAllChosenAuthAdAccountIds = () => createSelector(
    [
        getChosenAuthAdAccountIdsByPlatform
    ], (chosenAuthAdAccountIdByPlatform) => {
        let allAuthAdAccountIds = [];
        Object.values(chosenAuthAdAccountIdByPlatform).forEach((authAdAccountIds) => {
            allAuthAdAccountIds = allAuthAdAccountIds.concat(authAdAccountIds);
        });
        return allAuthAdAccountIds;
    }
);

export const makeSelectAllChosenAuthAdAccountIdsByPlatformType = () => createSelector(
    [
        getChosenAuthAdAccountIdsByPlatform,
        (_, platformType) => platformType
    ], (chosenAuthAdAccountIdByPlatform, platformType) => chosenAuthAdAccountIdByPlatform[platformType] || []
);

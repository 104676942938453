import { combineReducers } from 'redux';
import { socialNetworkAdAccountAuthenticationConnectSuccess } from 'src/actions/socialNetworkAdAccountAuthentication';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case socialNetworkAdAccountAuthenticationConnectSuccess.type: {
            const { authAdAccounts } = payload;
            return Object.keys(authAdAccounts);
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case socialNetworkAdAccountAuthenticationConnectSuccess.type: {
            const { authAdAccounts } = payload;
            return Object.assign({}, state, authAdAccounts);
        }
        default:
            return state;
    }
};

export const authAdAccounts = combineReducers({
    allIds,
    byId
});

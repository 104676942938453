import { browserHistory } from 'react-router';
import configureStore from 'src/store/app.public.configureStore';
import { Provider } from 'react-redux';
import React from 'react';
import Root from 'src/components/Root';
import routes from 'src/routes.public';
import { syncHistoryWithStore } from 'react-router-redux';

const store = configureStore();
const enhancedHistory = syncHistoryWithStore(browserHistory, store);

const AppProviderPublic = () => (
    <Provider store={store}>
        <Root history={enhancedHistory} routes={routes} />
    </Provider>
);

export default AppProviderPublic;

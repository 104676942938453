import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    getScopesByUseCases,
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest,
    popUpWindowWithCallbackUrlAction
} from 'src/sagas/utils';
import {
    TIKTOK_AUTH_USER_CONNECT_REQUEST,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    tiktokAuthUserConnectError,
    tiktokAuthUserConnectSuccess,
    tiktokAuthUserExternalConnectError,
    tiktokAuthUserExternalConnectSuccess
} from 'src/actions/tiktokAuthUsers';
import {
    parseAuthTransaction,
    parseAuthTransactionsRequirements
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { getTiktokAuthenticationCallbackUrl } from 'src/utils/authUsers';

function* tiktokAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'tiktok');
    const url = getTiktokAuthenticationCallbackUrl(finalScopes);
    try {
        const { response, serverError } = yield call(popUpWindowWithCallbackUrlAction, url, 'Tiktok');
        if (response) {
            const serverRequest = createServerRequest({
                code: response.code,
                platformUsername: profile.platformUsername,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/finish-tiktok-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { authTransaction, connectedProfileIds } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(tiktokAuthUserConnectSuccess(identifier, parsedAuthTransaction, parsedAuthTransactionRequirements));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(tiktokAuthUserConnectError(identifier, applicationError.message));
    }
}

function* tiktokAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'tiktok');
    const url = getTiktokAuthenticationCallbackUrl(finalScopes);
    try {
        const { response, serverError } = yield call(popUpWindowWithCallbackUrlAction, url, 'Tiktok');
        if (response) {
            const serverRequest = createServerRequest({
                code: response.code,
                platformUsername: profile.platformUsername,
                hash,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/finish-external-tiktok-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { authTransaction, connectedProfileIds } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(tiktokAuthUserExternalConnectSuccess(
                    identifier,
                    parsedAuthTransaction,
                    parsedAuthTransactionRequirements
                ));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(tiktokAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

export default function* tiktokAuthUsersSagas() {
    yield takeEvery(TIKTOK_AUTH_USER_CONNECT_REQUEST, tiktokAuthUserConnectRequest);
    yield takeEvery(TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST, tiktokAuthUserExternalConnectRequest);
}

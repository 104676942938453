export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SHOW_TYPED_NOTIFICATION = 'SHOW_TYPED_NOTIFICATION';
export const FLAG_NOTIFICATION_AS_ERROR = 'FLAG_NOTIFICATION_AS_ERROR';

const defaultDuration = 5;

export function hideNotification(id) {
    return {
        type: HIDE_NOTIFICATION,
        payload: { id }
    };
}

export const showNotification = (message, level = 'success', autoDismiss = defaultDuration, dismissible = true) => ({
    type: SHOW_NOTIFICATION,
    payload: {
        message, level, autoDismiss, dismissible
    }
});

export const setNotificationToError = (id) => ({
    type: FLAG_NOTIFICATION_AS_ERROR,
    payload: { id }
}
);

export const showTypedNotification = (notificationType, notificationProps, identifier = false, level = 'success', autoDismiss = defaultDuration, dismissible = true) => ({
    type: SHOW_TYPED_NOTIFICATION,
    payload: {
        notificationType,
        notificationProps,
        level,
        autoDismiss,
        dismissible,
        identifier
    }
});

export const showDashboardMetricsAddSuccessNotification = (dashboardId, dashboardName, metricName, metricId) => showTypedNotification('DashboardMetricsAddSuccess', {
    dashboardId, dashboardName, metricName, metricId
});

export const showDashboardSuccessNotification = (dashboardId, dashboardName) => showTypedNotification('DashboardAddSuccess', {
    dashboardId, dashboardName
});

export const showGroupAddSuccessNotification = (groupId, groupName) => showTypedNotification('GroupAddSuccess', {
    groupId, groupName
});

export const showFileExportSuccessOrErrorNotification = (fileExportIdentifier, fileName, format) => showTypedNotification('FileExportSuccessOrError', { fileExportIdentifier, fileName, format }, fileExportIdentifier, 'success', 0, false);

export const showFolderAddSuccessNotification = (folderName) => showTypedNotification('FolderAddSuccess', { folderName });

export const showProfileBatchAddingSuccessOrWarning = (profileAddOrRemoveResults) => showTypedNotification('ProfileBatchAddingSuccessOrWarning', { profileAddOrRemoveResults });

export const showFoldersAndDashboardMoveSuccessOrWarning = (foldersResponses, dashboardsResponses, toFolderId) => showTypedNotification('FoldersAndDashboardMoveSuccessOrWarning', { foldersResponses, dashboardsResponses, toFolderId });

export const showFoldersAndDashboardDeleteSuccessOrWarning = (foldersResponses, dashboardsResponses) => showTypedNotification('FoldersAndDashboardDeleteSuccessOrWarning', { foldersResponses, dashboardsResponses });

export const showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification = (connectedProfileIds = [], allProfileIds = [], connectedAdAccountIds = [], allAdAccountIds = []) => showTypedNotification('AuthenticatedProfilesByAuthUserSuccessOrWarning', {
    connectedProfileIds, allProfileIds, connectedAdAccountIds, allAdAccountIds
});

export const INITIALIZE_LIST = 'INITIALIZE_LIST';
export const UNREGISTER_LIST = 'UNREGISTER_LIST';
export const UNREGISTER_LIST_ITEMS = 'UNREGISTER_LIST_ITEMS';
export const REGISTER_LIST_ITEMS = 'REGISTER_LIST_ITEMS';
export const TOGGLE_LIST_VALUE = 'TOGGLE_LIST_VALUE';
export const UNCHECK_LIST_VALUE = 'UNCHECK_LIST_VALUE';
export const CHECK_ALL_LIST_VALUES = 'CHECK_ALL_LIST_VALUES';
export const UNCHECK_ALL_LIST_VALUES = 'UNCHECK_ALL_LIST_VALUES';
export const UPDATE_LIST_VALUES = 'UPDATE_LIST_VALUES';
export const CHECK_LIST_VALUES = 'CHECK_LIST_VALUES';
export const UNCHECK_LIST_VALUES = 'UNCHECK_LIST_VALUES';

export const initializeList = (listName, items = []) => ({
    type: INITIALIZE_LIST,
    payload: {
        listName,
        items
    }
});

export const unregisterList = (listName) => ({
    type: UNREGISTER_LIST,
    payload: { listName }
});

export const unregisterListItems = (listName, fieldNames) => ({
    type: UNREGISTER_LIST_ITEMS,
    payload: { listName, fieldNames }
});

export const registerListItems = (listName, fieldNames, overRightPrevItems = true) => ({
    type: REGISTER_LIST_ITEMS,
    payload: { listName, fieldNames, overRightPrevItems }
});

export const toggleListValue = (listName, fieldName) => ({
    type: TOGGLE_LIST_VALUE,
    payload: {
        listName,
        fieldName
    }
});

export const uncheckListValue = (listName, fieldName) => ({
    type: UNCHECK_LIST_VALUE,
    payload: {
        listName,
        fieldName
    }
});

export const checkListValues = (listName, fieldNames) => ({
    type: CHECK_LIST_VALUES,
    payload: {
        listName,
        fieldNames
    }
});

export const uncheckListValues = (listName, fieldNames) => ({
    type: UNCHECK_LIST_VALUES,
    payload: {
        listName,
        fieldNames
    }
});

export const checkAllListValues = (listName) => ({
    type: CHECK_ALL_LIST_VALUES,
    payload: { listName }
});

export const uncheckAllListValues = (listName) => ({
    type: UNCHECK_ALL_LIST_VALUES,
    payload: { listName }
});

export const updateListValues = (listName, fieldNames) => ({
    type: UPDATE_LIST_VALUES,
    payload: { listName, fieldNames }
});

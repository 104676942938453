import authTransactionSagas from 'src/sagas/authTransactions';
import { formActionSaga } from 'redux-form-saga';
import googleTagManagerSagas from 'src/sagas/googleTagManager';
import graphAuthUsers from 'src/sagas/graphAuthUsers';
import linkedInAuthUsersSagas from 'src/sagas/linkedInAuthUsers';
import modals from 'src/sagas/modals';
import facebookSDKSagas from 'src/sagas/facebookSDK';
import snapchatAuthUsersSagas from 'src/sagas/snapchatAuthUsers';
import threadsAuthUsersSagas from 'src/sagas/threadsAuthUsers';
import twitterAuthUsers from 'src/sagas/twitterAuthUsers';
import youtubeAuthUsersSagas from 'src/sagas/youtubeAuthUsers';
import tiktokAuthUsersSagas from 'src/sagas/tiktokAuthUsers';
import socialNetworkProfileAuthentications from 'src/sagas/socialNetworkProfileAuthentications';
import externalAdAccountAuthentication from 'src/sagas/socialNetworkAdAccountAuthentication';

export default [
    authTransactionSagas,
    formActionSaga,
    graphAuthUsers,
    googleTagManagerSagas,
    linkedInAuthUsersSagas,
    modals,
    facebookSDKSagas,
    snapchatAuthUsersSagas,
    twitterAuthUsers,
    youtubeAuthUsersSagas,
    tiktokAuthUsersSagas,
    threadsAuthUsersSagas,
    socialNetworkProfileAuthentications,
    externalAdAccountAuthentication
];

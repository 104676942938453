import { createSelector } from 'reselect';
import _get from 'lodash/get';

export const getClientLimits = (state) => state.client.clientLimits;
export const getClientSetting = (state) => state.client.clientSetting;

export const selectClientName = createSelector(
    [
        getClientSetting
    ],
    (clientSetting) => clientSetting.clientName
);

export const selectClientIsOnboard = createSelector(
    [
        getClientSetting
    ],
    (clientSetting) => clientSetting.onboarded
);

export const selectUserLimitAndUsage = createSelector(
    [
        getClientLimits
    ],
    (clientLimits) => {
        const { limits, usage } = clientLimits;
        return {
            limit: limits.users || 0,
            usage: usage.users || 0
        };
    }
);

export const selectNumberFreeUsers = createSelector(
    [
        selectUserLimitAndUsage
    ],
    (userLimitAndUsage) => userLimitAndUsage.limit - userLimitAndUsage.usage
);

export const selectNumberOfDataPushTasksAllowed = createSelector(
    [
        getClientLimits
    ],
    (clientLimits) => _get(clientLimits, 'limits.dataPushTasks', 0)
);

export const selectDataPushTaskLimitAndUsage = createSelector(
    [
        getClientLimits
    ],
    (clientLimits) => {
        const { limits, usage } = clientLimits;
        return {
            limit: limits.dataPushTasks || 0,
            usage: usage.dataPushTasks || 0,
            dataPushTaskNetworks: limits.dataPushTaskNetworks || []
        };
    }
);

export const selectDataPushTaskNetworks = createSelector(
    [
        getClientLimits
    ],
    (clientLimits) => _get(clientLimits, 'limits.dataPushTaskNetworks', [])
);

import { createFormAction } from 'redux-form-saga';

export const PROFILE_ADD_REQUEST = 'PROFILE_ADD_REQUEST_BACKGROUND';
export const PROFILE_ADD_SUCCESS = 'PROFILE_ADD_SUCCESS_BACKGROUND';
export const PROFILE_ADD_ERROR = 'PROFILE_ADD_ERROR_BACKGROUND';

export const PROFILE_ADD_STATE_CLEANUP = 'PROFILE_ADD_STATE_CLEANUP';

export const BULK_PROFILE_ADD_REQUEST = 'BULK_PROFILE_ADD_REQUEST';
export const BULK_PROFILE_ADD_SUCCESS = 'BULK_PROFILE_ADD_SUCCESS';
export const BULK_PROFILE_ADD_ERROR = 'BULK_PROFILE_ADD_ERROR';

export const ONBOARDING_BULK_PROFILE_ADD_REQUEST = 'ONBOARDING_BULK_PROFILE_ADD_REQUEST';
export const ONBOARDING_BULK_PROFILE_ADD_SUCCESS = 'ONBOARDING_BULK_PROFILE_ADD_SUCCESS';
export const ONBOARDING_BULK_PROFILE_ADD_ERROR = 'ONBOARDING_BULK_PROFILE_ADD_ERROR';

export const PROFILE_DELETE_ERROR = 'PROFILE_DELETE_ERROR';
export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST';
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS';

export const PROFILE_FORCE_DELETE_REQUEST = 'PROFILE_FORCE_DELETE_REQUEST';
export const PROFILE_FORCE_DELETE_SUCCESS = 'PROFILE_FORCE_DELETE_SUCCESS';
export const PROFILE_FORCE_DELETE_ERROR = 'PROFILE_FORCE_DELETE_ERROR';

export const PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR = 'PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR';
export const PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST = 'PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST';
export const PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS = 'PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS';
export const CLEANUP_PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP = 'CLEANUP_PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP';

export const PROFILE_CUSTOMIZE_ERROR = 'PROFILE_CUSTOMIZE_ERROR';
export const PROFILE_CUSTOMIZE_REQUEST = 'PROFILE_CUSTOMIZE_REQUEST';
export const PROFILE_CUSTOMIZE_SUCCESS = 'PROFILE_CUSTOMIZE_SUCCESS';

export const UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST = 'UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST';
export const UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS = 'UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS';
export const UPDATE_INSTAGRAM_PLATFORM_USERNAME_ERROR = 'UPDATE_INSTAGRAM_PLATFORM_USERNAME_ERROR';

export const UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST = 'UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST';
export const UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS = 'UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS';
export const UPDATE_TIKTOK_PLATFORM_USERNAME_ERROR = 'UPDATE_TIKTOK_PLATFORM_USERNAME_ERROR';

export const EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_REQUEST = 'EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_REQUEST';
export const EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_SUCCESS = 'EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_SUCCESS';
export const EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_ERROR = 'EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_ERROR';

export const profileAddStateCleanup = (id) => ({
    type: PROFILE_ADD_STATE_CLEANUP,
    payload: {
        id
    }
});

export const profileAddRequest = (id) => ({
    type: PROFILE_ADD_REQUEST,
    payload: {
        id
    }
});

export const profileAddSuccess = (id) => ({
    type: PROFILE_ADD_SUCCESS,
    payload: {
        id
    }
});

export const profileAddError = (id, error) => ({
    type: PROFILE_ADD_ERROR,
    payload: { id, error }
});

export const profileAddRequestFormAction = createFormAction(
    profileAddRequest,
    [PROFILE_ADD_SUCCESS, PROFILE_ADD_ERROR]
);

export const bulkProfileAddRequest = (checkedProfileIds) => ({
    type: BULK_PROFILE_ADD_REQUEST,
    payload: { checkedProfileIds }
});

export const bulkProfileAddSuccess = (profiles) => ({
    type: BULK_PROFILE_ADD_SUCCESS,
    payload: { profiles }
});

export const bulkProfileAddError = (error) => ({
    type: BULK_PROFILE_ADD_ERROR,
    payload: error
});

export const onboardingBulkProfileAddRequest = (checkedProfileIds) => ({
    type: ONBOARDING_BULK_PROFILE_ADD_REQUEST,
    payload: { checkedProfileIds }
});

export const onboardingBulkProfileAddSuccess = (profiles) => ({
    type: ONBOARDING_BULK_PROFILE_ADD_SUCCESS,
    payload: { profiles }
});

export const onboardingBulkProfileAddError = (error) => ({
    type: ONBOARDING_BULK_PROFILE_ADD_ERROR,
    payload: error
});

export const profileDeleteRequest = (profileIds) => ({
    type: PROFILE_DELETE_REQUEST,
    payload: {
        profileIds
    }
});

export const profileDeleteSuccess = (profileIds) => ({
    type: PROFILE_DELETE_SUCCESS,
    payload: {
        profileIds
    }
});

export const profileDeleteError = (error) => ({
    type: PROFILE_DELETE_ERROR,
    payload: error
});

export const profileDeleteFormAction = createFormAction(
    (values) => profileDeleteRequest(values.accountIds),
    [PROFILE_DELETE_SUCCESS, PROFILE_DELETE_ERROR]
);

export const profileForceDeleteRequest = ({ ids }) => ({
    type: PROFILE_FORCE_DELETE_REQUEST,
    payload: {
        profileIds: ids
    }
});

export const profileForceDeleteSuccess = (profileIds) => ({
    type: PROFILE_FORCE_DELETE_SUCCESS,
    payload: {
        profileIds
    }
});

export const profileForceDeleteError = (error) => ({
    type: PROFILE_FORCE_DELETE_ERROR,
    payload: error
});

export const profileForceDeleteFormAction = createFormAction(
    profileForceDeleteRequest,
    [PROFILE_FORCE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_ERROR]
);

export const profileAddToOrRemoveFromGroupRequest = (profileIds, selectedGroupOptions) => ({
    type: PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST,
    payload: {
        profileIds,
        selectedGroupOptions,
    }
});

export const profileAddToOrRemoveFromGroupSuccess = (profileAddOrRemoveResults) => ({
    type: PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS,
    payload: {
        profileAddOrRemoveResults
    }
});

export const profileAddToOrRemoveFromGroupError = (error) => ({
    type: PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR,
    payload: { error }
});

export const profileCustomizeRequest = ({
    accountId, appendix, color, favorite
}) => ({
    type: PROFILE_CUSTOMIZE_REQUEST,
    payload: {
        profileId: accountId,
        appendix,
        color,
        favorite
    }
});

export const profileCustomizeSuccess = (profile) => ({
    type: PROFILE_CUSTOMIZE_SUCCESS,
    payload: {
        profile
    }
});

export const profileCustomizeError = (error) => ({
    type: PROFILE_CUSTOMIZE_ERROR,
    payload: error
});

export const profileCustomizeRequestFormAction = createFormAction(profileCustomizeRequest, [PROFILE_CUSTOMIZE_SUCCESS, PROFILE_CUSTOMIZE_ERROR]);

export const updateInstagramPlatformUsernameRequest = (profileId, platformUsername) => ({
    type: UPDATE_INSTAGRAM_PLATFORM_USERNAME_REQUEST,
    payload: {
        profileId,
        platformUsername
    }
});

export const updateInstagramPlatformUsernameSuccess = (profileId, profile) => ({
    type: UPDATE_INSTAGRAM_PLATFORM_USERNAME_SUCCESS,
    payload: { profileId, profile }
});

export const updateInstagramPlatformUsernameError = (profileId, error) => ({
    type: UPDATE_INSTAGRAM_PLATFORM_USERNAME_ERROR,
    payload: { profileId, error }
});

export const updateTiktokPlatformUsernameRequest = (profileId, platformUsername) => ({
    type: UPDATE_TIKTOK_PLATFORM_USERNAME_REQUEST,
    payload: {
        profileId,
        platformUsername
    }
});

export const updateTiktokPlatformUsernameSuccess = (profileId, profile) => ({
    type: UPDATE_TIKTOK_PLATFORM_USERNAME_SUCCESS,
    payload: { profileId, profile }
});

export const updateTiktokPlatformUsernameError = (profileId, error) => ({
    type: UPDATE_TIKTOK_PLATFORM_USERNAME_ERROR,
    payload: { profileId, error }
});

export const cleanupProfileAddToOrRemoveFromGroup = () => ({
    type: CLEANUP_PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP
});

export const externalNetworkAuthenticationLinkGetRequest = () => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_REQUEST
});

export const externalNetworkAuthenticationLinkGetSuccess = () => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_SUCCESS
});

export const externalNetworkAuthenticationLinkGetError = (error) => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_LINK_GET_ERROR,
    payload: { error }
});

import { makeOrderBySorter, makeSearchQueryFilter } from 'src/selectors/accounts';
import {
    createDeepEqualSelector,
    createShallowEqualSelector,
    makeGroupFilter,
    makePropertyExtractor,
    makeStringSorter,
    defaultLoadingState
} from 'src/selectors/utils';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { generateAccountTempId } from 'src/utils/accountSearch';
import { makeSelectAdAccountUseCasesByAdAccountId } from 'src/selectors/accountUseCases';
import { makeSelectAdAccountUseCasesAuthUsersByAdAccountId } from 'src/selectors/adAccountUseCaseAuthUsers';
import { makeSelectAuthUsersAuthAdAccountUseCasesByAdAccountId } from 'src/selectors/authUserAuthAdAccountUseCases';
import { getFoundedEntities } from 'src/utils/array';

export const getAdAccountIdsFromStore = (state) => state.entities.adAccounts.allIds;
export const getAdAccountsFromStore = (state) => state.entities.adAccounts.byId;
export const getAsyncStates = (state) => state.entities.adAccounts.asyncStates;

const getAdAccountByIdFromStore = (state, adAccountId) => {
    const adAccounts = getAdAccountsFromStore(state);
    return adAccounts[adAccountId] || false;
};

const selectAdAccountUseCasesByAdAccountId = makeSelectAdAccountUseCasesByAdAccountId();
const selectAdAccountUseCasesAuthUsersByAdAccountId = makeSelectAdAccountUseCasesAuthUsersByAdAccountId();
const selectAuthUsersAuthProfileUseCasesByAdAccountId = makeSelectAuthUsersAuthAdAccountUseCasesByAdAccountId();

export const makeSelectAdAccountById = () => createShallowEqualSelector(
    [
        (state, id) => {
            const adAccount = getAdAccountByIdFromStore(state, id);
            const useCases = selectAdAccountUseCasesByAdAccountId(state, id);
            const accountUseCasesAuthUsers = selectAdAccountUseCasesAuthUsersByAdAccountId(state, id);
            const authUsersAuthAccountUseCases = selectAuthUsersAuthProfileUseCasesByAdAccountId(state, id);
            return {
                adAccount, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases
            };
        }
    ],
    ({
        adAccount, useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases
    }) => {
        if (adAccount) {
            return Object.assign({}, adAccount, { useCases, accountUseCasesAuthUsers, authUsersAuthAccountUseCases });
        }
        return false;
    }
);

export const makeSelectAdAccountsByIds = () => {
    // each entity needs it's own selector
    const selectorCache = {};
    const getSelector = (id) => {
        const selector = _get(selectorCache, id);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [id]: makeSelectAdAccountById() });
        return selectorCache[id];
    };
    return createShallowEqualSelector(
        [
            (state, ids) => {
                const entities = {};
                ids.forEach((id) => Object.assign(entities, { [id]: getSelector(id)(state, id) }));
                return entities;
            },
            (state, ids) => ids
        ],
        (adAccountsById, ids) => getFoundedEntities(ids, adAccountsById)
    );
};

export const makeSelectOnlyAdAccountById = () => createSelector(
    [
        getAdAccountsFromStore,
        (state, id) => id
    ],
    (adAccounts, id) => adAccounts[id] || null
);

export const makeSelectOnlyAdAccountByIds = () => createSelector(
    [
        getAdAccountsFromStore,
        (state, ids) => ids
    ],
    (adAccountsById, ids) => getFoundedEntities(ids, adAccountsById)
);

export const makeSelectAdAccounts = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    return (state) => selectAdAccountsByIds(state, getAdAccountIdsFromStore(state));
};

export const defaultNameStringOrderWithExcludedPrefix = () => makeStringSorter('name', 'asc');

export const makeSelectAdAccountsFilteredByQuery = () => {
    const selectAdAccounts = makeSelectAdAccounts();
    const searchQueryFilter = makeSearchQueryFilter();
    return createSelector(
        [
            selectAdAccounts,
            (_, filterQuery) => filterQuery
        ],
        (adAccounts, filterQuery) => searchQueryFilter(adAccounts, filterQuery).sort(defaultNameStringOrderWithExcludedPrefix())
    );
};

export const selectAdAccountIds = (state) => getAdAccountIdsFromStore(state);

export const makeSelectAdAccountIdsWithListFiltersApplied = () => {
    const selectAdAccounts = makeSelectAdAccounts();
    const searchQueryFilter = makeSearchQueryFilter();
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('id');
    const groupFilter = makeGroupFilter();

    return createDeepEqualSelector([(state, filterBy, group, orderBy) => {
        const allProfiles = selectAdAccounts(state);
        const queryFiltered = searchQueryFilter(allProfiles, filterBy);
        const groupFiltered = groupFilter(queryFiltered, group);
        const sorted = sorter(groupFiltered, orderBy);
        return idsExtractor(sorted);
    }], (ids) => ids);
};

export const selectAdAccountAddingState = createSelector(
    [
        getAsyncStates,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, ['adding', id], defaultLoadingState)
);

export const selectIsBulkAdAccountAdding = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['bulkAdding', 'state', 'isPending'], false)
);

export const makeSelectAdAccountByTempId = () => {
    const selectAdAccounts = makeSelectAdAccounts();
    return createSelector(
        [
            selectAdAccounts,
            (_, tempId) => tempId
        ],
        (adAccounts, tempId) => {
            const index = adAccounts.map((adAccount) => generateAccountTempId(adAccount)).indexOf(tempId);
            return index > -1 ? adAccounts[index] : null;
        }
    );
};

export const selectPlatformTypeByAdAccountId = (state, adAccountId) => {
    const adAccount = getAdAccountByIdFromStore(state, adAccountId);

    if (adAccount) {
        return adAccount.platformType;
    }
    return '';
};

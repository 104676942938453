import { createSelector } from 'reselect';

export const getAuthAdAccountIdsFromStore = (state) => state.entities.authAdAccounts.allIds;
export const getAuthAdAccountsFromStore = (state) => state.entities.authAdAccounts.byId;

export const makeSelectAuthAdAccounts = () => createSelector(
    [
        getAuthAdAccountIdsFromStore,
        getAuthAdAccountsFromStore
    ], (allIds, byId) => allIds.map((id) => byId[id])
);

export const makeSelectAuthAdAccountsByIds = () => createSelector(
    [
        (_, ids) => ids,
        getAuthAdAccountsFromStore
    ], (ids, byId) => {
        const result = [];
        ids.forEach((id) => {
            if (byId[id]) {
                result.push(byId[id]);
            }
        });
        return result;
    }
);

export const makeSelectAuthAdAccountByPlatformType = () => {
    const selectAuthAdAccounts = makeSelectAuthAdAccounts();
    return createSelector(
        [
            selectAuthAdAccounts,
            (_, platformType) => platformType
        ],
        (authAdAccounts, platformType) => authAdAccounts.filter((authAdAccount) => authAdAccount.platformType === platformType)
    );
};

export const makeSelectAuthAdAccountById = () => createSelector(
    [
        getAuthAdAccountsFromStore,
        (_, id) => id
    ],
    (authAdAccounts, id) => authAdAccounts[id] || false
);

import { createFormAction } from 'redux-form-saga';

export const CUSTOM_METRIC_SAVE_AS_REQUEST = 'CUSTOM_METRIC_SAVE_AS_REQUEST';
export const CUSTOM_METRIC_SAVE_AS_SUCCESS = 'CUSTOM_METRIC_SAVE_AS_SUCCESS';
export const CUSTOM_METRIC_SAVE_AS_ERROR = 'CUSTOM_METRIC_SAVE_AS_ERROR';

export const CUSTOM_METRIC_DELETE_REQUEST = 'CUSTOM_METRIC_DELETE_REQUEST';
export const CUSTOM_METRIC_DELETE_SUCCESS = 'CUSTOM_METRIC_DELETE_SUCCESS';
export const CUSTOM_METRIC_DELETE_ERROR = 'CUSTOM_METRIC_DELETE_ERROR';

export const CUSTOM_METRIC_DUPLICATE_REQUEST = 'CUSTOM_METRIC_DUPLICATE_REQUEST';
export const CUSTOM_METRIC_DUPLICATE_SUCCESS = 'CUSTOM_METRIC_DUPLICATE_SUCCESS';
export const CUSTOM_METRIC_DUPLICATE_ERROR = 'CUSTOM_METRIC_DUPLICATE_ERROR';

export const SET_QQL_METRIC_DATA_REQUEST_STATUS = 'SET_QQL_METRIC_DATA_REQUEST_STATUS';
export const SET_QQL_RUN_STATUS = 'SET_RUN_QQL_STATUS';

export const SET_QQL_FORM_VALUES = 'SET_QQL_FORM_VALUES';
export const RESET_ALL_QQL_PARAMS = 'RESET_ALL_QQL_PARAMS';

export const customMetricSaveAsRequest = (metric) => ({
    type: CUSTOM_METRIC_SAVE_AS_REQUEST,
    payload: { metric }
});

export const customMetricSaveAsSuccess = (metric, dashboardMetricSettings, isNewMetric) => ({
    type: CUSTOM_METRIC_SAVE_AS_SUCCESS,
    payload: { metric, dashboardMetricSettings, isNewMetric }
});

export const customMetricSaveAsError = (error) => ({
    type: CUSTOM_METRIC_SAVE_AS_ERROR,
    payload: error
});

export const customMetricSaveAsFormAction = createFormAction(customMetricSaveAsRequest, [
    CUSTOM_METRIC_SAVE_AS_SUCCESS, CUSTOM_METRIC_SAVE_AS_ERROR
]);

export const customMetricDeleteRequest = ({ id }) => ({
    type: CUSTOM_METRIC_DELETE_REQUEST,
    payload: { id }
});

export const customMetricDeleteSuccess = (id) => ({
    type: CUSTOM_METRIC_DELETE_SUCCESS,
    payload: { id }
});

export const customMetricDeleteError = (error, id) => ({
    type: CUSTOM_METRIC_DELETE_ERROR,
    payload: { error, id }
});

export const customMetricDeleteFormAction = createFormAction(customMetricDeleteRequest, [
    CUSTOM_METRIC_DELETE_SUCCESS, CUSTOM_METRIC_DELETE_ERROR
]);

export const setQQLMetricDataRequestStatus = (hasData, loading, error) => ({
    type: SET_QQL_METRIC_DATA_REQUEST_STATUS,
    payload: {
        hasData,
        loading,
        error
    }
});

export const setQQLFormValues = (qqlFormValues, qqlSelectorValues = {}) => ({
    type: SET_QQL_FORM_VALUES,
    payload: { qqlFormValues, qqlSelectorValues }
});

export const resetAllQQLParams = () => ({
    type: RESET_ALL_QQL_PARAMS
});

export const setRunQQLStatus = (runQQLStatus) => ({
    type: SET_QQL_RUN_STATUS,
    payload: {
        runQQLStatus
    }
});

export const customMetricDuplicateRequest = (metric) => ({
    type: CUSTOM_METRIC_DUPLICATE_REQUEST,
    payload: { metric }
});

export const customMetricDuplicateSuccess = (metric) => ({
    type: CUSTOM_METRIC_DUPLICATE_SUCCESS,
    payload: { metric }
});

export const customMetricDuplicateError = (error) => ({
    type: CUSTOM_METRIC_DUPLICATE_ERROR,
    payload: error
});

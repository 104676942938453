import { createFormAction } from 'redux-form-saga';
import { createAction } from '@reduxjs/toolkit';

export const PROFILE_USE_CASES_SET_REQUEST = 'PROFILE_USE_CASES_SET_REQUEST';
export const PROFILE_USE_CASES_SET_ERROR = 'PROFILE_USE_CASES_SET_ERROR';
export const PROFILE_USE_CASES_SET_SUCCESS = 'PROFILE_USE_CASES_SET_SUCCESS';

export const BULK_PROFILES_USE_CASES_UPDATE_REQUEST = 'BULK_PROFILES_USE_CASES_UPDATE_REQUEST';
export const BULK_PROFILES_USE_CASES_UPDATE_ERROR = 'BULK_PROFILES_USE_CASES_UPDATE_ERROR';
export const BULK_PROFILES_USE_CASES_UPDATE_SUCCESS = 'BULK_PROFILES_USE_CASES_UPDATE_SUCCESS';

export const profileUseCasesSetRequest = (formValues) => ({
    type: PROFILE_USE_CASES_SET_REQUEST,
    payload: { formValues }
});

export const profileUseCasesSetSuccess = (profileId) => ({
    type: PROFILE_USE_CASES_SET_SUCCESS,
    payload: {
        profileId
    }
});

export const profileUseCasesSetError = (error) => ({
    type: PROFILE_USE_CASES_SET_ERROR,
    payload: error
});

export const profileUseCasesSetFormAction = createFormAction(
    profileUseCasesSetRequest,
    [PROFILE_USE_CASES_SET_SUCCESS, PROFILE_USE_CASES_SET_ERROR]
);

export const removeProfileUseCaseRequest = createAction('REMOVE_PROFILE_USE_CASE_REQUEST', (accountId, useCaseId) => ({ payload: { accountId, useCaseId } }));
export const removeProfileUseCaseSuccess = createAction('REMOVE_PROFILE_USE_CASE_SUCCESS', (accountId, useCaseId) => ({ payload: { accountId, useCaseId } }));
export const removeProfileUseCaseError = createAction('REMOVE_PROFILE_USE_CASE_ERROR', (accountId, useCaseId, error) => ({ payload: { accountId, useCaseId, error } }));

export const bulkProfilesUseCasesUpdateRequest = (checkedAndUncheckedUseCasesPerProfile) => ({
    type: BULK_PROFILES_USE_CASES_UPDATE_REQUEST,
    payload: { checkedAndUncheckedUseCasesPerProfile }
});

export const bulkProfilesUseCasesUpdateSuccess = () => ({
    type: BULK_PROFILES_USE_CASES_UPDATE_SUCCESS
});

export const bulkProfilesUseCasesUpdateError = () => ({
    type: BULK_PROFILES_USE_CASES_UPDATE_ERROR,
    payload: {}
});

export const EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST = 'EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST';
export const EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS = 'EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS';
export const EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_ERROR = 'EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_ERROR';

export const EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST = 'EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST';
export const EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS = 'EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS';
export const EXTERNAL_SOCIAL_NETWORK_CONNECT_ERROR = 'EXTERNAL_SOCIAL_NETWORK_CONNECT_ERROR';

export const EXTERNAL_GRAPH_FORCE_CONNECT_REQUEST = 'EXTERNAL_GRAPH_FORCE_CONNECT_REQUEST_BACKGROUND';
export const EXTERNAL_LINKED_IN_FORCE_CONNECT_REQUEST = 'EXTERNAL_LINKED_IN_FORCE_CONNECT_REQUEST_BACKGROUND';
export const EXTERNAL_SNAPCHAT_FORCE_CONNECT_REQUEST = 'EXTERNAL_SNAPCHAT_FORCE_CONNECT_REQUEST_BACKGROUND';

export const EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST = 'EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST';
export const EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_SUCCESS = 'EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_SUCCESS';
export const EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_ERROR = 'EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_ERROR';

export const EXTERNAL_SOCIAL_NETWORK_BY_HASH_REQUEST = 'EXTERNAL_SOCIAL_NETWORK_BY_HASH_REQUEST';
export const EXTERNAL_SOCIAL_NETWORK_BY_HASH_SUCCESS = 'EXTERNAL_SOCIAL_NETWORK_BY_HASH_SUCCESS';
export const EXTERNAL_SOCIAL_NETWORK_BY_HASH_ERROR = 'EXTERNAL_SOCIAL_NETWORK_BY_HASH_ERROR';

export const externalSocialNetworkConnectRequest = (network, useCaseIds) => ({
    type: EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST,
    payload: { network, useCaseIds }
});

export const externalSocialNetworkConnectSuccess = (authProfiles = {}, authUsersAuthProfileUseCases = [], authUser = null) => ({
    type: EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS,
    payload: { authProfiles, authUsersAuthProfileUseCases, authUser }
});

export const externalSocialNetworkConnectError = (error) => ({
    type: EXTERNAL_SOCIAL_NETWORK_CONNECT_ERROR,
    payload: { error }
});

export const externalSocialNetworkByHashRequest = (hash) => ({
    type: EXTERNAL_SOCIAL_NETWORK_BY_HASH_REQUEST,
    payload: { hash }
});

export const externalSocialNetworkByHashSuccess = (authProfiles = {}, authUsersAuthProfileUseCases = [], authUser = null) => ({
    type: EXTERNAL_SOCIAL_NETWORK_BY_HASH_SUCCESS,
    payload: { authProfiles, authUsersAuthProfileUseCases, authUser }
});

export const externalSocialNetworkByHashError = (error) => ({
    type: EXTERNAL_SOCIAL_NETWORK_BY_HASH_ERROR,
    payload: { error }
});

export const externalGraphForceConnectRequest = (accessToken) => ({
    type: EXTERNAL_GRAPH_FORCE_CONNECT_REQUEST,
    payload: { accessToken }
});

export const externalLinkedInForceConnectRequest = (
    accessToken,
    refreshToken,
    validUntil,
    refreshTokenValidUntil,
    platformUserId,
    platformUserName
) => ({
    type: EXTERNAL_LINKED_IN_FORCE_CONNECT_REQUEST,
    payload: {
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName
    }
});

export const externalSnapchatForceConnectRequest = (
    accessToken,
    expiresIn,
    refreshAccessToken,
    platformUserId,
    displayName
) => ({
    type: EXTERNAL_SNAPCHAT_FORCE_CONNECT_REQUEST,
    payload: {
        accessToken,
        expiresIn,
        refreshAccessToken,
        platformUserId,
        displayName
    }
});

export const externalNetworkAuthenticationBootstrapRequest = (hash) => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST,
    payload: { hash }
});

export const externalNetworkAuthenticationBootstrapSuccess = (hash, useCases, platformPermissions, createdByUserName) => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS,
    payload: {
        hash,
        useCases,
        platformPermissions,
        createdByUserName
    }
});

export const externalNetworkAuthenticationBootstrapError = (error) => ({
    type: EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_ERROR,
    payload: { error }
});

export const externalAuthUserShowPagesConfirmRequest = (authUserIds) => ({
    type: EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST,
    payload: { authUserIds }
});

export const externalAuthUserShowPagesConfirmSuccess = () => ({
    type: EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_SUCCESS
});

export const externalAuthUserShowPagesConfirmError = (error) => ({
    type: EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_ERROR,
    payload: { error }
});

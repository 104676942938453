import { combineReducers } from 'redux';
import { EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS } from 'src/actions/socialNetworkProfileAuthentication';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS: {
            const { authProfiles } = payload;
            return Object.keys(authProfiles);
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS: {
            const { authProfiles } = payload;
            return Object.assign({}, state, authProfiles);
        }
        default:
            return state;
    }
};

export const authProfiles = combineReducers({
    allIds,
    byId
});

import * as customPropTypes from 'src/customPropTypes';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const Impersonate = ({ location, params }) => {
    const {
        loginWithRedirect
    } = useAuth0();
    const { userId, spaceId } = params;

    const { query } = location;
    const appState = {};
    if (query.redirectUrl) {
        appState.impersonateRedirect = query.redirectUrl;
    }
    useEffect(() => {
        window.location.href = loginWithRedirect({
            prompt: 'login',
            userId,
            spaceId,
            impersonate: true,
            scope: 'read:impersonate',
            appState
        });
    }, []);

    return null;
};

Impersonate.propTypes = {
    location: customPropTypes.location.isRequired
};

export default Impersonate;

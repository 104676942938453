import AppError from 'src/components/errors/AppError';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';

const ErrorPage = ({ location }) => {
    const { message } = location.query;
    return <AppError error={{ message: `${message || 'Something went wrong'}.` }} withLogout={false} />;
};

ErrorPage.propTypes = {
    location: customPropTypes.location.isRequired
};

export default ErrorPage;

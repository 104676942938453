import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/public/externalSocialNetworkAuthentication.scss';
import { selectCreatedByUserName } from 'src/selectors/externalNetworkAuthentication';
import * as customPropTypes from 'src/customPropTypes';
import _lowerCase from 'lodash/lowerCase';

const ExternalSocialNetworkAuthentication = (props) => {
    const { createdByUserName, accountType } = props;
    const accountName = _lowerCase(accountType);
    return (
        <div className={styles.header}>
            <div className={styles.title}>
                {
                    `${createdByUserName} sent you a request to authenticate ${accountName} for analysis in quintly`
                }
            </div>
            <div className={styles.subtitle}>
                {
                    `To authenticate ${accountName}, continue to the social networks you'd like to provide private analysis for.`
                }
            </div>
        </div>
    );
};

ExternalSocialNetworkAuthentication.propTypes = {
    createdByUserName: PropTypes.string.isRequired,
    accountType: customPropTypes.accountTypes.isRequired
};

const mapStateToProps = (state) => ({
    createdByUserName: selectCreatedByUserName(state)
});

export default connect(mapStateToProps)(ExternalSocialNetworkAuthentication);

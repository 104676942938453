import * as Sentry from '@sentry/react';
import { sentryConfig } from 'src/config';

let isSentrySetup = false;
export const getIsSentrySetup = () => isSentrySetup;

export const initializeSentry = () => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init(sentryConfig);
        isSentrySetup = true;
    }
};

export const captureException = (error, extras = {}) => {
    if (Object.keys(extras).length > 0) {
        return Sentry.withScope((scope) => {
            scope.setExtras(extras);
            Sentry.captureException(error);
        });
    }
    return Sentry.captureException(error);
};

export const registerUserToSentry = (userId, clientId) => {
    if (isSentrySetup) {
        Sentry.setUser({
            id: userId,
            clientId
        });
    }
};

export const showReportDialog = (eventId) => {
    Sentry.showReportDialog({ eventId });
};

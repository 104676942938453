import { createShallowEqualSelector, makePropertyExtractor, makeStringSorter } from 'src/selectors/utils';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { getUseCasesByProfileFromStore, getUseCasesByAdAccountFromStore } from 'src/selectors/accountUseCases';
import { getBrokenAccountsUseCasesByPlatformType } from 'src/utils/accountInsights';

export const getAuthUserIdsFromStore = (state) => state.entities.authUsers.allIds;
export const getAuthUsersFromStore = (state) => state.entities.authUsers.byId;
export const getAsyncStatesByAction = (state) => state.entities.authUsers.asyncStatesByAction;

export const selectAuthenticateByIdentifier = createSelector(
    [
        (state) => getAsyncStatesByAction(state).authenticate,
        (_, identifier) => identifier
    ],
    (asyncActions, identifier) => _get(asyncActions, identifier, false)
);

export const selectAuthUserCount = createSelector(
    [
        getAuthUserIdsFromStore
    ],
    (authUserIds) => authUserIds.length
);

export const makeSelectAuthUserById = () => createSelector(
    [
        getAuthUsersFromStore,
        (_, authUserId) => authUserId
    ],
    (authUsers, authUserId) => authUsers[authUserId] || false
);

export const makeSelectAuthUserByIds = () => {
    const selectAuthUserById = makeSelectAuthUserById();
    return createShallowEqualSelector(
        [
            (state, ids) => {
                const entities = {};
                ids.forEach((id) => {
                    Object.assign(entities, { [id]: selectAuthUserById(state, id) });
                });
                return entities;
            },
            (state, ids) => ids
        ],
        (authUsersById, ids) => ids.map((id) => authUsersById[id])
    );
};

export const makeSelectAuthUsers = () => {
    const selectAuthUsersByIds = makeSelectAuthUserByIds();
    return (state) => selectAuthUsersByIds(state, getAuthUserIdsFromStore(state));
};

export const makeSearchQueryFilter = () => createSelector(
    [
        (items) => items,
        (_, filterQuery) => filterQuery
    ],
    (items, filterQuery) => {
        if (filterQuery.length < 1) {
            return items;
        }
        const filterQueryToLower = filterQuery.toLowerCase();
        return items.filter((authUser) => (
            authUser.name.toLowerCase().indexOf(filterQueryToLower) > -1
        ));
    }
);

export const makeOrderBySorter = () => createSelector(
    [
        (items) => items,
        (_, orderBy) => orderBy
    ],
    (items, orderBy = 'alphabetically') => {
        if (orderBy === 'alphabetically') {
            return items.slice().sort(makeStringSorter('name', 'asc'));
        }
        if (orderBy === 'recentlyUpdated') {
            return items.slice().sort(makeStringSorter('lastUpdatedTime', 'desc'));
        }
        return items;
    }
);

export const makeEnvironmentTypeFilter = () => createSelector(
    [
        (items) => items,
        (_, platformType) => platformType
    ],
    (items, platformType) => {
        if (platformType === 'all') {
            return items;
        }
        return items.filter((item) => item.environment === platformType);
    }
);

export const makeSelectAuthUserIdsWithListFiltersApplied = () => {
    const environmentTypeFilter = makeEnvironmentTypeFilter();
    const selectAuthUsers = makeSelectAuthUsers();
    const searchQueryFilter = makeSearchQueryFilter();
    const sorter = makeOrderBySorter();
    const idsExtractor = makePropertyExtractor('id');

    return (state, environment, filterBy, orderBy) => {
        const allAuthUsers = selectAuthUsers(state);
        const envFiltered = environmentTypeFilter(allAuthUsers, environment);
        const queryFiltered = searchQueryFilter(envFiltered, filterBy);
        const sorted = sorter(queryFiltered, orderBy);
        return idsExtractor(sorted);
    };
};

export const makeSelectBrokenProfileUseCasesByPlatformType = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    const selectAuthUsers = makeSelectAuthUsers();
    return (state) => {
        const profiles = selectProfilesByIds(state, Object.keys(getUseCasesByProfileFromStore(state)));
        const authUsers = selectAuthUsers(state);
        return getBrokenAccountsUseCasesByPlatformType(profiles, authUsers, 'profiles');
    };
};

export const makeSelectBrokenAdAccountUseCasesByPlatformType = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    const selectAuthUsers = makeSelectAuthUsers();
    return (state) => {
        const adAccounts = selectAdAccountsByIds(state, Object.keys(getUseCasesByAdAccountFromStore(state)));
        const authUsers = selectAuthUsers(state);
        return getBrokenAccountsUseCasesByPlatformType(adAccounts, authUsers, 'adAccounts');
    };
};

export const makeSelectOrderedByNameAuthUsers = () => {
    const selectAuthUserByIds = makeSelectAuthUserByIds();
    return createSelector(
        [
            selectAuthUserByIds
        ],
        (authUsers) => authUsers.slice().sort(makeStringSorter('name', 'asc'))
    );
};

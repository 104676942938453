import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const UserRegistration = () => {
    const {
        loginWithRedirect
    } = useAuth0();

    useEffect(() => {
        window.location.href = loginWithRedirect({ prompt: 'login', screen_hint: 'signup' });
    }, []);

    return null;
};

UserRegistration.propTypes = {};

export default UserRegistration;

import { createSelector } from 'reselect';

export const getAuthUsersAuthAdAccountUseCases = (state) => state.entities.authUsersAuthAdAccountUseCases;

export const makeSelectAuthUsersAuthAdAccountUseCasesByAuthAdAccountId = () => createSelector(
    [
        getAuthUsersAuthAdAccountUseCases,
        (_, authAdAccountId) => authAdAccountId
    ],
    (allAuthAdAccountUseCasesAuthUsers, authAdAccountId) => allAuthAdAccountUseCasesAuthUsers.filter((authAdAccountUseCasesAuthUser) => authAdAccountUseCasesAuthUser.authAdAccountId === authAdAccountId)
);

export const makeSelectAuthUsersAuthAdAccountUseCasesByAdAccountId = () => createSelector(
    [
        getAuthUsersAuthAdAccountUseCases,
        (_, adAccountId) => adAccountId
    ],
    (allAuthAdAccountUseCasesAuthUsers, adAccountId) => allAuthAdAccountUseCasesAuthUsers.filter((profilesUseCasesAuthUser) => profilesUseCasesAuthUser.adAccountId === adAccountId)
);

export const makeSelectAuthUsersAuthAdAccountUseCasesByAdAuthUserId = () => createSelector(
    [
        getAuthUsersAuthAdAccountUseCases,
        (_, authUserId) => authUserId
    ],
    (allAuthAdAccountUseCasesAuthUsers, authUserId) => allAuthAdAccountUseCasesAuthUsers.filter((authAdAccountUseCasesAuthUser) => authAdAccountUseCasesAuthUser.userId === authUserId)
);

import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import authUserConnectionStateStyles from 'src/stylesheets/views/public/authUserConnectionState.scss';
import PropTypes from 'prop-types';
import AccountInfoListItemByAccountType from 'src/components/AccountInfoListItemByAccountType';

const doesUseCaseExists = (authUserAuthAdAccountUseCases, useCaseId) => authUserAuthAdAccountUseCases.some((authUserAuthAdAccountUseCase) => authUserAuthAdAccountUseCase.useCaseId === useCaseId);

const AuthUserAuthAccountUseCaseRow = (props) => {
    const {
        authAccount, authUserAuthAccountUseCases, platformUseCases, accountType
    } = props;
    return (
        <ListTableRow hasBorder={false}>
            <ListTableCell padded={false}>
                <AccountInfoListItemByAccountType account={authAccount} accountType={accountType} />
            </ListTableCell>
            {
                platformUseCases.map((platformUseCase) => {
                    const useCaseExists = doesUseCaseExists(authUserAuthAccountUseCases, platformUseCase.id);
                    return (
                        <ListTableCell key={platformUseCase.id}>
                            <div className={styles.useCase}>
                                {
                                    useCaseExists
                                    && (
                                        <IcomoonIcon icon="success" className={authUserConnectionStateStyles.stateIcon} />
                                    )
                                }
                                {
                                    !useCaseExists
                                    && <div className={styles.unavailable} />
                                }
                            </div>
                        </ListTableCell>
                    );
                })
            }
        </ListTableRow>
    );
};

AuthUserAuthAccountUseCaseRow.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    authAccount: PropTypes.oneOfType([customPropTypes.authAdAccount, customPropTypes.authProfile]).isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    authUserAuthAccountUseCases: PropTypes.oneOfType([customPropTypes.authUserAuthAdAccountUseCases, customPropTypes.authUserAuthProfileUseCases]).isRequired
};

export default AuthUserAuthAccountUseCaseRow;

import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    THREADS_AUTH_USER_CONNECT_REQUEST,
    THREADS_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    threadsAuthUserConnectError
} from 'src/actions/threadsAuthUsers';
import {
    getScopesByUseCases,
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { getThreadsAuthenticationCallbackUrl } from 'src/utils/authUsers';

function* threadsAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;
    try {
        const {
            useCaseIds,
            authTransactionRequirementIds
        } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
        const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'threads');
        const url = getThreadsAuthenticationCallbackUrl(finalScopes);

        const serverRequest = createServerRequest({
            url,
            platformUsername: profile.platformUsername,
            authTransactionId,
            authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
        });
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/redirect-threads-oauth-authUser');
        if (response) {
            const { stateFulRedirect } = response.jsonData;
            window.location.href = stateFulRedirect;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(threadsAuthUserConnectError(identifier, applicationError.message));
    }
}

function* threadsAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;
    try {
        const {
            useCaseIds,
            authTransactionRequirementIds
        } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
        const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'threads');
        const url = getThreadsAuthenticationCallbackUrl(finalScopes);

        const serverRequest = createServerRequest({
            url,
            platformUsername: profile.platformUsername,
            authTransactionId,
            authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
            hash
        });
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/redirect-threads-oauth-authUser-public');
        if (response) {
            const { stateFulRedirect } = response.jsonData;
            window.location.href = stateFulRedirect;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(threadsAuthUserConnectError(identifier, applicationError.message));
    }
}

export default function* threadsAuthUsersSagas() {
    yield takeEvery(THREADS_AUTH_USER_CONNECT_REQUEST, threadsAuthUserConnectRequest);
    yield takeEvery(THREADS_AUTH_USER_EXTERNAL_CONNECT_REQUEST, threadsAuthUserExternalConnectRequest);
}

import { combineReducers } from 'redux';
import {
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST,
    SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS
} from 'src/actions/profileSearch';
import {
    EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST,
    EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS,
    EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_ERROR,
    EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST,
    EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS,
    EXTERNAL_SOCIAL_NETWORK_CONNECT_ERROR,
    EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST,
    EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_ERROR,
    EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_SUCCESS
} from 'src/actions/socialNetworkProfileAuthentication';
import {
    socialNetworkAdAccountAuthenticationConnectSuccess,
    socialNetworkAdAccountAuthenticationConnectRequest,
    socialNetworkAdAccountAuthenticationConnectError,
    socialNetworkAdAccountAuthenticationBootstrapRequest,
    socialNetworkAdAccountAuthenticationBootstrapSuccess,
    socialNetworkAdAccountAuthenticationBootstrapError,
    socialNetworkAdAccountAuthenticationConfirmError,
    socialNetworkAdAccountAuthenticationConfirmSuccess,
    socialNetworkAdAccountAuthenticationConfirmRequest,
    authAdAccountSelectionConfirm
} from 'src/actions/socialNetworkAdAccountAuthentication';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

export const hash = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS:
        case socialNetworkAdAccountAuthenticationBootstrapSuccess.type: {
            return payload.hash;
        }
        default:
            return state;
    }
};

export const createdByUserName = (state = '', action) => {
    const { type, payload } = action;
    switch (type) {
        case EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS:
        case socialNetworkAdAccountAuthenticationBootstrapSuccess.type: {
            return payload.createdByUserName;
        }
        default:
            return state;
    }
};

const adAccountIdsByPlatformTypeDefault = {
    meta: []
};

export const chosenAuthAdAccountIdsByPlatform = (state = adAccountIdsByPlatformTypeDefault, action) => {
    const { type, payload } = action;
    if (type === authAdAccountSelectionConfirm.type) {
        const { platformType, authAdAccountIds } = payload;
        return Object.assign({}, state, { [platformType]: authAdAccountIds });
    }
    return state;
};

const asyncStatesByAction = combineReducers({
    fetch: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST, socialNetworkAdAccountAuthenticationBootstrapRequest.type],
        [EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS, socialNetworkAdAccountAuthenticationBootstrapSuccess.type],
        [EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_ERROR, socialNetworkAdAccountAuthenticationBootstrapError.type]
    ),
    connect: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST, socialNetworkAdAccountAuthenticationConnectRequest.type, SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST],
        [EXTERNAL_SOCIAL_NETWORK_CONNECT_SUCCESS, socialNetworkAdAccountAuthenticationConnectSuccess.type, SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS],
        [EXTERNAL_SOCIAL_NETWORK_CONNECT_ERROR, socialNetworkAdAccountAuthenticationConnectError.type, SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR]
    ),
    confirm: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST, socialNetworkAdAccountAuthenticationConfirmRequest.type],
        [EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_SUCCESS, socialNetworkAdAccountAuthenticationConfirmSuccess.type],
        [EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_ERROR, socialNetworkAdAccountAuthenticationConfirmError.type]
    ),
});

export const externalNetworkAuthentication = combineReducers({
    hash,
    createdByUserName,
    asyncStatesByAction,
    chosenAuthAdAccountIdsByPlatform
});

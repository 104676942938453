import { combineReducers } from 'redux';
import { entities } from 'src/reducers/entities.public';
import { reducer as formReducer } from 'redux-form';
import { overlays } from 'src/reducers/overlays';
import { routerReducer as routing } from 'react-router-redux';
import { notifications } from 'src/reducers/notifications';
import { externalNetworkAuthentication } from 'src/reducers/externalNetworkAuthentication';
import { client } from 'src/reducers/client';

const quintlyPublic = combineReducers({
    entities,
    form: formReducer,
    overlays,
    routing,
    notifications,
    externalNetworkAuthentication,
    client
});

export default quintlyPublic;

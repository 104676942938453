import { createFormAction } from 'redux-form-saga';

export const CREATE_SPACE_REQUEST = 'CREATE_SPACE_REQUEST';
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS';
export const CREATE_SPACE_ERROR = 'CREATE_SPACE_ERROR';

export const UPDATE_SPACE_REQUEST = 'UPDATE_SPACE_REQUEST';
export const UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS';
export const UPDATE_SPACE_ERROR = 'UPDATE_SPACE_ERROR';

export const DELETE_SPACE_REQUEST = 'DELETE_SPACE_REQUEST';
export const DELETE_SPACE_SUCCESS = 'DELETE_SPACE_SUCCESS';
export const DELETE_SPACE_ERROR = 'DELETE_SPACE_ERROR';

export const createSpaceRequest = ({
    name, pageLimit, customLogo, adAccountLimit
}) => ({
    type: CREATE_SPACE_REQUEST,
    payload: {
        name, pageLimit, customLogo, adAccountLimit
    }
});

export const createSpaceSuccess = (space, currentUserId) => ({
    type: CREATE_SPACE_SUCCESS,
    payload: {
        space,
        currentUserId
    }
});

export const createSpaceError = (error) => ({
    type: CREATE_SPACE_ERROR,
    payload: error
});

export const updateSpaceRequest = ({
    id, name, pageLimit, customLogo, adAccountLimit
}) => ({
    type: UPDATE_SPACE_REQUEST,
    payload: {
        id, name, pageLimit, customLogo, adAccountLimit
    }
});

export const updateSpaceSuccess = (space) => ({
    type: UPDATE_SPACE_SUCCESS,
    payload: { space }
});

export const updateSpaceError = (error) => ({
    type: UPDATE_SPACE_ERROR,
    payload: error
});

export const updateSpaceRequestFormAction = createFormAction(
    updateSpaceRequest,
    [UPDATE_SPACE_SUCCESS, UPDATE_SPACE_ERROR]
);

export const createSpaceRequestFormAction = createFormAction(
    createSpaceRequest,
    [CREATE_SPACE_SUCCESS, CREATE_SPACE_ERROR]
);

export const deleteSpaceRequest = ({ spaceId }) => ({
    type: DELETE_SPACE_REQUEST,
    payload: { spaceId }
});

export const deleteSpaceSuccess = (spaceId) => ({
    type: DELETE_SPACE_SUCCESS,
    payload: { spaceId }
});

export const deleteSpaceError = (error) => ({
    type: DELETE_SPACE_ERROR,
    payload: error
});

export const deleteSpaceFormRequest = createFormAction(
    deleteSpaceRequest,
    [DELETE_SPACE_SUCCESS, DELETE_SPACE_ERROR]
);

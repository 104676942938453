import { createFormAction } from 'redux-form-saga';

export const GROUP_ADD_REQUEST = 'GROUP_ADD_REQUEST';
export const GROUP_ADD_SUCCESS = 'GROUP_ADD_SUCCESS';
export const GROUP_ADD_ERROR = 'GROUP_ADD_ERROR';
export const GROUP_EDIT_REQUEST = 'GROUP_EDIT_REQUEST';
export const GROUP_EDIT_SUCCESS = 'GROUP_EDIT_SUCCESS';
export const GROUP_EDIT_ERROR = 'GROUP_EDIT_ERROR';
export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_FORCE_DELETE_REQUEST = 'GROUP_FORCE_DELETE_REQUEST';
export const GROUP_FORCE_DELETE_SUCCESS = 'GROUP_FORCE_DELETE_SUCCESS';
export const GROUP_FORCE_DELETE_ERROR = 'GROUP_FORCE_DELETE_ERROR';

export const groupAddRequest = ({ name }) => ({
    type: GROUP_ADD_REQUEST,
    payload: { name }
});

export const groupAddRequestFormAction = createFormAction(groupAddRequest, [GROUP_ADD_SUCCESS, GROUP_ADD_ERROR]);

export const groupAddSuccess = (group) => ({
    type: GROUP_ADD_SUCCESS,
    payload: { group }
});

export const groupAddError = (error) => ({
    type: GROUP_ADD_ERROR,
    payload: error
});

export const groupEditRequest = (id, newName) => ({
    type: GROUP_EDIT_REQUEST,
    payload: { id, newName }
});

export const groupEditSuccess = (id, group) => ({
    type: GROUP_EDIT_SUCCESS,
    payload: { id, group }
});

export const groupEditError = (id, error) => ({
    type: GROUP_EDIT_ERROR,
    payload: { id, error }
});

export const groupDeleteRequest = ({ groupIds }) => ({
    type: GROUP_DELETE_REQUEST,
    payload: {
        groupIds
    }
});

export const groupDeleteSuccess = (groupIds) => ({
    type: GROUP_DELETE_SUCCESS,
    payload: {
        groupIds
    }
});

export const groupDeleteError = (error) => ({
    type: GROUP_DELETE_ERROR,
    payload: error
});

export const groupDeleteFormRequest = createFormAction(
    groupDeleteRequest,
    [GROUP_DELETE_SUCCESS, GROUP_DELETE_ERROR]
);

export const groupForceDeleteRequest = ({ ids }) => ({
    type: GROUP_FORCE_DELETE_REQUEST,
    payload: {
        groupIds: ids
    }
});

export const groupForceDeleteSuccess = (groupIds) => ({
    type: GROUP_FORCE_DELETE_SUCCESS,
    payload: {
        groupIds
    }
});

export const groupForceDeleteError = (error) => ({
    type: GROUP_FORCE_DELETE_ERROR,
    payload: error
});

export const groupForceDeleteFormAction = createFormAction(
    groupForceDeleteRequest,
    [GROUP_FORCE_DELETE_SUCCESS, GROUP_FORCE_DELETE_ERROR]
);

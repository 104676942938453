import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import AppProviderPublic from 'src/components/AppProviderPublic';  // eslint-disable-line
import configuration from 'react-global-configuration';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import 'src/stylesheets/globalDefault.scss';
import { loginRoute } from 'src/routePaths';
import { initializeSentry } from 'src/utils/sentry';
import { Auth0Provider } from '@auth0/auth0-react';

// this needs to be done in order to ensure that redirect urls with hash identifier are working.
if (window.location.hash && window.location.pathname === loginRoute && window.location.search && window.location.search.includes('returnUrl')) {
    window.location.href = `${window.location.origin}${window.location.pathname}${window.location.search}${window.location.hash.replace('#', '%23')}`;
} else {
    initializeSentry();
    configuration.set({}, { freeze: false });
    render(
        <StrictMode>
            <Auth0Provider
              domain={global.AUTH0_DOMAIN}
              clientId={global.AUTH0_CLIENT_ID}
              audience={global.AUTH0_AUDIENCE}
              redirectUri={window.location.origin}
            >
                <AppProviderPublic />
            </Auth0Provider>
        </StrictMode>, document.getElementById('root')
    );
}

import { createAction } from '@reduxjs/toolkit';

export const socialNetworkAdAccountAuthenticationBootstrapRequest = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_BOOTSTRAP_REQUEST', (hash) => ({ payload: { hash } }));

export const socialNetworkAdAccountAuthenticationBootstrapSuccess = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_BOOTSTRAP_SUCCESS', (
    hash,
    useCases,
    platformPermissions,
    createdByUserName
) => ({
    payload: {
        hash,
        useCases,
        platformPermissions,
        createdByUserName
    }
}));

export const socialNetworkAdAccountAuthenticationBootstrapError = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_BOOTSTRAP_ERROR', (error) => ({ payload: { error } }));

export const socialNetworkAdAccountAuthenticationConfirmRequest = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONFIRM_REQUEST', (authUserIds, chosenAuthAdAccountIds) => ({ payload: { authUserIds, chosenAuthAdAccountIds } }));
export const socialNetworkAdAccountAuthenticationConfirmSuccess = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONFIRM_SUCCESS');
export const socialNetworkAdAccountAuthenticationConfirmError = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONFIRM_ERROR', (error) => ({ payload: { error } }));

export const socialNetworkAdAccountAuthenticationConnectRequest = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONNECT_REQUEST', (platformType, useCaseIds) => ({ payload: { platformType, useCaseIds } }));
export const socialNetworkAdAccountAuthenticationConnectSuccess = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONNECT_SUCCESS', (authAdAccounts = [], authUserAuthAdAccountUseCases = [], authUser = null) => ({ payload: { authAdAccounts, authUserAuthAdAccountUseCases, authUser } }));
export const socialNetworkAdAccountAuthenticationConnectError = createAction('SOCIAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_CONNECT_ERROR', (error) => ({ payload: { error } }));

export const metaAdAccountAuthenticationForceConnectRequest = createAction('META_AD_ACCOUNT_AUTHENTICATION_FORCE_CONNECT_REQUEST', (accessToken) => ({ payload: { accessToken } }));

export const authAdAccountSelectionConfirm = createAction('AUTH_AD_ACCOUNT_SELECTION_CONFIRM', (platformType, authAdAccountIds) => ({ payload: { platformType, authAdAccountIds } }));

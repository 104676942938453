export const PREPARE_GOOGLE_TAG_MANAGER = 'PREPARE_GOOGLE_TAG_MANAGER';
export const ONBOARDING_STARTED_EVENT = 'ONBOARDING_STARTED_EVENT';
export const APP_STARTED_EVENT = 'APP_STARTED_EVENT';
export const DELETE_ACCOUNT_EVENT = 'DELETE_ACCOUNT_EVENT';
export const ADJUST_PACKAGE_EVENT = 'ADJUST_PACKAGE_EVENT';
export const USER_ONBOARDING_COMPLETION_EVENT = 'USER_ONBOARDING_COMPLETION_EVENT';
export const FIRE_CUSTOM_EVENT = 'FIRE_CUSTOM_EVENT';

export const prepareGoogleTagManager = (isAgent = false) => ({
    type: PREPARE_GOOGLE_TAG_MANAGER,
    payload: { isAgent }
});

export const userOnboardingCompletionEvent = () => ({
    type: USER_ONBOARDING_COMPLETION_EVENT
});

export const onboardingStartedEvent = () => ({
    type: ONBOARDING_STARTED_EVENT
});

export const appStartedEvent = () => ({
    type: APP_STARTED_EVENT
});

export const deleteAccountEvent = () => ({
    type: DELETE_ACCOUNT_EVENT
});

export const adjustPackageEvent = (packageStatus) => ({
    type: ADJUST_PACKAGE_EVENT,
    payload: { packageStatus }
});

export const fireCustomEvent = (eventName) => ({
    type: FIRE_CUSTOM_EVENT,
    payload: { eventName },
});

import { createFormAction } from 'redux-form-saga';

export const FOLDER_ADD_REQUEST = 'FOLDER_ADD_REQUEST';
export const FOLDER_ADD_SUCCESS = 'FOLDER_ADD_SUCCESS';
export const FOLDER_ADD_ERROR = 'FOLDER_ADD_ERROR';

export const FOLDER_EDIT_REQUEST = 'FOLDER_EDIT_REQUEST';
export const FOLDER_EDIT_SUCCESS = 'FOLDER_EDIT_SUCCESS';
export const FOLDER_EDIT_ERROR = 'FOLDER_EDIT_ERROR';

export const FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST = 'FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST';
export const FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS = 'FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS';
export const FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_ERROR = 'FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_ERROR';

export const FOLDERS_AND_DASHBOARDS_DELETE_REQUEST = 'FOLDERS_AND_DASHBOARDS_DELETE_REQUEST';
export const FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS = 'FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS';
export const FOLDERS_AND_DASHBOARDS_DELETE_ERROR = 'FOLDERS_AND_DASHBOARDS_DELETE_ERROR';

export const CHANGE_MODAL_FOLDER_LOCATION = 'CHANGE_MODAL_FOLDER_LOCATION';

export const changeModalFolderLocation = (toFolderId) => ({
    type: CHANGE_MODAL_FOLDER_LOCATION,
    payload: { toFolderId }
});

export const folderAddRequest = (newFolder) => ({
    type: FOLDER_ADD_REQUEST,
    payload: { newFolder }
});

export const folderAddSuccess = (folders) => ({
    type: FOLDER_ADD_SUCCESS,
    payload: { folders }
});

export const folderAddError = (error) => ({
    type: FOLDER_ADD_ERROR,
    payload: error
});

export const folderAddRequestFormAction = createFormAction(folderAddRequest, [FOLDER_ADD_SUCCESS, FOLDER_ADD_ERROR]);

export const folderEditRequest = (updatedFolder) => ({
    type: FOLDER_EDIT_REQUEST,
    payload: { updatedFolder }
});

export const folderEditSuccess = (updatedFolder) => ({
    type: FOLDER_EDIT_SUCCESS,
    payload: { updatedFolder }
});

export const folderEditError = (error) => ({
    type: FOLDER_EDIT_ERROR,
    payload: error
});

export const folderEditRequestFormAction = createFormAction(folderEditRequest, [FOLDER_EDIT_SUCCESS, FOLDER_EDIT_ERROR]);

export const foldersAndDashboardsMoveToFolderRequest = (folderIds, dashboardIds, toFolderId, fromFolderId) => ({
    type: FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST,
    payload: {
        folderIds, dashboardIds, toFolderId, fromFolderId
    }
});

export const foldersAndDashboardsMoveToFolderSuccess = (foldersResponses, dashboardsResponses, toFolderId, fromFolderId) => ({
    type: FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS,
    payload: {
        foldersResponses, dashboardsResponses, toFolderId, fromFolderId
    }
});

export const foldersAndDashboardsMoveToFolderError = (error, fromFolderId) => ({
    type: FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_ERROR,
    payload: { error, fromFolderId }
});

export const foldersAndDashboardsDeleteRequest = ({
    folderIds, dashboardIds, fromFolderId, redirectToDashboards, forceDeleteFolder
}) => ({
    type: FOLDERS_AND_DASHBOARDS_DELETE_REQUEST,
    payload: {
        folderIds, dashboardIds, fromFolderId, redirectToDashboards, forceDeleteFolder
    }
});

export const foldersAndDashboardsDeleteSuccess = (folderIds, dashboardIds, fromFolderId) => ({
    type: FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS,
    payload: {
        folderIds, dashboardIds, fromFolderId
    }
});

export const foldersAndDashboardsDeleteError = (error) => ({
    type: FOLDERS_AND_DASHBOARDS_DELETE_ERROR,
    payload: error
});

export const foldersAndDashboardsDeleteRequestFormAction = createFormAction(foldersAndDashboardsDeleteRequest, [FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS, FOLDERS_AND_DASHBOARDS_DELETE_ERROR]);

import { createFormAction } from 'redux-form-saga';

export const ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST = 'ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST';
export const ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS = 'ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS';
export const ALL_DASHBOARD_SHARE_LINKS_GET_ERROR = 'ALL_DASHBOARD_SHARE_LINKS_GET_ERROR';

export const DASHBOARD_SHARE_LINKS_GET_REQUEST = 'DASHBOARD_SHARE_LINKS_GET_REQUEST';
export const DASHBOARD_SHARE_LINKS_GET_SUCCESS = 'DASHBOARD_SHARE_LINKS_GET_SUCCESS';
export const DASHBOARD_SHARE_LINKS_GET_ERROR = 'DASHBOARD_SHARE_LINKS_GET_ERROR';

export const DASHBOARD_SHARE_LINK_REMOVE_REQUEST = 'DASHBOARD_SHARE_LINK_REMOVE_REQUEST';
export const DASHBOARD_SHARE_LINK_REMOVE_SUCCESS = 'DASHBOARD_SHARE_LINK_REMOVE_SUCCESS';
export const DASHBOARD_SHARE_LINK_REMOVE_ERROR = 'DASHBOARD_SHARE_LINK_REMOVE_ERROR';

export const CREATE_SHAREABLE_LINK_REQUEST = 'CREATE_SHAREABLE_LINK_REQUEST';
export const CREATE_SHAREABLE_LINK_SUCCESS = 'CREATE_SHAREABLE_LINK_SUCCESS';
export const CREATE_SHAREABLE_LINK_ERROR = 'CREATE_SHAREABLE_LINK_ERROR';

export const DASHBOARD_SHARE_LINK_UPDATE_REQUEST = 'DASHBOARD_SHARE_LINK_UPDATE_REQUEST';
export const DASHBOARD_SHARE_LINK_UPDATE_SUCCESS = 'DASHBOARD_SHARE_LINK_UPDATE_SUCCESS';
export const DASHBOARD_SHARE_LINK_UPDATE_ERROR = 'DASHBOARD_SHARE_LINK_UPDATE_ERROR';

export const DASHBOARD_SHARE_LINKS_CLEANUP = 'DASHBOARD_SHARE_LINKS_CLEANUP';

export const allDashboardShareLinksGetRequest = () => ({
    type: ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST
});

export const allDashboardShareLinksGetSuccess = (dashboardShareLinks) => ({
    type: ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS,
    payload: { dashboardShareLinks }
});

export const allDashboardShareLinksGetError = (error) => ({
    type: ALL_DASHBOARD_SHARE_LINKS_GET_ERROR,
    payload: { error }
});

export const dashboardShareLinksGetRequest = (dashboardId) => ({
    type: DASHBOARD_SHARE_LINKS_GET_REQUEST,
    payload: { dashboardId }
});

export const dashboardShareLinksGetSuccess = (dashboardId, dashboardShareLinks) => ({
    type: DASHBOARD_SHARE_LINKS_GET_SUCCESS,
    payload: { dashboardId, dashboardShareLinks }
});

export const dashboardShareLinksGetError = (dashboardId, error) => ({
    type: DASHBOARD_SHARE_LINKS_GET_ERROR,
    payload: { dashboardId, error }
});

export const dashboardShareLinkRemoveRequest = ({ id }) => ({
    type: DASHBOARD_SHARE_LINK_REMOVE_REQUEST,
    payload: { id }
});

export const dashboardShareLinkRemoveSuccess = (id) => ({
    type: DASHBOARD_SHARE_LINK_REMOVE_SUCCESS,
    payload: { id }
});

export const dashboardShareLinkRemoveError = (error) => ({
    type: DASHBOARD_SHARE_LINK_REMOVE_ERROR,
    payload: error
});

export const dashboardShareLinkRemoveRequestFormAction = createFormAction(
    dashboardShareLinkRemoveRequest,
    [
        DASHBOARD_SHARE_LINK_REMOVE_SUCCESS,
        DASHBOARD_SHARE_LINK_REMOVE_ERROR
    ]
);

export const createShareableLinkRequest = ({
    profileSelection,
    dateSelection,
    validUntil,
    hasExpiry,
    dashboardId,
    additionalFilterSelection,
    isPasswordProtected,
    password
}) => ({
    type: CREATE_SHAREABLE_LINK_REQUEST,
    payload: {
        profileSelection,
        dateSelection,
        validUntil,
        hasExpiry,
        dashboardId,
        additionalFilterSelection,
        isPasswordProtected,
        password
    }
});

export const createShareableLinkSuccess = (dashboardShareLink) => ({
    type: CREATE_SHAREABLE_LINK_SUCCESS,
    payload: { dashboardShareLink }
});

export const createShareableLinkError = (error) => ({
    type: CREATE_SHAREABLE_LINK_ERROR,
    payload: error
});

export const createShareableLinkFormAction = createFormAction(
    createShareableLinkRequest,
    [
        CREATE_SHAREABLE_LINK_SUCCESS,
        CREATE_SHAREABLE_LINK_ERROR
    ]
);

export const dashboardShareLinkUpdateRequest = ({
    id,
    dashboardId,
    profileSelection,
    dateSelection,
    validUntil,
    hasExpiry,
    additionalFilterSelection,
    isPasswordProtected,
    password
}) => ({
    type: DASHBOARD_SHARE_LINK_UPDATE_REQUEST,
    payload: {
        id,
        dashboardId,
        profileSelection,
        dateSelection,
        validUntil,
        hasExpiry,
        additionalFilterSelection,
        isPasswordProtected,
        password
    }
});

export const dashboardShareLinkUpdateSuccess = (dashboardShareLink) => ({
    type: DASHBOARD_SHARE_LINK_UPDATE_SUCCESS,
    payload: { dashboardShareLink }
});

export const dashboardShareLinkUpdateError = (error) => ({
    type: DASHBOARD_SHARE_LINK_UPDATE_ERROR,
    payload: error
});

export const dashboardShareLinkUpdateRequestFormAction = createFormAction(dashboardShareLinkUpdateRequest, [DASHBOARD_SHARE_LINK_UPDATE_SUCCESS, DASHBOARD_SHARE_LINK_UPDATE_ERROR]);

export const dashboardShareLinksCleanup = (dashboardShareLinkIds) => ({
    type: DASHBOARD_SHARE_LINKS_CLEANUP,
    payload: { dashboardShareLinkIds }
});

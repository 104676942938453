import React from 'react';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/views/public/authUserGrantedPermissionConfirmationInfo.scss';
import { connect } from 'react-redux';
import { externalAuthUserShowPagesConfirmRequest } from 'src/actions/socialNetworkProfileAuthentication';
import PropTypes from 'prop-types';
import { getAuthUserIdsFromStore } from 'src/selectors/authUsers';
import { selectExternalNetworkAuthenticationConfirm, selectCreatedByUserName, makeSelectAllChosenAuthAdAccountIds } from 'src/selectors/externalNetworkAuthentication';
import withUnsavedConfirmationDialog from 'src/components/forms/withUnsavedConfirmationDialog';
import * as customPropTypes from 'src/customPropTypes';
import { socialNetworkAdAccountAuthenticationConfirmRequest } from 'src/actions/socialNetworkAdAccountAuthentication';
import _lowerCase from 'lodash/lowerCase';

const AuthUserGrantedPermissionConfirmationInfo = (props) => {
    const { createdByUserName, submitting, accountType } = props;

    const handleOnDoneClick = () => {
        const {
            authUserIds, externalAuthUserShowPagesConfirmRequestAction, socialNetworkAdAccountAuthenticationConfirmRequestAction, chosenAuthAdAccountIds
        } = props;
        if (accountType === 'profiles') {
            externalAuthUserShowPagesConfirmRequestAction(authUserIds);
        }
        if (accountType === 'adAccounts') {
            socialNetworkAdAccountAuthenticationConfirmRequestAction(authUserIds, chosenAuthAdAccountIds);
        }
    };

    const accountName = _lowerCase(accountType);
    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                {`You have granted quintly permissions to the following ${accountName}. Please make sure that all of these ${accountName} are intended to be shared with ${createdByUserName}. `}
                You can always remove quintly&apos;s access to your accounts later. <a target="_blank" rel="noopener noreferrer" href="https://help.facelift-cloud.com/en/articles/8601252-profiles-connected-with-authentication-link">Learn more</a><br />
            </div>
            <div className={styles.button}>
                <div>Click &apos;Done&apos; to give {createdByUserName} access to the {accountName}.</div><br />
                <Button
                  label="Done"
                  onClick={handleOnDoneClick}
                  loading={submitting}
                  action
                  stretch
                  small
                />
            </div>
        </div>
    );
};

AuthUserGrantedPermissionConfirmationInfo.propTypes = {
    createdByUserName: PropTypes.string.isRequired,
    externalAuthUserShowPagesConfirmRequestAction: PropTypes.func.isRequired,
    authUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    submitting: PropTypes.bool.isRequired,
    accountType: customPropTypes.accountTypes.isRequired,
    socialNetworkAdAccountAuthenticationConfirmRequestAction: PropTypes.func.isRequired,
    chosenAuthAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = (state) => {
    const authUserIds = getAuthUserIdsFromStore(state);
    const selectAllChosenAuthAdAccountIds = makeSelectAllChosenAuthAdAccountIds();
    const confirmState = selectExternalNetworkAuthenticationConfirm(state);
    const { isPending, success } = confirmState;
    return {
        dirty: !success,
        submitting: isPending,
        authUserIds,
        createdByUserName: selectCreatedByUserName(state),
        chosenAuthAdAccountIds: selectAllChosenAuthAdAccountIds(state)
    };
};

export default connect(mapStateToProps, {
    externalAuthUserShowPagesConfirmRequestAction: externalAuthUserShowPagesConfirmRequest,
    socialNetworkAdAccountAuthenticationConfirmRequestAction: socialNetworkAdAccountAuthenticationConfirmRequest
})(withUnsavedConfirmationDialog(AuthUserGrantedPermissionConfirmationInfo));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const enableUnload = () => {
    window.onbeforeunload = () => true;
};

const disableUnload = () => {
    window.onbeforeunload = null;
};

const withUnsavedConfirmationDialog = (WrappedComponent) => {
    class WithUnsavedConfirmationDialog extends Component {
        constructor(props) {
            super(props);
            this.routerWillLeave = this.routerWillLeave.bind(this);
            this.routerLeaveHook = null;
        }

        componentDidMount() {
            const { router, routes, dirty } = this.props;
            const route = routes[routes.length - 1];
            this.routerLeaveHook = router.setRouteLeaveHook(route, this.routerWillLeave);

            if (dirty) {
                enableUnload();
            }
        }

        componentDidUpdate(prevProps) {
            const { dirty, submitting } = this.props;
            if (submitting) {
                disableUnload();
            } else if (!prevProps.dirty && dirty) {
                enableUnload();
            } else if (prevProps.dirty && !dirty) {
                disableUnload();
            }
        }

        componentWillUnmount() {
            disableUnload();
            this.unRegisterRouterLeaveHook();
        }

        routerWillLeave() {
            const { submitting, dirty } = this.props;
            // When ever we are submitting we don't want to show a notification.
            // We do redirects within a submit process
            if (!submitting && dirty) {
                return 'Are you sure you want to leave the page? \nChanges that you made will not be saved.';
            }
            return null;
        }

        unRegisterRouterLeaveHook() {
            if (this.routerLeaveHook()) {
                this.routerLeaveHook();
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    WithUnsavedConfirmationDialog.propTypes = {
        dirty: PropTypes.bool.isRequired,
        router: PropTypes.object.isRequired,
        routes: PropTypes.array.isRequired,
        submitting: PropTypes.bool.isRequired
    };

    return withRouter(WithUnsavedConfirmationDialog);
};

export default withUnsavedConfirmationDialog;

import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    parsePlatformPermissions,
    parseUseCases,
    parseAuthUser,
    parseAuthAdAccounts,
    parseAuthUserAuthAdAccountUseCases
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    socialNetworkAdAccountAuthenticationBootstrapRequest,
    socialNetworkAdAccountAuthenticationBootstrapSuccess,
    socialNetworkAdAccountAuthenticationBootstrapError,
    socialNetworkAdAccountAuthenticationConnectRequest,
    socialNetworkAdAccountAuthenticationConnectSuccess,
    socialNetworkAdAccountAuthenticationConnectError,
    socialNetworkAdAccountAuthenticationConfirmRequest,
    socialNetworkAdAccountAuthenticationConfirmError,
    socialNetworkAdAccountAuthenticationConfirmSuccess,
    metaAdAccountAuthenticationForceConnectRequest,
} from 'src/actions/socialNetworkAdAccountAuthentication';
import { selectHash } from 'src/selectors/externalNetworkAuthentication';
import { graphAuthUserAuthenticationRequest } from 'src/sagas/authUsers';
import { selectUseCasesByPlatformWithBasicInsights } from 'src/selectors/useCases';
import {
    modalAuthUserShowPagesConfirmed,
    modalsShowFacebookAuthUserInAnotherSpaceExternalAuthenticationWarning,
    modalShowAuthAdAccountSelection
} from 'src/actions/overlays';
import { stringArrayToIntArray } from 'src/utils/array';

function* performExternalSocialNetworkConnectSuccess(response, platformType) {
    const { authUserAuthAdAccountUseCases, authAdAccounts, authUser } = response.jsonData;
    const parsedAuthAdAccounts = parseAuthAdAccounts(authAdAccounts);
    const parsedAuthUserAuthAdAccountUseCases = parseAuthUserAuthAdAccountUseCases(authUserAuthAdAccountUseCases);
    const parsedAuthUser = parseAuthUser(authUser);
    yield put(socialNetworkAdAccountAuthenticationConnectSuccess(parsedAuthAdAccounts, parsedAuthUserAuthAdAccountUseCases, parsedAuthUser));
    yield put(modalShowAuthAdAccountSelection(platformType));
}

function* getHash() {
    return yield select(selectHash);
}

function* socialNetworkAdAccountAuthenticationBootstrapRequestAction(action) {
    const { hash } = action.payload;
    try {
        const serverRequest = createServerRequest({ hash });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/bootstrap-social-network-ad-account-authentication');
        if (response) {
            const {
                useCases,
                platformPermissions,
                useCasePlatformPermissions,
                createdByUserName
            } = response.jsonData;
            const parsedUseCases = parseUseCases(useCases, useCasePlatformPermissions);
            const parsedPlatformPermissions = parsePlatformPermissions(platformPermissions);
            yield put(socialNetworkAdAccountAuthenticationBootstrapSuccess(hash, parsedUseCases, parsedPlatformPermissions, createdByUserName));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountAuthenticationBootstrapError(applicationError));
    }
}

function* externalFacebookAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const hash = yield call(getHash);
            const serverRequest = createServerRequest({ hash, shortLiveAccessToken: accessToken });
            const {
                response: authorizedResponse, serverError: authorizedServerError
            } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-ad-account-graph-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(socialNetworkAdAccountAuthenticationConnectSuccess());
                    yield put(modalsShowFacebookAuthUserInAnotherSpaceExternalAuthenticationWarning(accessToken, 'adAccounts'));
                } else {
                    yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse, 'meta');
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountAuthenticationConnectError(applicationError));
    }
}

function* metaAdAccountAuthenticationForceConnectRequestAction(action) {
    const { payload } = action;
    const { accessToken } = payload;
    try {
        const hash = yield call(getHash);
        const serverRequest = createServerRequest({ hash, shortLiveAccessToken: accessToken, isAllowedInNewSpace: true });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-ad-account-graph-auth-user-authentication-callback-url');
        if (response) {
            yield call(performExternalSocialNetworkConnectSuccess, response, 'meta');
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountAuthenticationConnectError(applicationError));
    }
}

function* socialNetworkAdAccountAuthenticationConnectRequestAction(action) {
    const { platformType } = action.payload;
    const useCases = yield select(selectUseCasesByPlatformWithBasicInsights, platformType, false);
    const useCaseIds = useCases.map(({ id }) => id);
    if (platformType === 'meta') {
        yield call(externalFacebookAuthenticateRequest, useCaseIds);
    }
}

function* socialNetworkAdAccountAuthenticationConfirmRequestAction(action) {
    const { payload } = action;
    const { authUserIds, chosenAuthAdAccountIds } = payload;
    const hash = yield call(getHash);
    try {
        const serverRequest = createServerRequest({ hash, authUserIds: JSON.stringify(stringArrayToIntArray(authUserIds)), chosenAuthAdAccountIds: JSON.stringify(stringArrayToIntArray(chosenAuthAdAccountIds)) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/external-auth-user-show-ad-accounts-confirm-request');
        if (response) {
            yield put(socialNetworkAdAccountAuthenticationConfirmSuccess());
            yield put(modalAuthUserShowPagesConfirmed());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountAuthenticationConfirmError(applicationError));
    }
}

export default function* externalSocialNetworkAuthenticationSagas() {
    yield takeEvery(socialNetworkAdAccountAuthenticationBootstrapRequest.type, socialNetworkAdAccountAuthenticationBootstrapRequestAction);
    yield takeEvery(socialNetworkAdAccountAuthenticationConnectRequest.type, socialNetworkAdAccountAuthenticationConnectRequestAction);
    yield takeEvery(metaAdAccountAuthenticationForceConnectRequest.type, metaAdAccountAuthenticationForceConnectRequestAction);
    yield takeEvery(socialNetworkAdAccountAuthenticationConfirmRequest.type, socialNetworkAdAccountAuthenticationConfirmRequestAction);
}

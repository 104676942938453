import { createFormAction } from 'redux-form-saga';

export const USER_ACCEPT_TERMS_AND_CONDITIONS_REQUEST = 'USER_ACCEPT_TERMS_AND_CONDITIONS_REQUEST';
export const USER_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS = 'USER_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS';
export const USER_ACCEPT_TERMS_AND_CONDITIONS_ERROR = 'USER_ACCEPT_TERMS_AND_CONDITIONS_ERROR';

export const USER_CLIENT_INFO_REGISTRATION_REQUEST = 'USER_CLIENT_INFO_REGISTRATION_REQUEST';
export const USER_CLIENT_INFO_REGISTRATION_SUCCESS = 'USER_CLIENT_INFO_REGISTRATION_SUCCESS';
export const USER_CLIENT_INFO_REGISTRATION_ERROR = 'USER_CLIENT_INFO_REGISTRATION_ERROR';

export const USER_ONLY_INFO_REGISTRATION_REQUEST = 'USER_ONLY_INFO_REGISTRATION_REQUEST';
export const USER_ONLY_INFO_REGISTRATION_SUCCESS = 'USER_ONLY_INFO_REGISTRATION_SUCCESS';
export const USER_ONLY_INFO_REGISTRATION_ERROR = 'USER_ONLY_INFO_REGISTRATION_ERROR';

export const USER_ONBOARDING_COMPLETED_REQUEST = 'USER_ONBOARDING_COMPLETED_REQUEST';
export const USER_ONBOARDING_COMPLETED_ERROR = 'USER_ONBOARDING_COMPLETED_ERROR';

export const MOVE_FORWARD = 'MOVE_FORWARD';
export const MOVE_BACKWARD = 'MOVE_BACKWARD';

export const SET_FAILED_TO_BE_ADDED_PROFILE_URLS = 'SET_FAILED_TO_BE_ADDED_PROFILE_URLS';
export const RESET_FAILED_TO_BE_ADDED_PROFILE_URLS = 'RESET_FAILED_TO_BE_ADDED_PROFILE_URLS';

export const ADD_UPDATE_USER_TO_LIST = 'ADD_UPDATE_USER_TO_LIST';
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST';

export const userClientInfoRegistrationRequest = ({
    firstName, lastName, timezone, company, companyType, acceptedTermsAndConditions
}) => ({
    type: USER_CLIENT_INFO_REGISTRATION_REQUEST,
    payload: {
        firstName, lastName, timezone, company, companyType, acceptedTermsAndConditions
    }
});

export const userClientInfoRegistrationSuccess = () => ({
    type: USER_CLIENT_INFO_REGISTRATION_SUCCESS,
});

export const userClientInfoRegistrationError = (error) => ({
    type: USER_CLIENT_INFO_REGISTRATION_ERROR,
    payload: error
});

export const userClientInfoRegistrationFormAction = createFormAction(userClientInfoRegistrationRequest, [USER_CLIENT_INFO_REGISTRATION_SUCCESS, USER_CLIENT_INFO_REGISTRATION_ERROR]);

export const userOnlyInfoRegistrationRequest = ({
    firstName, lastName, timezone
}) => ({
    type: USER_ONLY_INFO_REGISTRATION_REQUEST,
    payload: {
        firstName, lastName, timezone
    }
});

export const userOnlyInfoRegistrationError = (error) => ({
    type: USER_ONLY_INFO_REGISTRATION_ERROR,
    payload: error
});

export const userOnlyInfoRegistrationFormAction = createFormAction(userOnlyInfoRegistrationRequest, [USER_ONLY_INFO_REGISTRATION_SUCCESS, USER_ONLY_INFO_REGISTRATION_ERROR]);

export const userOnboardingCompletedRequest = () => ({
    type: USER_ONBOARDING_COMPLETED_REQUEST
});

export const userOnboardingCompletedError = (error) => ({
    type: USER_ONBOARDING_COMPLETED_ERROR,
    payload: { error }
});

export const moveForward = (steps = 1) => ({
    type: MOVE_FORWARD,
    payload: { steps }
});

export const moveBackward = (steps = 1) => ({
    type: MOVE_BACKWARD,
    payload: { steps }
});

export const setFailedToBeAddedProfileUrls = (urls) => ({
    type: SET_FAILED_TO_BE_ADDED_PROFILE_URLS,
    payload: { urls }
});

export const resetFailedToBeAddedProfileUrls = () => ({
    type: RESET_FAILED_TO_BE_ADDED_PROFILE_URLS
});

export const userAcceptsTermsAndConditionRequest = ({ acceptedTermsAndConditions }) => ({
    type: USER_ACCEPT_TERMS_AND_CONDITIONS_REQUEST,
    payload: { acceptedTermsAndConditions }
});

export const userAcceptsTermsAndConditionSuccess = () => ({
    type: USER_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS
});

export const userAcceptsTermsAndConditionError = (error) => ({
    type: USER_ACCEPT_TERMS_AND_CONDITIONS_ERROR,
    payload: error
});

export const userAcceptsTermsAndConditionRequestFormAction = createFormAction(userAcceptsTermsAndConditionRequest, [USER_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS, USER_ACCEPT_TERMS_AND_CONDITIONS_ERROR]);

import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import { socialNetworkProfileShowListError } from 'src/actions/profileSearch';
import { getScopesByUseCases, handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    parseAuthProfiles, parseAuthUserAuthProfileUseCases, parsePlatformPermissions, parseUseCases, parseAuthUser
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST,
    externalNetworkAuthenticationBootstrapError,
    externalNetworkAuthenticationBootstrapSuccess,
    EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST,
    externalSocialNetworkConnectError,
    externalSocialNetworkConnectSuccess,
    EXTERNAL_GRAPH_FORCE_CONNECT_REQUEST,
    EXTERNAL_LINKED_IN_FORCE_CONNECT_REQUEST,
    EXTERNAL_SNAPCHAT_FORCE_CONNECT_REQUEST,
    EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST,
    externalAuthUserShowPagesConfirmSuccess,
    externalAuthUserShowPagesConfirmError,
    EXTERNAL_SOCIAL_NETWORK_BY_HASH_REQUEST
} from 'src/actions/socialNetworkProfileAuthentication';
import { selectHash } from 'src/selectors/externalNetworkAuthentication';
import {
    modalsShowFacebookAuthUserInAnotherSpaceExternalAuthenticationWarning,
    modalsShowLinkedInAuthUserInAnotherSpaceExternalAuthenticationWarning,
    modalsShowSnapchatAuthUserInAnotherSpaceExternalAuthenticationWarning,
    modalAuthUserShowPagesConfirmed
} from 'src/actions/overlays';
import {
    graphAuthUserAuthenticationRequest,
    linkedInAuthUserAuthenticationRequest,
    snapchatAuthUserAuthenticationRequest,
    youtubeAuthUserAuthenticationRequest,
    twitterAuthUserAuthenticationRequest,
    tiktokAuthUserAuthenticationRequest,
} from 'src/sagas/authUsers';
import { generateRandomTwitterRequestId, getThreadsAuthenticationCallbackUrl } from 'src/utils/authUsers';

function* getHash() {
    return yield select(selectHash);
}

function* performExternalSocialNetworkConnectSuccess(response) {
    const { authUserAuthProfileUseCases, authProfiles, authUser } = response.jsonData;
    const parsedAuthProfiles = parseAuthProfiles(authProfiles);
    const parsedAuthUserAuthProfileUseCases = parseAuthUserAuthProfileUseCases(authUserAuthProfileUseCases);
    const parsedAuthUser = parseAuthUser(authUser);
    yield put(externalSocialNetworkConnectSuccess(parsedAuthProfiles, parsedAuthUserAuthProfileUseCases, parsedAuthUser));
}

function* externalFacebookAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const hash = yield call(getHash);
            const serverRequest = createServerRequest({ hash, shortLiveAccessToken: accessToken });
            const {
                response: authorizedResponse, serverError: authorizedServerError
            } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-graph-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(modalsShowFacebookAuthUserInAnotherSpaceExternalAuthenticationWarning(accessToken));
                    yield put(externalSocialNetworkConnectSuccess());
                } else {
                    yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalGraphForceConnectRequest(action) {
    const { payload } = action;
    const { accessToken } = payload;
    try {
        const hash = yield call(getHash);
        const serverRequest = createServerRequest({ hash, shortLiveAccessToken: accessToken, isAllowedInNewSpace: true });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-graph-auth-user-authentication-callback-url');
        if (response) {
            yield call(performExternalSocialNetworkConnectSuccess, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalLinkedInAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(linkedInAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const hash = yield call(getHash);
            const authorizedRequest = createServerRequest({
                code: response.code,
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-public/handle-external-linked-in-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshToken,
                        validUntil,
                        refreshTokenValidUntil,
                        platformUserId,
                        platformUserName
                    } = authorizedResponse.jsonData;
                    yield put(modalsShowLinkedInAuthUserInAnotherSpaceExternalAuthenticationWarning(accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName));
                    yield put(externalSocialNetworkConnectSuccess());
                } else {
                    yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalLinkedInForceConnectRequest(action) {
    const { payload } = action;
    const {
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName
    } = payload;
    try {
        const hash = yield call(getHash);
        const serverRequest = createServerRequest({
            hash,
            accessToken,
            refreshToken,
            validUntil,
            refreshTokenValidUntil,
            platformUserId,
            platformUserName
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-linked-in-auth-user-force-authentication-callback-url');
        if (response) {
            yield call(performExternalSocialNetworkConnectSuccess, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalTwitterAuthenticateRequest() {
    try {
        const requestId = generateRandomTwitterRequestId();
        const { response, serverError } = yield call(twitterAuthUserAuthenticationRequest, requestId);
        if (response) {
            const { oauthToken, oauthVerifier } = response;
            const hash = yield call(getHash);
            const authorizedRequest = createServerRequest({
                oauthToken,
                oauthVerifier,
                requestId,
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-public/handle-external-twitter-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalYoutubeAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(youtubeAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const hash = yield call(getHash);
            const authorizedRequest = createServerRequest({
                code: response.code,
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-public/handle-external-youtube-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalSnapchatAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(snapchatAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const hash = yield call(getHash);
            const authorizedRequest = createServerRequest({
                code: response.code,
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-public/handle-external-snapchat-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshAccessToken,
                        expiresIn,
                        platformUserId,
                        displayName
                    } = authorizedResponse.jsonData;
                    yield put(modalsShowSnapchatAuthUserInAnotherSpaceExternalAuthenticationWarning(
                        accessToken, refreshAccessToken, expiresIn, platformUserId, displayName
                    ));
                    yield put(externalSocialNetworkConnectSuccess());
                } else {
                    yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalSnapchatForceConnectRequest(action) {
    const { payload } = action;
    const {
        accessToken,
        expiresIn,
        refreshAccessToken,
        platformUserId,
        displayName
    } = payload;
    try {
        const hash = yield call(getHash);
        const serverRequest = createServerRequest({
            hash,
            accessToken,
            expiresIn,
            refreshAccessToken,
            platformUserId,
            displayName
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-snapchat-auth-user-force-authentication-callback-url');
        if (response) {
            yield call(performExternalSocialNetworkConnectSuccess, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalTiktokAuthenticateRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(tiktokAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const hash = yield call(getHash);
            const serverRequest = createServerRequest({
                code: response.code,
                hash,
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-tiktok-auth-user-authentication-callback-url');
            if (authorizedResponse) {
                yield call(performExternalSocialNetworkConnectSuccess, authorizedResponse);
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

function* externalThreadsRedirectOauthRequest(useCaseIds) {
    try {
        const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'threads');
        const hash = yield call(getHash);

        const url = getThreadsAuthenticationCallbackUrl(finalScopes);
        const serverRequest = createServerRequest({
            url,
            hash
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/redirect-threads-oauth-profile-add-public');
        if (response) {
            const { stateFulRedirect } = response.jsonData;
            window.location.href = stateFulRedirect;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkProfileShowListError(applicationError));
    }
}

function* externalSocialNetworkConnectRequest(action) {
    const { network, useCaseIds } = action.payload;
    if (network === 'facebook') {
        yield call(externalFacebookAuthenticateRequest, useCaseIds);
    }
    if (network === 'youtube') {
        yield call(externalYoutubeAuthenticateRequest, useCaseIds);
    }
    if (network === 'twitter') {
        yield call(externalTwitterAuthenticateRequest, useCaseIds);
    }
    if (network === 'linkedIn') {
        yield call(externalLinkedInAuthenticateRequest, useCaseIds);
    }
    if (network === 'snapchatShow') {
        yield call(externalSnapchatAuthenticateRequest, useCaseIds);
    }
    if (network === 'tiktok') {
        yield call(externalTiktokAuthenticateRequest, useCaseIds);
    } if (network === 'threads') {
        yield call(externalThreadsRedirectOauthRequest, useCaseIds);
    }
}

function* externalNetworkAuthenticationBootstrapRequest(action) {
    const { hash } = action.payload;
    try {
        const serverRequest = createServerRequest({ hash });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/bootstrap-social-network-profile-authentication');
        if (response) {
            const {
                useCases,
                platformPermissions,
                useCasePlatformPermissions,
                createdByUserName
            } = response.jsonData;
            const parsedUseCases = parseUseCases(useCases, useCasePlatformPermissions);
            const parsedPlatformPermissions = parsePlatformPermissions(platformPermissions);
            yield put(externalNetworkAuthenticationBootstrapSuccess(hash, parsedUseCases, parsedPlatformPermissions, createdByUserName));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalNetworkAuthenticationBootstrapError(applicationError));
    }
}

function* externalAuthUserShowPagesInSpaceConfirmRequest(action) {
    const { payload } = action;
    const { authUserIds } = payload;
    const hash = yield call(getHash);
    try {
        const serverRequest = createServerRequest({ hash, authUserIds: JSON.stringify(authUserIds) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/external-auth-user-show-profiles-confirm-request');
        if (response) {
            yield put(externalAuthUserShowPagesConfirmSuccess());
            yield put(modalAuthUserShowPagesConfirmed());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(externalAuthUserShowPagesConfirmError(applicationError));
    }
}

function* externalSocialNetworkByHashRequest(action) {
    const { hash } = action.payload;
    try {
        const serverRequest = createServerRequest({ stateHash: hash });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-network-auth-user-by-hash');
        if (response) {
            yield call(performExternalSocialNetworkConnectSuccess, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(externalSocialNetworkConnectError(applicationError));
    }
}

export default function* externalSocialNetworkAuthenticationSagas() {
    yield takeEvery(EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_REQUEST, externalNetworkAuthenticationBootstrapRequest);
    yield takeEvery(EXTERNAL_SOCIAL_NETWORK_CONNECT_REQUEST, externalSocialNetworkConnectRequest);
    yield takeEvery(EXTERNAL_SOCIAL_NETWORK_BY_HASH_REQUEST, externalSocialNetworkByHashRequest);
    yield takeEvery(EXTERNAL_GRAPH_FORCE_CONNECT_REQUEST, externalGraphForceConnectRequest);
    yield takeEvery(EXTERNAL_LINKED_IN_FORCE_CONNECT_REQUEST, externalLinkedInForceConnectRequest);
    yield takeEvery(EXTERNAL_SNAPCHAT_FORCE_CONNECT_REQUEST, externalSnapchatForceConnectRequest);
    yield takeEvery(EXTERNAL_AUTH_USER_SHOW_PAGES_IN_SPACE_CONFIRM_REQUEST, externalAuthUserShowPagesInSpaceConfirmRequest);
}

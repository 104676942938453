import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/public/externalSocialNetworkAuthentication.scss';
import { modalShowSocialNetworkLoginUseCaseSelection } from 'src/actions/overlays';
import ProfileSocialNetworkButtons from 'src/components/views/profiles/ProfileSocialNetworkButtons';
import AuthUserAuthProfileConnectedStates from 'src/components/views/public/authProfileAuthUserConnectionState/AuthUserAuthProfileConnectedStates';
import SocialNetworkAuthenticationHeader from 'src/components/views/public/SocialNetworkAuthenticationHeader';

const SocialNetworkProfileAuthentication = (props) => {
    const { modalShowSocialNetworkLoginUseCaseSelectionAction, stateHash } = props;
    const handleOnSocialNetworkButtonClick = (platformType) => {
        modalShowSocialNetworkLoginUseCaseSelectionAction(platformType, true);
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <SocialNetworkAuthenticationHeader accountType="profiles" />
                    <ProfileSocialNetworkButtons action={handleOnSocialNetworkButtonClick} />
                </div>
                <AuthUserAuthProfileConnectedStates stateHash={stateHash} />
            </div>
        </div>
    );
};

SocialNetworkProfileAuthentication.propTypes = {
    modalShowSocialNetworkLoginUseCaseSelectionAction: PropTypes.func.isRequired,
    stateHash: PropTypes.string
};

export default connect(null, {
    modalShowSocialNetworkLoginUseCaseSelectionAction: modalShowSocialNetworkLoginUseCaseSelection
})(SocialNetworkProfileAuthentication);

import PropTypes from 'prop-types';
import { ownershipEntities, ownershipTypes } from 'src/utils/ownership';
import { dataPushTaskQQLType, dataPushTaskDataSourceType } from 'src/utils/dataPushTasks';
import {
    QUICK_ANALYSIS, METRIC_BUILDER, DISCOVER_PREVIEW, POST_TAGGING, DASHBOARD
} from 'src/utils/filterSelectors';

export const profileUseCase = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired
});

export const profileUseCases = PropTypes.arrayOf(profileUseCase);

export const useCase = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    platformType: PropTypes.string.isRequired,
    platformPermissionIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const useCases = PropTypes.arrayOf(useCase);

export const authUser = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    lastUpdatedTime: PropTypes.string.isRequired,
    deprecated: PropTypes.bool.isRequired
});

export const authUsers = PropTypes.arrayOf(authUser);

export const useCaseFormField = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    hasWarning: PropTypes.bool.isRequired
});

export const platformPermission = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    documentationLink: PropTypes.string.isRequired
});

export const platformPermissions = PropTypes.arrayOf(platformPermission);

export const useCaseFormFields = PropTypes.arrayOf(useCaseFormField);

export const fixedDataTableCellProps = PropTypes.shape({
    className: PropTypes.string,
    columnKey: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
});

export const authUserAuthProfileUseCase = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired,
});

export const authUserAuthProfileUseCases = PropTypes.arrayOf(authUserAuthProfileUseCase);

export const authUserAuthAdAccountUseCase = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    adAccountId: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired,
});

export const authUserAuthAdAccountUseCases = PropTypes.arrayOf(authUserAuthAdAccountUseCase);

const exportFormats = ['pptx', 'pdf', 'png', 'jpg', 'csv'];
const toolFormats = ['console', 'json'];

export const format = PropTypes.oneOf([...exportFormats, ...toolFormats]);

export const paginationAndSorting = PropTypes.shape({
    page: PropTypes.number,
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
    paginate: PropTypes.bool
});

export const batchPaginationAndSorting = PropTypes.objectOf(paginationAndSorting);

export const children = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
]);

export const authProfileIdsByPlatformType = PropTypes.shape({
    facebook: PropTypes.arrayOf(PropTypes.string).isRequired,
    instagram: PropTypes.arrayOf(PropTypes.string).isRequired,
    twitter: PropTypes.arrayOf(PropTypes.string).isRequired,
    youtube: PropTypes.arrayOf(PropTypes.string).isRequired,
    linkedIn: PropTypes.arrayOf(PropTypes.string).isRequired,
    snapchatShow: PropTypes.arrayOf(PropTypes.string).isRequired,
    tiktok: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const authAdAccountByPlatformType = PropTypes.shape({
    meta: PropTypes.arrayOf(PropTypes.string).isRequired
});

const request = PropTypes.shape({
    hash: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
});

const endpointInfo = PropTypes.shape({
    to: PropTypes.string.isRequired,
    format
});

export const batchServerRequest = PropTypes.shape({
    requests: PropTypes.arrayOf(request).isRequired,
    endpointInfo: endpointInfo.isRequired,
    batchIdentifier: PropTypes.string.isRequired,
    requestVariables: PropTypes.objectOf(PropTypes.string).isRequired,
    entityIdToRequestMapping: PropTypes.objectOf(PropTypes.number).isRequired
});

export const serverRequest = PropTypes.shape({
    request: request.isRequired,
    endpointInfo: endpointInfo.isRequired
});

export const numberFormat = PropTypes.shape({
    thousandSeparator: PropTypes.string.isRequired,
    decimalSeparator: PropTypes.string.isRequired
});

export const accountOptions = PropTypes.shape({
    csvSeparator: PropTypes.string.isRequired,
    numberFormat: numberFormat.isRequired,
    exportEncoding: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    weekDefinition: PropTypes.string.isRequired
});

export const dashboard = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customLogo: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    spaceId: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired,
    dashboardMetricIds: PropTypes.arrayOf(PropTypes.string),
    createTime: PropTypes.string,
    lastUpdateTime: PropTypes.string,
    createdByUserId: PropTypes.string,
    createdByUserName: PropTypes.string,
    defaultTimezone: PropTypes.string.isRequired
});

export const dashboardOrFalse = PropTypes.oneOfType([
    dashboard,
    PropTypes.bool
]);

export const dashboards = PropTypes.arrayOf(dashboard);

export const data = PropTypes.object;

export const bigNumberData = PropTypes.shape({
    singleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const location = PropTypes.object;

export const visualizationType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    supportedDataTypeId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pos: PropTypes.number.isRequired
});

export const visualizationTypeList = PropTypes.arrayOf(visualizationType);

export const dataSourceHints = PropTypes.arrayOf(PropTypes.string);

export const metricDataSourceHintsShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    dataSourceName: PropTypes.string.isRequired,
    hints: dataSourceHints.isRequired
});

export const metricDataSourcesHints = PropTypes.arrayOf(metricDataSourceHintsShape);

export const metric = PropTypes.shape({
    id: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    visualizationId: PropTypes.string.isRequired,
    visualization: visualizationType.isRequired,
    name: PropTypes.string.isRequired,
    isCustomMetric: PropTypes.bool,
    summary: PropTypes.string.isRequired,
    dataSourceHints: metricDataSourcesHints,
    description: PropTypes.string.isRequired,
    useCases: PropTypes.string.isRequired,
    usage: PropTypes.number,
    createTime: PropTypes.string,
    lastUpdateTime: PropTypes.string,
    platformType: PropTypes.string.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool,
    parameters: PropTypes.object,
    createdByUserId: PropTypes.string,
    createdByUserName: PropTypes.string,
    fixedInterval: PropTypes.string,
    tagIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const minimalMetricShapeForDataLoading = PropTypes.shape({
    id: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    visualizationId: PropTypes.string.isRequired,
    visualization: visualizationType.isRequired,
    name: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired
});

export const customTag = PropTypes.shape({
    name: PropTypes.string,
    customOption: PropTypes.bool
});

export const tag = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tagType: PropTypes.string,
    tagTypeId: PropTypes.string
});

export const tagOrCustomTag = PropTypes.oneOfType([tag, customTag]);

export const tagsOrCustomTags = PropTypes.arrayOf(tagOrCustomTag);

export const tags = PropTypes.arrayOf(tag);

export const tagTypeWithTags = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tags
});

export const tagTypesWithTags = PropTypes.arrayOf(tagTypeWithTags);

export const metricOrFalse = PropTypes.oneOfType([
    metric,
    PropTypes.bool
]);

export const metrics = PropTypes.arrayOf(metric);

export const metricConfig = PropTypes.shape({
    metaData: PropTypes.object
});

export const profileUseCasesAuthUser = PropTypes.shape({
    useCaseId: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    invalidReason: PropTypes.string,
    userId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
});

export const profileUseCasesAuthUsers = PropTypes.arrayOf(profileUseCasesAuthUser);

export const authUsersAuthProfileUseCase = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired
});

export const authUsersAuthProfileUseCases = PropTypes.arrayOf(authUsersAuthProfileUseCase);

export const profile = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    platformId: PropTypes.string.isRequired,
    platformProfileType: PropTypes.string,
    platformUsername: PropTypes.string,
    picture: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    likes: PropTypes.number.isRequired,
    verified: PropTypes.bool.isRequired,
    favorite: PropTypes.bool,
    color: PropTypes.string,
    appendix: PropTypes.string,
    addedTime: PropTypes.string,
    addedUserFirstname: PropTypes.string,
    addedUserLastname: PropTypes.string,
    useCases,
    accountUseCasesAuthUsers: profileUseCasesAuthUsers,
    authUsersAuthAccountUseCases: authUsersAuthProfileUseCases
});

export const profiles = PropTypes.arrayOf(profile);

export const authProfile = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    platformId: PropTypes.string.isRequired,
    platformUsername: PropTypes.string,
});

export const authProfiles = PropTypes.arrayOf(authProfile);

export const authAdAccount = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    platformId: PropTypes.string.isRequired,
    appendix: PropTypes.string.isRequired,
    addedUserFirstname: PropTypes.string,
    addedUserLastname: PropTypes.string,
});

export const adAccountCampaigns = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    platformId: PropTypes.string.isRequired,
    adAccountId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    endTime: PropTypes.string
}));

export const authAdAccounts = PropTypes.arrayOf(authAdAccount);

export const group = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const groups = PropTypes.arrayOf(group);

export const authUsersAuthAdAccountUseCase = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    adAccountId: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired
});

export const authUsersAuthAdAccountUseCases = PropTypes.arrayOf(authUsersAuthAdAccountUseCase);

export const adAccountUseCasesAuthUser = PropTypes.shape({
    useCaseId: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    invalidReason: PropTypes.string,
    userId: PropTypes.string.isRequired,
    adAccountId: PropTypes.string.isRequired,
});

export const adAccountUseCasesAuthUsers = PropTypes.arrayOf(adAccountUseCasesAuthUser);

export const adAccount = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    appendix: PropTypes.string,
    accountUseCasesAuthUsers: adAccountUseCasesAuthUsers,
    authUsersAuthAccountUseCases: authUsersAuthAdAccountUseCases
});

export const adAccounts = PropTypes.arrayOf(adAccount);

// This is a timezone for persited entities
export const persistedSelectedTimezone = PropTypes.string;

const dateRangeShape = {
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
};

const postTagRuleDateSelection = PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string,
    timezone: PropTypes.string.isRequired
});

export const dateRange = PropTypes.shape(dateRangeShape);

const relativeDateShape = {
    dynamicDate: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
};

export const relativeDate = PropTypes.shape(relativeDateShape);

export const selectedDate = PropTypes.oneOfType([relativeDate, dateRange]);

const multipleValueFilterShape = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    conjunction: PropTypes.oneOf(['all', 'any']).isRequired
};

export const dateFilter = PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired
});

export const profileFilter = PropTypes.shape({
    groupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const adCampaignFilter = PropTypes.shape(multipleValueFilterShape);

export const postTagFilter = PropTypes.shape(Object.assign({}, multipleValueFilterShape, { untagged: PropTypes.bool.isRequired }));

export const postTextFilter = PropTypes.shape(multipleValueFilterShape);

export const postTextExcludeFilter = PropTypes.shape(multipleValueFilterShape);

export const postTextIncludeAndExcludeFilter = PropTypes.shape({
    postText: postTextFilter,
    postTextExclude: postTextExcludeFilter
});

export const additionalFilterValues = PropTypes.shape({
    postText: postTextFilter,
    postTextExclude: postTextExcludeFilter,
    postTag: postTagFilter,
    adCampaign: adCampaignFilter,
});

export const user = PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    gravatar35px: PropTypes.string,
    gravatar80px: PropTypes.string,
    gravatar100px: PropTypes.string,
    email: PropTypes.string.isRequired
});

export const users = PropTypes.arrayOf(user);

export const loggedInUser = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    loggedInSpaceId: PropTypes.string.isRequired,
    userSpaceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isAgent: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    firstAppStartedTime: PropTypes.string.isRequired,
    intercomHash: PropTypes.string.isRequired,
    faceliftCustomerId: PropTypes.bool
});

export const faceliftTagOrCampaign = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['tag', 'campaign']).isRequired
});
export const manageUser = PropTypes.shape({
    id: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    lastActiveTime: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    invitationPending: PropTypes.bool.isRequired,
    invitationCode: PropTypes.string.isRequired,
    spaces: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        readOnly: PropTypes.bool.isRequired
    })).isRequired,
    groupRestrictions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        spaceId: PropTypes.string.isRequired
    })).isRequired
});

export const dashboardMetricSettings = PropTypes.shape({
    isCustomDateSelected: PropTypes.bool,
    isCustomProfileSelected: PropTypes.bool,
    isCustomAdditionalFilterSelected: PropTypes.bool,
    inheritTimezone: PropTypes.bool,
    settingAdditional: PropTypes.shape({
        hideColumns: PropTypes.arrayOf(PropTypes.string),
        sortBy: PropTypes.string,
        sortDir: PropTypes.string,
        limit: PropTypes.number
    }),
    settingDateSelection: PropTypes.oneOfType([selectedDate, PropTypes.bool]),
    settingProfileSelection: profileFilter,
    settingAdditionalFilterSelection: additionalFilterValues,
    notes: PropTypes.string,
    visualizationId: PropTypes.string,
    visualization: visualizationType
});

export const dashboardMetric = PropTypes.shape({
    id: PropTypes.string.isRequired,
    metricId: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    settings: dashboardMetricSettings.isRequired,
    metric: metric.isRequired,
    displayName: PropTypes.string.isRequired
});

export const dashboardMetricLayout = PropTypes.shape({
    id: PropTypes.string.isRequired,
    col: PropTypes.number.isRequired,
    row: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
});

export const dashboardMetricOrFalse = PropTypes.oneOfType([
    dashboardMetric,
    PropTypes.bool
]);

export const dashboardMetrics = PropTypes.arrayOf(dashboardMetric);

export const dashboardMetricLayouts = PropTypes.arrayOf(dashboardMetricLayout);

export const tab = PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
});

export const tableCellColumn = PropTypes.shape(
    {
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        title: PropTypes.string.isRequired,
        widthRatio: PropTypes.number
    }
);

export const hideColumn = PropTypes.string;

export const tableHeaderColumn = PropTypes.shape(
    {
        absoluteWidth: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        ratioBasedWidth: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        widthRatio: PropTypes.number,
        type: PropTypes.string
    }
);

export const tableHeaderColumnExport = PropTypes.shape({
    absoluteWidth: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    widthRatio: PropTypes.number
});

export const tableConfig = PropTypes.shape({
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
    hideColumns: PropTypes.array,
    columns: PropTypes.arrayOf(tableCellColumn).isRequired
});

export const error = PropTypes.shape({
    id: PropTypes.string.isRequired,
    errorType: PropTypes.string,
    message: PropTypes.string.isRequired,
    handled: PropTypes.bool.isRequired,
    title: PropTypes.string
});

export const dataLoadingError = PropTypes.shape({
    errorType: PropTypes.string,
    message: PropTypes.string,
    payload: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
});

export const errors = PropTypes.arrayOf(error);

export const createTime = PropTypes.shape({
    date: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    timezone_type: PropTypes.number.isRequired
});

export const notFullyCoveredWarning = PropTypes.shape({
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        createTime: createTime.isRequired
    })).isRequired
});

export const deprecatedDataSourceWarning = PropTypes.shape({
    name: PropTypes.string.isRequired
});

export const instagramBusinessAccountConversionMissingPostsWarning = PropTypes.shape({
    name: PropTypes.string.isRequired
});

export const youtubeTimeBasedLimitationWarning = PropTypes.shape({
    name: PropTypes.string.isRequired
});

export const facebookInsightsMissingDataWarning = PropTypes.shape({
    name: PropTypes.string.isRequired
});

export const facebookInsightsFacebookBugWarning = PropTypes.shape({
    name: PropTypes.string.isRequired,
    profiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired
});

export const useCaseViolationWarning = PropTypes.shape({
    name: PropTypes.string.isRequired,
    profiles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
    })).isRequired
});

export const metricServerResponseWarnings = PropTypes.shape({
    notFullyCovered: notFullyCoveredWarning,
    deprecatedDataSource: PropTypes.arrayOf(deprecatedDataSourceWarning),
    instagramBusinessAccountConversionMissingPosts: PropTypes.arrayOf(instagramBusinessAccountConversionMissingPostsWarning),
    youtubeTimeBasedLimitationWarning: PropTypes.arrayOf(youtubeTimeBasedLimitationWarning),
    facebookInsightsMissingData: PropTypes.arrayOf(facebookInsightsMissingDataWarning),
});

export const postSearchResponseWarnings = PropTypes.shape({
    useCaseViolation: PropTypes.arrayOf(useCaseViolationWarning)
});

export const metricServerResponse = PropTypes.shape({
    warnings: metricServerResponseWarnings,
    success: PropTypes.bool,
    infoData: PropTypes.array,
    axes: PropTypes.object,
    hcChartData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
});

export const contentWidth = PropTypes.number;

export const networkFilterOption = PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
});

export const networkFilterOptions = PropTypes.arrayOf(networkFilterOption);

export const orderBy = PropTypes.oneOfType([PropTypes.bool, PropTypes.string]);

export const filterBy = PropTypes.string;

export const metricSearchInput = PropTypes.shape({
    selectedTags: tags.isRequired,
    orderBy: PropTypes.string.isRequired,
    filterBy: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired
});

const searchResultShape = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['Metric', 'DashboardTemplate']),
    tagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    usage: PropTypes.number.isRequired,
    createTime: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    summary: PropTypes.string,
    useCases: PropTypes.string,
    platformType: PropTypes.string.isRequired
};

const searchResultDashboardTemplateShape = searchResultShape;

const searchResultMetricShape = Object.assign({}, searchResultShape, {
    visualizationId: PropTypes.string.isRequired,
    visualization: visualizationType.isRequired,
    config: metricConfig.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.string),
    dataSourceHints: metricDataSourcesHints,
    isCustomMetric: PropTypes.bool.isRequired
});

export const searchResultDashboardTemplate = PropTypes.shape(searchResultDashboardTemplateShape);

export const searchResultDashboardTemplateOrFalse = PropTypes.oneOfType([searchResultDashboardTemplate, PropTypes.bool]);

export const searchResultMetric = PropTypes.shape(searchResultMetricShape);

export const searchResultMetricOrFalse = PropTypes.oneOfType([searchResultMetric, PropTypes.bool]);

export const metricSearchQueryParams = PropTypes.shape({
    search: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    filterBy: PropTypes.string,
    orderBy: PropTypes.string
});

export const linkToDiscover = PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: metricSearchQueryParams
});

export const metricSearchResult = PropTypes.oneOfType([searchResultMetric, searchResultDashboardTemplate]);

export const metricSearchSearchResult = PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['Metric', 'DashboardTemplate']).isRequired,
        id: PropTypes.string.isRequired
    })),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object
});

export const linkTo = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object
    })
]);

export const recommendations = PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['Metric', 'DashboardTemplate']).isRequired,
        id: PropTypes.string.isRequired
    })),
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired
});

export const reduxStore = PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const modal = PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    modalType: PropTypes.string.isRequired,
    modalProps: PropTypes.object
});

export const modals = PropTypes.arrayOf(modal);

export const searchedProfile = PropTypes.shape({
    id: PropTypes.string.isRequired,
    platformType: PropTypes.string,
    platformId: PropTypes.string,
    platformProfileType: PropTypes.string,
    platformUsername: PropTypes.string,
    name: PropTypes.string,
    defaultName: PropTypes.string,
    appendix: PropTypes.string,
    picture: PropTypes.string,
    thumbnail: PropTypes.string,
    likes: PropTypes.number,
    link: PropTypes.string,
    category: PropTypes.string,
    verified: PropTypes.bool,
    hash: PropTypes.string.isRequired
});

export const searchedAdAccount = PropTypes.shape({
    id: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    platformId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    timezoneName: PropTypes.string.isRequired,
    platformTimezoneId: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    authUserName: PropTypes.string
});

export const searchedProfiles = PropTypes.arrayOf(searchedProfile);

export const accountSearchResult = PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.object
});

export const profileSearchInput = PropTypes.shape({ keyword: PropTypes.string.isRequired });

export const profileSearchOutput = PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalResultCount: PropTypes.number.isRequired,
    resultType: PropTypes.string,
    hints: PropTypes.arrayOf(PropTypes.string).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const clientLimits = PropTypes.shape({
    limits: PropTypes.shape({
        profiles: PropTypes.number.isRequired,
        profileSwaps: PropTypes.number.isRequired,
        users: PropTypes.number.isRequired,
        twitterAnalyticsProfiles: PropTypes.number.isRequired,
        dataPushTasks: PropTypes.number.isRequired,
        dataPushTaskNetworks: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    usage: PropTypes.shape({
        profiles: PropTypes.number.isRequired,
        profileSwaps: PropTypes.number.isRequired,
        users: PropTypes.number.isRequired,
        twitterAnalyticsProfiles: PropTypes.number.isRequired,
        dataPushTasks: PropTypes.number.isRequired
    }),
    freeLimits: PropTypes.shape({
        profiles: PropTypes.number,
        users: PropTypes.number,
        adAccounts: PropTypes.number,
    })
});

export const currentAccountLimitCountAndType = PropTypes.shape({
    count: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
});

export const userForManagement = PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    lastActiveTime: PropTypes.string,
    groupRestrictions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        spaceId: PropTypes.string.isRequired
    })),
    spaces: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        readOnly: PropTypes.bool.isRequired
    }))
});

export const spaceForManagement = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pageLimit: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(userForManagement).isRequired,
    profiles: PropTypes.number.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired })).isRequired,
    adAccountLimit: PropTypes.number.isRequired,
    adAccounts: PropTypes.number.isRequired
});

export const space = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customLogo: PropTypes.string.isRequired
});

export const spacesForManagement = PropTypes.arrayOf(spaceForManagement);

export const spaces = PropTypes.arrayOf(space);

export const spaceManagementData = PropTypes.shape({
    clientLimits,
    spaces
});

export const futureSwaps = PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string.isRequired,
    new: PropTypes.number.isRequired
}));

export const spaceLimits = PropTypes.shape({
    limits: PropTypes.shape({
        profiles: PropTypes.number.isRequired,
        profileSwaps: PropTypes.number.isRequired,
        adAccounts: PropTypes.number.isRequired
    }),
    usage: PropTypes.shape({
        profiles: PropTypes.number.isRequired,
        adAccounts: PropTypes.number.isRequired,
        profileSwaps: PropTypes.number.isRequired,
        futureSwaps: futureSwaps.isRequired
    })
});

export const userSpace = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
});

export const userSpaces = PropTypes.arrayOf(userSpace);

const reportFormat = PropTypes.oneOf(['pdf', 'xlsx', 'csv']);

export const standardReportSchedule = PropTypes.shape({
    intervalType: PropTypes.string.isRequired,
    intervalDetailWeekly: PropTypes.string.isRequired,
    intervalDetailMonthly: PropTypes.string.isRequired,
    reportTime: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
});

export const advancedReportSchedule = PropTypes.shape({
    cronExpression: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
});

export const dashboardReportInitialValues = PropTypes.shape({
    report: PropTypes.bool.isRequired,
    dashboardId: PropTypes.string.isRequired,
    reportProfileSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    reportDateSelection: PropTypes.oneOfType([selectedDate, PropTypes.bool]).isRequired,
    reportAdditionalFilterSelection: additionalFilterValues,
    notes: PropTypes.string.isRequired,
    recipients: PropTypes.string.isRequired,
    reportEmailMessage: PropTypes.string,
    reportMetricDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    reportAttachShareLink: PropTypes.bool.isRequired,
    standardReportSchedule,
    advancedReportSchedule,
    cronExpression: PropTypes.string,
    useCronExpression: PropTypes.bool.isRequired,
    scheduleCronTranslation: PropTypes.string,
    reportFormat
});

export const dashboardReport = PropTypes.shape({
    id: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    dashboardName: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    createTime: PropTypes.string.isRequired,
    lastUpdateTime: PropTypes.string.isRequired,
    reportFormat,
    recipients: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    showMetricDesc: PropTypes.bool.isRequired,
    emailMessage: PropTypes.string,
    attachShareLink: PropTypes.bool.isRequired,
    dateSelection: PropTypes.oneOfType([selectedDate, PropTypes.bool]).isRequired,
    profileSelection: profileFilter.isRequired,
    additionalFilterSelection: additionalFilterValues,
    scheduleTimezone: PropTypes.string.isRequired,
    scheduleIntervalType: PropTypes.string.isRequired,
    scheduleIntervalDetail: PropTypes.number.isRequired,
    scheduleTime: PropTypes.string,
    isCronScheduleEnabled: PropTypes.bool.isRequired,
    cronScheduleExpression: PropTypes.string,
    scheduleCronTranslation: PropTypes.string,
    createdByUserName: PropTypes.string.isRequired
});

export const dashboardReports = PropTypes.arrayOf(dashboardReport);

export const nextRunTimeState = PropTypes.shape({
    date: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object,
    requested: PropTypes.bool
});

export const limits = PropTypes.shape({
    label: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    usage: PropTypes.number.isRequired
});

export const customColors = PropTypes.objectOf(PropTypes.string.isRequired);

export const favorites = PropTypes.objectOf(PropTypes.bool);

export const queryTableResultData = PropTypes.arrayOf(PropTypes.object);

export const customMetric = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
});

export const tabableContents = PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired
}));

export const notification = PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
});

export const typedNotification = PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    notificationProps: PropTypes.object.isRequired
});

export const notifications = PropTypes.arrayOf(PropTypes.oneOfType([notification, typedNotification]));

export const groupRestrictions = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    spaceId: PropTypes.number
}));

export const selectListOption = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    warning: PropTypes.string
});

export const selectListOptions = PropTypes.arrayOf(selectListOption);

export const radioListOption = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
});

export const radioListOptions = PropTypes.arrayOf(selectListOption);

export const metricBuilderFormValues = PropTypes.shape({
    metricId: PropTypes.string,
    metricName: PropTypes.string.isRequired,
    visualizationType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    qqlConsole: PropTypes.string.isRequired,
    qqlConfig: PropTypes.string.isRequired,
    metricSummary: PropTypes.string.isRequired,
    isCustomMetric: PropTypes.bool.isRequired,
    isMetric: PropTypes.bool.isRequired
});

export const MetricBuilderFormFields = PropTypes.shape({
    qqlConsole: PropTypes.string.isRequired,
    visualizationType: PropTypes.number.isRequired,
    qqlConfig: PropTypes.string.isRequired,
    metricSummary: PropTypes.string.isRequired
});

export const metricBuilderInitialValues = PropTypes.oneOfType([
    MetricBuilderFormFields,
    metricBuilderFormValues,
    PropTypes.bool
]);

export const folder = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
    parentId: PropTypes.string,
    createTime: PropTypes.string.isRequired,
    lastUpdateTime: PropTypes.string.isRequired,
    createdByUserId: PropTypes.string,
    createdByUserName: PropTypes.string
});

export const folderOrFalse = PropTypes.oneOfType([
    folder,
    PropTypes.bool
]);

export const folders = PropTypes.arrayOf(folder);

export const billingAddress = PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    person: PropTypes.string.isRequired,
    vatId: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
});

export const creditCard = PropTypes.shape({
    expMonth: PropTypes.string.isRequired,
    expYear: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
    expired: PropTypes.bool.isRequired
});

export const packageStatus = PropTypes.shape({
    status: PropTypes.string.isRequired,
    blocked: PropTypes.bool.isRequired,
    packageValid: PropTypes.string.isRequired
});

export const generalInfoForModal = PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
});

export const gridBreakpoints = PropTypes.oneOf(['sm', 'lg']);

export const clientSettingExport = PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired
});

export const clientSettingTool = PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    hideInvoices: PropTypes.bool.isRequired,
    registerTime: PropTypes.number.isRequired
});

export const apiAccess = PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    secret: PropTypes.string
});

export const clientSettingSharedDashboard = PropTypes.shape({
    dynamicDate: PropTypes.string,
    sharedDashboardValidUntil: PropTypes.string,
    sharedDashboardHasExpiry: PropTypes.bool.isRequired
});

export const autocompleteSuggestionTerm = PropTypes.shape({
    item: PropTypes.shape({
        term: PropTypes.string.isRequired
    }).isRequired,
    type: PropTypes.oneOf(['term']).isRequired
});

export const autocompleteSuggestionTag = PropTypes.shape({
    item: tag.isRequired,
    type: PropTypes.oneOf(['tag']).isRequired
});

export const autocompleteSuggestions = PropTypes.arrayOf(PropTypes.oneOfType([
    autocompleteSuggestionTag,
    autocompleteSuggestionTerm
]));

export const quickAccessLinks = PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object
});

export const quickAccessSelectEntry = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired,
    platformType: PropTypes.string.isRequired
});

export const quickAccessSelectEntries = PropTypes.arrayOf(quickAccessSelectEntry);

export const dataSourceColumn = PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDeprecated: PropTypes.bool.isRequired,
    nestedIntervalSpecificColumns: PropTypes.arrayOf(PropTypes.string)
});

export const dataSourceColumns = PropTypes.arrayOf(dataSourceColumn);

export const dataSource = PropTypes.shape({
    name: PropTypes.string.isRequired,
    allowedPlatformType: PropTypes.string.isRequired,
    isPrivateStatistic: PropTypes.bool.isRequired,
    isDeprecated: PropTypes.bool.isRequired,
    isTimeSeries: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    hints: PropTypes.arrayOf(PropTypes.string).isRequired,
    isApiAccessSupported: PropTypes.bool.isRequired,
    optionalFilters: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const dataSources = PropTypes.arrayOf(dataSource);

export const VisualizationListOptions = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}));

export const customMetricQQLRunStatus = PropTypes.shape({
    hasData: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object
});

export const exportContext = PropTypes.shape({
    orderedRequestIdentifiers: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const reportContext = PropTypes.shape({
    orderedRequestIdentifiers: PropTypes.arrayOf(PropTypes.string).isRequired,
    reportSettings: PropTypes.shape({
        name: PropTypes.string.isRequired,
        notes: PropTypes.string,
        showMetricDescriptions: PropTypes.bool.isRequired,
        reportTimezone: PropTypes.string.isRequired
    })
});

export const appliedParameters = PropTypes.shape({
    start: PropTypes.number,
    limit: PropTypes.number,
    sortDir: PropTypes.string,
    sortBy: PropTypes.string,
    absoluteLimit: PropTypes.number
});

export const filter = PropTypes.shape({
    profile: PropTypes.shape({
        groupId: PropTypes.string,
        profileIds: PropTypes.arrayOf(PropTypes.string)
    }),
    date: PropTypes.shape({
        to: PropTypes.string,
        from: PropTypes.string,
        interval: PropTypes.string.isRequired,
        dynamicDate: PropTypes.string
    }).isRequired,
    postText: postTextFilter,
    postTextExclude: postTextExcludeFilter,
    postTag: postTagFilter
});

export const exportMetricRequest = PropTypes.shape({
    requestId: PropTypes.string.isRequired,
    dependencyType: PropTypes.oneOf(['metric', 'dashboardMetric']).isRequired,
    dependencyId: PropTypes.string.isRequired,
    visualizationId: PropTypes.string,
    filter: filter.isRequired,
});

export const forwardRef = PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
]);
export const metricDependency = PropTypes.shape({
    dashboardId: PropTypes.string,
    dashboardMetricId: PropTypes.string,
    metricId: PropTypes.string
});

export const metricRequest = PropTypes.shape({
    dataSources: PropTypes.arrayOf(PropTypes.string).isRequired,
    dependency: metricDependency,
    filter: filter.isRequired,
    hash: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired
});

export const userNotificationSettings = PropTypes.shape({
    productChanges: PropTypes.bool.isRequired,
    profileHealth: PropTypes.bool.isRequired
});

export const userNotificationContent = PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
});

export const userNotificationItem = PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: userNotificationContent.isRequired,
    wasRead: PropTypes.bool.isRequired,
    createTime: PropTypes.string.isRequired
});

export const userNotificationItems = PropTypes.arrayOf(userNotificationItem);

export const userMenuNotificationRequestState = PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
});

export const userPageNotificationRequestState = PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    next: PropTypes.bool.isRequired,
});

export const asyncReducerState = PropTypes.shape({
    isPending: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.string,
});

export const profilesByPlatformType = PropTypes.shape({
    facebook: profiles,
    instagram: profiles,
    twitter: profiles,
    youtube: profiles,
    linkedIn: profiles,
    snapchatShow: profiles,
    tiktok: profiles
});

export const adAccountsByPlatformType = PropTypes.shape({
    meta: adAccounts
});

export const userTransferOptions = PropTypes.shape({
    ownerShipsPerSpace: PropTypes.objectOf(PropTypes.shape({
        dashboard: PropTypes.number,
        folder: PropTypes.number,
        metrics: PropTypes.number
    })).isRequired,
    spaceInfos: PropTypes.objectOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        users: PropTypes.objectOf(PropTypes.string.isRequired)
    })).isRequired,
    notDeletedUserInfo: PropTypes.objectOf(PropTypes.string.isRequired).isRequired
});

export const missingAndAdditionalProfile = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    defaultName: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired
});

export const missingAndAdditionalProfiles = PropTypes.arrayOf(missingAndAdditionalProfile);

export const feedbackTypes = PropTypes.oneOf(['info', 'warning', 'error', 'success']);

export const linkToDataSource = PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
        platformType: PropTypes.string.isRequired,
        statsType: PropTypes.string.isRequired,
        term: PropTypes.string.isRequired,
        sortBy: PropTypes.string.isRequired
    })
});

export const dataSourceSearchParamsLocation = PropTypes.shape({
    location: PropTypes.shape({
        query: PropTypes.shape({
            platformType: PropTypes.string,
            statsType: PropTypes.string,
            term: PropTypes.string,
            sortBy: PropTypes.string
        })
    })
});

export const awsS3DataPushTaskDestination = PropTypes.shape({
    id: PropTypes.string.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    destinationType: PropTypes.string.isRequired,
    destinationLocation: PropTypes.string.isRequired,
    awsS3AccessKeyId: PropTypes.string.isRequired,
    awsS3SecretAccessKey: PropTypes.string.isRequired,
    awsS3Region: PropTypes.string.isRequired,
    awsS3Bucket: PropTypes.string.isRequired,
    awsS3FilePrefix: PropTypes.string.isRequired,
    awsS3FileFormat: PropTypes.string.isRequired,
});

export const googleBigQueryDataPushTaskDestination = PropTypes.shape({
    id: PropTypes.string.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    destinationType: PropTypes.string.isRequired,
    destinationLocation: PropTypes.string.isRequired,
    googleBigQueryProjectId: PropTypes.string.isRequired,
    googleBigQueryDatasetId: PropTypes.string.isRequired,
    googleBigQueryTableId: PropTypes.string.isRequired,
    googleBigQueryTableSchema: PropTypes.string.isRequired,
    googleBigQueryTimePartitioningType: PropTypes.string,
    googleBigQueryTimePartitioningField: PropTypes.string
});

export const dataPushTaskDestination = PropTypes.oneOfType([googleBigQueryDataPushTaskDestination, awsS3DataPushTaskDestination]);

export const dataPushTaskType = PropTypes.oneOf([dataPushTaskQQLType, dataPushTaskDataSourceType]);

export const dataPushTask = PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    dataSource: PropTypes.string,
    dataSourceColumns: PropTypes.arrayOf(PropTypes.string),
    dynamicDate: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    selectAllAdAccounts: PropTypes.bool.isRequired,
    spaceAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectAllProfiles: PropTypes.bool.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    qqlQuery: PropTypes.string.isRequired,
    delivery: PropTypes.string,
    deliveryCronString: PropTypes.string.isRequired,
    destinationId: PropTypes.number.isRequired,
    destinationType: PropTypes.string.isRequired,
    type: dataPushTaskType.isRequired
});

export const dataPushTasks = PropTypes.arrayOf(dataPushTask);

export const dataPushTaskLogStatus = PropTypes.oneOf(['open', 'pending', 'success', 'partialFailure', 'failure']).isRequired;

export const dataPushTaskLog = PropTypes.shape({
    id: PropTypes.string.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    scheduleTime: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    overriddenDynamicDate: PropTypes.string,
    rowInserted: PropTypes.number.isRequired,
    isDryRun: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    status: dataPushTaskLogStatus,
    rawDataSourceUri: PropTypes.string,
    rawDataDestinationUri: PropTypes.string,
    rawDataType: PropTypes.string,
});

export const dataPushTaskLogs = PropTypes.arrayOf(dataPushTaskLog);

export const dataSourceOptionListForDataPushTasks = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string.isRequired,
        isUsed: PropTypes.bool.isRequired
    })
);

export const packageInfo = PropTypes.shape({
    packageId: PropTypes.string,
    packageType: PropTypes.string.isRequired,
    packageStartDate: PropTypes.string.isRequired,
    packageValid: PropTypes.string.isRequired,
    packageStatus: PropTypes.string.isRequired,
    canceled: PropTypes.bool.isRequired
});

export const billingAndInvoiceLoadingState = PropTypes.shape({
    isAccountDataLoading: PropTypes.bool.isRequired,
    isInvoiceLoading: PropTypes.bool.isRequired
});

export const asyncDataLoadingState = PropTypes.shape({
    isPending: PropTypes.bool,
    success: PropTypes.bool,
    error: dataLoadingError
});

export const invoice = PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoiceDate: PropTypes.string.isRequired,
    invoiceNumber: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired
});

export const invoices = PropTypes.arrayOf(invoice);

export const listTableHeaderColumn = PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    infoPopover: PropTypes.node,
    align: PropTypes.oneOf(['right', 'left']),
    isSortable: PropTypes.bool,
    width: PropTypes.string
});

export const listTableHeaderColumns = PropTypes.arrayOf(listTableHeaderColumn);

export const listTableConfig = PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string
});

export const profileEvent = PropTypes.shape({
    id: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    createdTime: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['positive', 'negative', 'neutral']).isRequired,
    content: PropTypes.string.isRequired
});

export const profileEvents = PropTypes.arrayOf(profileEvent);

export const platformProfileTypeInfo = PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
});

export const profileHealthMetric = PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
});

export const profileHealthMetrics = PropTypes.arrayOf(profileHealthMetric);

export const profileHealthMetricData = PropTypes.shape({
    yAxisCategories: PropTypes.array.isRequired,
    metrics: profileHealthMetrics
});

export const profileHealth = PropTypes.shape({
    profileId: PropTypes.string.isRequired,
    isHealthy: PropTypes.bool.isRequired,
    unHealthyType: PropTypes.string,
    metaData: PropTypes.shape({
        link: PropTypes.string
    })
});

export const ownershipType = PropTypes.oneOf(ownershipTypes);

export const ownershipEntity = PropTypes.oneOf(ownershipEntities);

export const postTag = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    creatorUserName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
    automation: PropTypes.bool.isRequired,
    postTagRuleIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const postTags = PropTypes.arrayOf(postTag);

export const postTagDataReturn = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
});

export const postTagsDataReturn = PropTypes.arrayOf(postTagDataReturn);

export const postTagsPostsInfo = PropTypes.shape({
    postTagId: PropTypes.string.isRequired,
    numberOfPosts: PropTypes.number.isRequired
});

export const postTagTableConfig = PropTypes.shape({
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired,
    showArchived: PropTypes.bool.isRequired
});

export const postKpi = PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const postKpis = PropTypes.arrayOf(postKpi);

export const postContentItem = PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string
});

export const postContentItems = PropTypes.arrayOf(postContentItem);

export const derivedPostPostTag = PropTypes.shape({
    postTagId: PropTypes.string.isRequired,
    postTagName: PropTypes.string.isRequired,
    tagUserName: PropTypes.string,
    postTagRuleId: PropTypes.string,
    tagTime: PropTypes.string
});

export const derivedPostPostTags = PropTypes.arrayOf(derivedPostPostTag);

export const postPostTag = PropTypes.shape({
    postTagId: PropTypes.string.isRequired,
    tagUserName: PropTypes.string,
    postTagRuleId: PropTypes.string,
    tagTime: PropTypes.string,
    name: PropTypes.string.isRequired
});

export const post = PropTypes.shape({
    id: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    postTypeName: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    contentItems: postContentItems.isRequired,
    snippet: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    kpis: postKpis.isRequired,
    postPostTags: PropTypes.objectOf(postPostTag).isRequired
});

export const posts = PropTypes.arrayOf(post);

export const postSearchRequestInputParams = PropTypes.shape({
    start: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    sortDir: PropTypes.string.isRequired
});

export const postSearchRequestInput = PropTypes.shape({
    // setting this as object cause the shape itself should never been used
    filter: PropTypes.object.isRequired,
    parameters: postSearchRequestInputParams.isRequired,
    hash: PropTypes.string.isRequired
});

export const outputFilterGroup = PropTypes.shape({
    date: dateFilter,
    profile: profileFilter,
    postText: postTextFilter,
    postTextExclude: postTextExcludeFilter,
    postTag: postTagFilter
});

export const appliedOutputFilter = PropTypes.shape({
    fullyApplied: outputFilterGroup,
    notApplied: outputFilterGroup,
    partiallyApplied: outputFilterGroup
});

export const requestInfo = PropTypes.shape({
    filters: appliedOutputFilter.isRequired,
    parameters: appliedParameters.isRequired
});

export const forceOperationalEntityInfo = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dashboardNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    postTagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasBindingsToOtherUserDashboards: PropTypes.bool.isRequired,
    hasBindingsToDashboardShareLinks: PropTypes.bool.isRequired
});

export const forceOperationalEntityInfos = PropTypes.arrayOf(forceOperationalEntityInfo);

export const imageInput = PropTypes.shape({
    url: PropTypes.string.isRequired,
    file: PropTypes.object,
    meta: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        size: PropTypes.number
    })
});

export const fileInput = PropTypes.shape({
    url: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired
    })
});

export const postTagRule = PropTypes.shape({
    id: PropTypes.string.isRequired,
    profileSelection: profileFilter.isRequired,
    additionalFilterSelection: additionalFilterValues,
    dateSelection: postTagRuleDateSelection.isRequired,
    createdByUser: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    lastUpdatedTime: PropTypes.string.isRequired,
    lastUpdatedUserName: PropTypes.string.isRequired,
    postTagId: PropTypes.string.isRequired
});

export const postTagRules = PropTypes.arrayOf(postTagRule);

export const postTagRuleTableConfig = PropTypes.shape({
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired
});

export const postTagInitialValues = PropTypes.shape({
    name: PropTypes.string.isRequired,
    archived: PropTypes.bool.isRequired,
    automation: PropTypes.bool.isRequired
});

export const fullyAppliedFilters = PropTypes.shape({
    profile: profileFilter,
    date: dateFilter,
    postText: postTextFilter,
    postTextExclude: postTextFilter,
    postTag: postTagFilter
});

export const showLinkInTable = PropTypes.oneOf(['none', 'in-app', 'public']);

export const tableCellSetting = PropTypes.shape({
    showLink: showLinkInTable
});

export const metaDataValueType = PropTypes.oneOf(['percent', 'decimal', 'integer', 'shortenInteger']);

export const filterContext = PropTypes.oneOf([QUICK_ANALYSIS, DISCOVER_PREVIEW, METRIC_BUILDER, DASHBOARD, POST_TAGGING]);

export const auth0 = PropTypes.shape({
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    getAccessTokenSilently: PropTypes.func.isRequired
});

export const authenticator = PropTypes.shape({
    getAccessTokenSilently: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    handleAuthorizedServerRequest: PropTypes.func.isRequired
});

export const userInvitation = PropTypes.shape({
    id: PropTypes.string.isRequired,
    invitationUrl: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdAtTimeZone: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired,
    expiresAtTimeZone: PropTypes.string.isRequired,
    inviter: PropTypes.string.isRequired
});

export const userIdentity = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    connection: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    isSocial: PropTypes.bool.isRequired
});

export const profileAudienceData = PropTypes.shape({
    profileId: PropTypes.string.isRequired,
    category: PropTypes.oneOf(['high', 'medium', 'low', 'none']).isRequired,
    since: PropTypes.string,
    numberOfAvailableDays: PropTypes.number.isRequired
});

export const profilesAudienceData = PropTypes.arrayOf(profileAudienceData);

export const dashboardShareLink = PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdByUserName: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    dashboardName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    dateSelection: PropTypes.oneOfType([selectedDate, PropTypes.bool]),
    profileSelection: profileFilter,
    additionalFilterSelection: additionalFilterValues,
    validUntil: PropTypes.string,
    hasExpiry: PropTypes.bool.isRequired,
    isPasswordProtected: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    numberOfVisits: PropTypes.number.isRequired,
    lastVisitTime: PropTypes.string
});

export const dashboardShareLinks = PropTypes.arrayOf(dashboardShareLink);

export const reportOverview = PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    numberOfVisits: PropTypes.number,
    lastVisitTime: PropTypes.string
});

export const reportOverviews = PropTypes.arrayOf(reportOverview);

export const multiFactorAuthenticationEnrollmentState = PropTypes.shape({
    status: PropTypes.string.isRequired,
    enrolledAt: PropTypes.string.isRequired,
    enrolledAtTimeZone: PropTypes.string.isRequired
});

export const dashboardShareLinkStats = PropTypes.shape({
    views: PropTypes.number.isRequired,
    lastView: PropTypes.string.isRequired,
    dashboardShareLinkId: PropTypes.string.isRequired
});

export const limitAndUsage = PropTypes.shape({
    limit: PropTypes.number.isRequired,
    usage: PropTypes.number.isRequired
});

export const dataPushTaskLimitAndUsage = PropTypes.shape({
    limit: PropTypes.number.isRequired,
    usage: PropTypes.number.isRequired,
    dataPushTaskNetworks: PropTypes.arrayOf(PropTypes.string)
});

export const listOption = PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
});

export const listOptions = PropTypes.arrayOf(listOption);

export const inlineListOption = PropTypes.shape({
    option: listOption,
    isIndeterminate: PropTypes.bool.isRequired
});

export const inlineListOptions = PropTypes.arrayOf(inlineListOption);

export const creatableOption = PropTypes.shape({
    optionToCreate: PropTypes.string.isRequired,
    validationError: PropTypes.string
});

export const platformTypes = PropTypes.oneOf(['facebook', 'instagram', 'twitter', 'tiktok', 'linkedIn', 'snapchatShow', 'youtube', 'meta', 'threads']);

export const accountTypes = PropTypes.oneOf(['profiles', 'adAccounts']);

export const account = PropTypes.oneOfType([profile, adAccount]);
export const accounts = PropTypes.arrayOf(account);

export const accountUseCasesAuthUser = PropTypes.shape({
    userId: PropTypes.string,
    useCaseId: PropTypes.string.isRequired,
    profileId: PropTypes.string,
    adAccountId: PropTypes.string,
    valid: PropTypes.bool.isRequired,
    invalidReason: PropTypes.string
});

export const authUserAuthAccountUseCase = PropTypes.shape({
    userId: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired,
    profileId: PropTypes.string,
    adAccountId: PropTypes.string,
});

export const accountUseCasesAuthUsers = PropTypes.arrayOf(accountUseCasesAuthUser);
export const authUserAuthAccountUseCases = PropTypes.arrayOf(authUserAuthAccountUseCase);

export const brokenProfileUseCases = PropTypes.shape({
    profileId: PropTypes.string.isRequired,
    invalidReasonsByUseCaseId: PropTypes.objectOf(PropTypes.string).isRequired
});

export const brokenAdAccountUseCases = PropTypes.shape({
    adAccountId: PropTypes.string.isRequired,
    invalidReasonsByUseCaseId: PropTypes.objectOf(PropTypes.string).isRequired
});
export const brokenProfilesUseCases = PropTypes.arrayOf(brokenProfileUseCases);
export const brokenAdAccountsUseCases = PropTypes.arrayOf(brokenAdAccountUseCases);
export const brokenProfilesUseCasesByPlatformType = PropTypes.objectOf(brokenProfilesUseCases);
export const brokenAdAccountsUseCasesByPlatformType = PropTypes.objectOf(brokenAdAccountsUseCases);

export const brokenAccountsUseCasesByPlatformType = PropTypes.oneOfType([brokenProfilesUseCasesByPlatformType, brokenAdAccountsUseCasesByPlatformType]);

export const profileAuthTransactionRequirement = PropTypes.shape({
    id: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired
});

export const profileAuthTransactionRequirements = PropTypes.arrayOf(profileAuthTransactionRequirement);

export const adAccountAuthTransactionRequirement = PropTypes.shape({
    id: PropTypes.string.isRequired,
    useCaseId: PropTypes.string.isRequired,
    adAccountId: PropTypes.string.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired
});

export const adAccountAuthTransactionRequirements = PropTypes.arrayOf(adAccountAuthTransactionRequirement);

export const authTransaction = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    createTime: PropTypes.string.isRequired,
    closeTime: PropTypes.string.isRequired,
    validUntil: PropTypes.string.isRequired,
    createdByUserId: PropTypes.string.isRequired,
    createdByUserName: PropTypes.string.isRequired,
    hash: PropTypes.string,
    profileAuthTransactionRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    adAccountAuthTransactionRequirementIds: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const authTransactions = PropTypes.arrayOf(authTransaction);

export const authTransactionRequirementsByPlatformType = PropTypes.shape({
    facebook: PropTypes.objectOf(profileAuthTransactionRequirements),
    instagram: PropTypes.objectOf(profileAuthTransactionRequirements),
    twitter: PropTypes.objectOf(profileAuthTransactionRequirements),
    youtube: PropTypes.objectOf(profileAuthTransactionRequirements),
    linkedIn: PropTypes.objectOf(profileAuthTransactionRequirements),
    snapchatShow: PropTypes.objectOf(profileAuthTransactionRequirements),
    tiktok: PropTypes.objectOf(profileAuthTransactionRequirements)
});

export const adAccountAuthTransactionRequirementsByPlatformType = PropTypes.shape({
    meta: PropTypes.objectOf(adAccountAuthTransactionRequirements),
});

export const topContentData = PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    comparisonSentence: PropTypes.string.isRequired,
    changeValue: PropTypes.shape({
        value: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired
}));

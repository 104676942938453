export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';

export const MODAL_HIDE_ALL = 'MODAL_HIDE_ALL';

export const MODAL_SHOW_AS_ROUTE = 'MODAL_SHOW_AS_ROUTE';
export const MODAL_HIDE_AS_ROUTE = 'MODAL_HIDE_AS_ROUTE';

export const createIdentifier = (type, uniqueIdentifier) => {
    if (uniqueIdentifier !== null) {
        return `${type}-${uniqueIdentifier}`;
    }
    return `${type}`;
};

export const modalsHide = (identifier, modalType) => ({
    type: MODAL_HIDE,
    payload: { identifier, modalType }
});

export const modalsShow = (identifier, modalType, modalProps) => ({
    type: MODAL_SHOW,
    payload: {
        identifier,
        modalType,
        modalProps
    }
});

export const modalsHideAll = () => ({
    type: MODAL_HIDE_ALL
});

export const modalsShowAsRoute = (modalType, idProp) => ({
    type: MODAL_SHOW_AS_ROUTE,
    payload: {
        modalType,
        idProp
    }
});

export const modalsHideAsRoute = (modalType, idProp) => ({
    type: MODAL_HIDE_AS_ROUTE,
    payload: {
        modalType,
        idProp
    }
});

export const modalsHideAsRouteByModalTypeOnly = (modalType) => ({
    type: MODAL_HIDE_AS_ROUTE,
    payload: {
        modalType,
        ignoreIdProp: true
    }
});

const makeModalsHide = (modalType, uniqueIdentifier = null) => modalsHide(createIdentifier(modalType, uniqueIdentifier), modalType);
const makeModalsShow = (modalType, modalProps = {}, uniqueIdentifier = null) => modalsShow(createIdentifier(modalType, uniqueIdentifier), modalType, modalProps);

const makeModalsShowAsRoute = (modalType, idProp = undefined) => modalsShowAsRoute(modalType, idProp);
const makeModalsHideAsRoute = (modalType, idProp = undefined) => modalsHideAsRoute(modalType, idProp);
const makeModalsHideAsRouteByModalTypeOnly = (modalType) => modalsHideAsRouteByModalTypeOnly(modalType);

export const modalShowAddDashboardChoice = (folderId) => makeModalsShow('AddDashboardChoiceModal', { folderId });
export const modalHideAddDashboardChoice = () => makeModalsHide('AddDashboardChoiceModal');

export const modalsShowCreateDashboard = (folderId, jumpToDashboard) => makeModalsShow('CreateDashboardModal', { folderId, jumpToDashboard });
export const modalsHideCreateDashboard = () => makeModalsHide('CreateDashboardModal');

export const modalsShowSaveDashboard = (dashboardTemplateId) => makeModalsShow('SaveDashboardModal', { dashboardTemplateId }, dashboardTemplateId);
export const modalsHideSaveDashboard = (dashboardTemplateId) => makeModalsHide('SaveDashboardModal', dashboardTemplateId);

export const modalsShowCloneDashboard = (dashboardId, folderId) => makeModalsShow('CloneDashboardModal', { dashboardId, folderId });
export const modalsHideCloneDashboard = () => makeModalsHide('CloneDashboardModal');

export const modalsShowCreateReport = (dashboardId) => makeModalsShowAsRoute('CreateReportModal', dashboardId);
export const modalsHideCreateReport = (dashboardId) => makeModalsHideAsRoute('CreateReportModal', dashboardId);

export const modalsShowEditReport = (dashboardReportId) => makeModalsShowAsRoute('EditReportModal', dashboardReportId);
export const modalsHideEditReport = (dashboardReportId) => makeModalsHideAsRoute('EditReportModal', dashboardReportId);

export const modalsShowDashboardMetricDelete = (dashboardMetricId, dashboardId, metricId) => makeModalsShow('DashboardMetricDeleteModal', { dashboardMetricId, dashboardId, metricId }, dashboardMetricId);
export const modalsHideDashboardMetricDelete = (dashboardMetricId) => makeModalsHide('DashboardMetricDeleteModal', dashboardMetricId);

export const modalsShowEditDashboard = (dashboardId, redirectToDashboards = false) => makeModalsShow('EditDashboardModal', { dashboardId, redirectToDashboards }, dashboardId);
export const modaslHideEditDashboard = (dashboardId) => makeModalsHide('EditDashboardModal', dashboardId);

export const modalsShowProfileDelete = (profileIds) => makeModalsShow('ProfileDeleteModal', { profileIds });
export const modalsHideProfileDelete = () => makeModalsHide('ProfileDeleteModal');

export const modalsShowAdAccountDelete = (adAccountIds) => makeModalsShow('AdAccountDeleteModal', { adAccountIds });
export const modalsHideAdAccountDelete = () => makeModalsHide('AdAccountDeleteModal');

export const modalsShowDeleteCustomMetric = (metricId) => makeModalsShow('CustomMetricDeleteModal', { metricId }, metricId);
export const modalsHideDeleteCustomMetric = (metricId) => makeModalsHide('CustomMetricDeleteModal', metricId);

export const modalShowSaveAsCustomMetric = () => makeModalsShow('CustomMetricSaveAsModal');
export const modalHideSaveAsCustomMetric = () => makeModalsHide('CustomMetricSaveAsModal');

export const modalShowSaveCustomMetric = () => makeModalsShow('CustomMetricSaveModal');
export const modalHideSaveCustomMetric = () => makeModalsHide('CustomMetricSaveModal');

export const modalShowTwitterUserInteraction = (userId, userName, metricRequest) => makeModalsShow('TwitterUserInteractionModal', { userId, userName, metricRequest }, userId);
export const modalHideTwitterUserInteraction = (userId) => makeModalsHide('TwitterUserInteractionModal', userId);

export const modalShowTwitterRetweeters = (tweetId, profileId, author, metricRequest) => makeModalsShow('TwitterRetweetersModal',
    {
        tweetId, profileId, author, metricRequest
    }, tweetId);
export const modalHideTwitterRetweeters = (tweetId) => makeModalsHide('TwitterRetweetersModal', tweetId);

export const modalShowMetricSettingsTile = (dashboardMetricId) => makeModalsShowAsRoute('MetricSettingsTileModal', dashboardMetricId);
export const modalHideMetricSettingsTile = (dashboardMetricId) => makeModalsHideAsRoute('MetricSettingsTileModal', dashboardMetricId);

export const modalShowCreateUser = () => makeModalsShowAsRoute('UserCreateModal');
export const modalsHideCreateUser = () => makeModalsHideAsRoute('UserCreateModal');

export const modalShowEditUser = (userId) => makeModalsShowAsRoute('UserEditModal', userId);
export const modalsHideEditUser = (userId) => makeModalsHideAsRoute('UserEditModal', userId);

export const modalShowDeleteUser = (userId, email) => makeModalsShow('UserDeleteModal', { userId, email }, userId);
export const modalsHideDeleteUser = (userId) => makeModalsHide('UserDeleteModal', userId);

export const modalShowTransferAndDeleteUser = (userId, transferOptions) => makeModalsShow('UserTransferAndDeleteModal', { userId, transferOptions }, userId);
export const modalsHideTransferAndDeleteUser = (userId) => makeModalsHide('UserTransferAndDeleteModal', userId);

export const modalShowSpaceEdit = (spaceId) => makeModalsShow('SpaceEditModal', { spaceId }, spaceId);
export const modalShowSpaceCreate = () => makeModalsShow('SpaceCreateModal');

export const modalsHideEditSpace = (spaceId) => makeModalsHide('SpaceEditModal', spaceId);
export const modalsHideCreateSpace = () => makeModalsHide('SpaceCreateModal');

export const modalShowDeleteSpace = (spaceId, spaceName) => makeModalsShow('SpaceDeleteModal', { spaceId, spaceName }, spaceId);
export const modalsHideDeleteSpace = (spaceId) => makeModalsHide('SpaceDeleteModal', spaceId);

export const modalsShowGroupCreate = () => makeModalsShow('GroupCreateModal');
export const modalsHideGroupCreate = () => makeModalsHide('GroupCreateModal');

export const modalsShowGroupEdit = () => makeModalsShow('GroupEditModal');

export const modalsShowGroupDelete = (groupId) => makeModalsShow('GroupDeleteModal', { groupId });
export const modalsHideGroupDelete = () => makeModalsHide('GroupDeleteModal');

export const modalShowCreateShareableLink = (dashboardId, dashboardShareLinkId = null) => makeModalsShow('CreateShareableLinkModal', { dashboardId, dashboardShareLinkId }, dashboardId);
export const modalHideCreateShareableLink = (dashboardId) => makeModalsHide('CreateShareableLinkModal', dashboardId);

export const modalShowEditShareableLink = (dashboardShareLinkId) => makeModalsShow('EditShareableLinkModal', { dashboardShareLinkId }, dashboardShareLinkId);
export const modalHideEditShareableLink = (dashboardShareLinkId) => makeModalsHide('EditShareableLinkModal', dashboardShareLinkId);

export const modalShowCreatedLink = (exportedLink, validUntil) => makeModalsShow('CreatedLinkModal', { exportedLink, validUntil });
export const modalHideCreatedLink = () => makeModalsHide('CreatedLinkModal');

export const modalsShowProfileSearch = () => makeModalsShowAsRoute('ProfileSearchModal');
export const modalsHideProfileSearch = () => makeModalsHideAsRouteByModalTypeOnly('ProfileSearchModal');

export const modalsHideOnboardingProfileSearch = () => makeModalsHide('OnboardingProfileSearchModal');

export const modalsShowAcceptTermsAndConditions = () => makeModalsShow('AcceptTermsAndConditionsModal');
export const modalsHideAcceptTermsAndConditions = () => makeModalsHide('AcceptTermsAndConditionsModal');

export const modalShowProfilesAddedFailedStatus = (addedProfileIds, failedSearchedProfileLinks) => makeModalsShow('ProfilesAddedFailedStatusModal', { addedProfileIds, failedSearchedProfileLinks });
export const modalHideProfilesAddedFailedStatus = () => makeModalsHide('ProfilesAddedFailedStatusModal');

export const modalShowAddMetricToDashboard = (metricId, isTable) => makeModalsShow('AddMetricToDashboardModal', { metricId, isTable }, metricId);
export const modalHideAddMetricToDashboard = (metricId) => makeModalsHide('AddMetricToDashboardModal', metricId);

export const modalsShowProfileDetails = (profileId) => makeModalsShowAsRoute('ProfileDetailsModal', profileId);
export const modalsHideProfileDetails = (profileId) => makeModalsHideAsRoute('ProfileDetailsModal', profileId);

export const modalsShowAdAccountDetails = (adAccountId) => makeModalsShowAsRoute('AdAccountDetailsModal', adAccountId);
export const modalsHideAdAccountDetails = (adAccountId) => makeModalsHideAsRoute('AdAccountDetailsModal', adAccountId);

export const modalsShowOnboardingProfileDetails = (profileId) => makeModalsShow('OnboardingProfileDetailModal', { profileId });
export const modalsHideOnboardingProfileDetails = (profileId) => makeModalsShow('OnboardingProfileDetailModal', { profileId });

export const modalsShowGroupSelectorProfile = (profileIds) => makeModalsShow('GroupSelectorProfileModal', { profileIds });
export const modalsHideGroupSelectorProfile = () => makeModalsHide('GroupSelectorProfileModal');

export const modalsShowGroupSelectorAdAccount = (adAccountIds) => makeModalsShow('GroupSelectorAdAccountModal', { adAccountIds });
export const modalsHideGroupSelectorAdAccount = () => makeModalsHide('GroupSelectorAdAccountModal');

export const modalShowAddFolder = (folderId, context = 'dashboard') => makeModalsShow('FolderAddModal', { folderId, context });
export const modalHideAddFolder = () => makeModalsHide('FolderAddModal');

export const modalShowEditFolder = (folderId, parentId) => makeModalsShow('FolderEditModal', { folderId, parentId }, folderId);
export const modalHideEditFolder = (folderId) => makeModalsHide('FolderEditModal', folderId);

export const modalShowMoveToFolder = (folderIds, dashboardIds, fromFolderId, fromFolderName) => makeModalsShow('MoveToFolderModal', {
    folderIds, dashboardIds, fromFolderId, fromFolderName
});
export const modalHideMoveToFolder = () => makeModalsHide('MoveToFolderModal');

export const modalsShowDashboardAndFolderDelete = (folderIds, dashboardIds, fromFolderId, redirectToDashboards) => makeModalsShow('DashboardAndFolderDeleteModal', {
    dashboardIds, folderIds, fromFolderId, redirectToDashboards
});
export const modalsHideDashboardAndFolderDelete = () => makeModalsHide('DashboardAndFolderDeleteModal');

export const modalShowDeleteAccount = () => makeModalsShow('DeleteAccountModal');
export const modalHideDeleteAccount = () => makeModalsHide('DeleteAccountModal');

export const modalsShowSubscriptionModal = (packageStatus) => makeModalsShow('SubscriptionModal', { packageStatus });
export const modalsHideSubscriptionModal = () => makeModalsHide('SubscriptionModal');

export const modalsShowGeneralInfo = (info) => makeModalsShow('GeneralInfoModal', { info });

export const modalShowUserPermissionError = (message) => makeModalsShow('UserPermissionErrorModal', { message });

export const modalsShowForceDeleteProfileModal = (forceOperationalProfileInfos, notRemovedProfileIds) => makeModalsShow('ForceDeleteProfileModalContainer', { forceOperationalProfileInfos, notRemovedProfileIds });
export const modalsHideForceDeleteProfileModal = () => makeModalsHide('ForceDeleteProfileModalContainer');

export const modalsShowForceDeleteGroupModal = (forceOperationalGroupInfos, notRemovedGroupIds) => makeModalsShow('ForceDeleteGroupModalContainer', { forceOperationalGroupInfos, notRemovedGroupIds });
export const modalsHideForceDeleteGroupModal = () => makeModalsHide('ForceDeleteGroupModalContainer');

export const modalsShowForceDeleteAdAccountModal = (forceOperationalAdAccountInfos, notRemovedAdAccountIds) => makeModalsShow('ForceDeleteAdAccountModalContainer', { forceOperationalAdAccountInfos, notRemovedAdAccountIds });
export const modalsHideForceDeleteAdAccountModal = () => makeModalsHide('ForceDeleteAdAccountModalContainer');

export const modalsShowEmptyDashboardExportError = () => makeModalsShow('EmptyDashboardExportError');
export const modalsHideEmptyDashboardExportError = () => makeModalsHide('EmptyDashboardExportError');

export const modalsShowUserNotificationSettings = () => makeModalsShowAsRoute('UserNotificationSettingsModal');
export const modalsHideUserNotificationSettings = () => makeModalsHideAsRoute('UserNotificationSettingsModal');

export const modalsShowSessionInvalidModal = (message) => makeModalsShow('SessionInvalidModal', { message });

export const modalsShowAuthUserDelete = (authUser) => makeModalsShow('AuthUserDeleteModal', { authUser });
export const modalsHideAuthUserDelete = () => makeModalsHide('AuthUserDeleteModal');

// authTransaction create step modals
export const modalsShowCreateAuthTransactionSelectProfiles = (initialProfileIds = [], initialAdAccountIds = [], selectBrokenProfileUseCases = false) => makeModalsShow('CreateAuthTransactionSelectProfilesModal', {
    initialProfileIds, initialAdAccountIds, selectBrokenProfileUseCases
});
export const modalsShowCreateAuthTransactionRequirements = (checkedProfileIds, checkedAdAccountIds, selectBrokenProfileUseCases = false) => makeModalsShow('CreateAuthTransactionRequirementsModal', {
    checkedProfileIds, checkedAdAccountIds, selectBrokenProfileUseCases
});
export const modalsHideCreateAuthTransactionRequirements = () => makeModalsHide('CreateAuthTransactionRequirementsModal');

export const modalsShowAddUpdateAuthUsers = (authTransactionId) => makeModalsShowAsRoute('AddUpdateAuthUsersModal', authTransactionId);
export const modalsHideAddUpdateAuthUsers = (authTransactionId) => makeModalsHideAsRoute('AddUpdateAuthUsersModal', authTransactionId);
export const modalsShowAuthTransactionsDetail = () => makeModalsShow('AuthTransactionsDetailModal');
export const modalsHideAuthTransactionsDetail = () => makeModalsHide('AuthTransactionsDetailModal');
export const modalsShowAuthTransactionDetail = (authTransactionId) => makeModalsShow('AuthTransactionDetailModal', { authTransactionId });
export const modalsHideAuthTransactionDetail = () => makeModalsHide('AuthTransactionDetailModal');
export const modalsShowAuthTransactionClose = (id) => makeModalsShow('AuthTransactionCloseModal', { id });
export const modalsHideAuthTransactionClose = () => makeModalsHide('AuthTransactionCloseModal');

export const modalsShowExternalAuthTransactionClose = (id) => makeModalsShow('ExternalAuthTransactionCloseModal', { id });

export const modalsShowFacebookAuthUserInAnotherSpaceAuthTransactionWarning = (identifier, authTransactionId, authTransactionRequirementIds, shortLiveAccessToken, profileIds, adAccountIds, isPublic) => makeModalsShow('FacebookAuthUserInAnotherSpaceAuthTransactionWarningIngModal', {
    identifier, authTransactionId, authTransactionRequirementIds, shortLiveAccessToken, profileIds, adAccountIds, isPublic
});

export const modalsShowFacebookAuthUserInAnotherSpaceExternalAuthenticationWarning = (accessToken, accountType) => makeModalsShow('FacebookAuthUserInAnotherSpaceExternalAuthenticationWarning', { accessToken, accountType });
export const modalsShowFacebookAuthUserInAnotherSocialNetworkSearchWarning = (accessToken, accountType) => makeModalsShow('FacebookAuthUserInAnotherSocialNetworkSearchWarningModal', { accessToken, accountType });

export const modalsShowLinkedInAuthUserInAnotherSpaceAuthTransactionWarning = (identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, profileIds, isPublic) => makeModalsShow('LinkedInAuthUserInAnotherSpaceAuthTransactionWarningModal', {
    identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, profileIds, isPublic
});

export const modalsShowLinkedInAuthUserInAnotherSpaceExternalAuthenticationWarning = (accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName) => makeModalsShow('LinkedInAuthUserInAnotherSpaceExternalAuthenticationWarningModal', {
    accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName
});

export const modalsLinkedInAuthUserInAnotherSpaceSocialNetworkSearchWarning = (accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName) => makeModalsShow('LinkedInAuthUserInAnotherSpaceSocialNetworkSearchWarningModal', {
    accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName
});

export const modalsShowSnapchatAuthUserInAnotherSpaceAuthTransactionWarning = (identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshAccessToken, expiresIn, platformUserId, displayName, profileIds, isPublic) => makeModalsShow('SnapchatAuthUserInAnotherSpaceAuthTransactionWarningModal', {
    identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshAccessToken, expiresIn, platformUserId, displayName, profileIds, isPublic
});

export const modalsShowSnapchatAuthUserInAnotherSpaceExternalAuthenticationWarning = (accessToken, refreshAccessToken, expiresIn, platformUserId, displayName) => makeModalsShow('SnapchatAuthUserInAnotherSpaceExternalAuthenticationWarningModal', {
    accessToken, refreshAccessToken, expiresIn, platformUserId, displayName
});

export const modalsShowSnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarning = (accessToken, refreshAccessToken, expiresIn, platformUserId, displayName) => makeModalsShow('SnapchatAuthUserInAnotherSpaceSocialNetworkSearchWarningModal', {
    accessToken, refreshAccessToken, expiresIn, platformUserId, displayName
});

export const modalShowActivateBulkProfileUseCases = (profileIds) => makeModalsShow('ActivateBulkProfileUseCasesModal', { profileIds });
export const modalHideActivateBulkProfileUseCases = () => makeModalsHide('ActivateBulkProfileUseCasesModal');

export const modalShowProfileList = (profileIds) => makeModalsShow('ProfileListModal', { profileIds });
export const modalShowAceEditor = (title, value, mode) => makeModalsShow('AceEditorModal', { title, value, mode });

export const modalShowSharedToPrivateOwnershipWarningModal = (folderIds, dashboardIds, toFolderId, fromFolderId) => makeModalsShow('SharedToPrivateOwnershipWarningModal', {
    folderIds, dashboardIds, toFolderId, fromFolderId
});

export const modalShowPostTagsDelete = (postTagIds) => makeModalsShow('PostTagsDeleteModal', { postTagIds });
export const modalHidePostTagsDelete = () => makeModalsHide('PostTagsDeleteModal');

export const modalShowPostTagsForceDelete = (forceDeletableTagInfos, nonDeletableTagIds) => makeModalsShow('PostTagsForceDeleteModalContainer', { forceDeletableTagInfos, nonDeletableTagIds });
export const modalHidePostTagsForceDelete = () => makeModalsHide('PostTagsForceDeleteModalContainer');

export const modalShowPostTagsForceArchive = (forceArchivableTagInfos, nonArchivableTagIds) => makeModalsShow('PostTagsForceArchiveModalContainer', { forceArchivableTagInfos, nonArchivableTagIds });
export const modalHidePostTagsForceArchive = () => makeModalsHide('PostTagsForceArchiveModalContainer');

export const modalShowPostTagAdd = () => makeModalsShow('PostTagAddModal');
export const modalHidePostTagAdd = () => makeModalsHide('PostTagAddModal');

export const modalShowAddOrRemovePostTagsFromPosts = (postIds) => makeModalsShow('AddOrRemovePostTagsFromPostsModal', { postIds });
export const modalHideAddOrRemovePostTagsFromPosts = () => makeModalsHide('AddOrRemovePostTagsFromPostsModal');

export const modalShowPostDetailInPostTagging = (postId, hasPostsTagsBeenUpdated = false) => makeModalsShow('PostDetailInPostTaggingModal', { postId, hasPostsTagsBeenUpdated });
export const modalHidePostDetailInPostTagging = () => makeModalsHide('PostDetailInPostTaggingModal');

export const modalShowPostDetailInPostTagManager = (postId, postTagId) => makeModalsShow('PostDetailInPostTagManagerModal', { postId, postTagId });
export const modalHidePostDetailInPostTagManager = () => makeModalsHide('PostDetailInPostTagManagerModal');

export const modalShowPostDetailInAnalysis = (postId, highlightKeywords, metricRequest) => makeModalsShow('PostDetailInAnalysisModal', { postId, highlightKeywords, metricRequest });
export const modalHidePostDetailInAnalysis = () => makeModalsHide('PostDetailInAnalysisModal');

export const modalShowAddOrRemovePostTagsFromPost = (postId, performPostSearch = false, onUpdateButtonClick) => makeModalsShow('AddOrRemovePostTagsFromPostModal', { postId, onUpdateButtonClick, performPostSearch });
export const modalHideAddOrRemovePostTagsFromPost = () => makeModalsHide('AddOrRemovePostTagsFromPostModal');

export const modalShowCloneDashboardMetric = (dashboardMetricId) => makeModalsShow('CloneDashboardMetricModal', { dashboardMetricId });
export const modalHideCloneDashboardMetric = () => makeModalsHide('CloneDashboardMetricModal');

export const modalShowMoveDashboardMetric = (dashboardMetricId) => makeModalsShow('MoveDashboardMetricModal', { dashboardMetricId });
export const modalHideMoveDashboardMetric = () => makeModalsHide('MoveDashboardMetricModal');

export const modalShowDashboardSearchableList = (onDashboardClick, disabledDashboardIds) => makeModalsShow('DashboardSearchableListModal', { onDashboardClick, disabledDashboardIds });
export const modalHideDashboardSearchableListModal = () => makeModalsHide('DashboardSearchableListModal');

export const modalShowPostTagRulesDelete = (postTagRuleId) => makeModalsShow('PostTagRulesDeleteModal', { postTagRuleId });
export const modalHidePostTagRulesDelete = () => makeModalsHide('PostTagRulesDeleteModal');

export const modalShowPostTagRuleWarning = (operationType, warningMessage) => makeModalsShow('PostTagRuleWarningModalModal', { operationType, warningMessage });
export const modalHidePostTagRuleWarning = () => makeModalsHide('PostTagRuleWarningModalModal');

export const modalShowPostTagEditWarning = (errorMessage) => makeModalsShow('PostTagEditWarningModal', { errorMessage });
export const modalHidePostTagEditWarning = () => makeModalsHide('PostTagEditWarningModal');

export const modalShowPostTagAutomationWarning = (postTagId) => makeModalsShow('PostTagAutomationWarningModal', { postTagId });
export const modalHidePostTagAutomationWarning = () => makeModalsHide('PostTagAutomationWarningModal');

export const modalShowDashboardShareLinkDelete = (dashboardShareLinkId) => makeModalsShow('DashboardShareLinkDeleteModal', { dashboardShareLinkId });
export const modalHideDashboardShareLinkDelete = () => makeModalsHide('DashboardShareLinkDeleteModal');

export const modalShowDashboardReportDelete = (dashboardReportId) => makeModalsShow('DashboardReportDeleteModal', { dashboardReportId });
export const modalHideDashboardReportDelete = () => makeModalsHide('DashboardReportDeleteModal');

export const modalShowReportOverviewDelete = (reportOverviewIds) => makeModalsShow('ReportOverviewDeleteModal', { reportOverviewIds });
export const modalHideReportOverviewDelete = () => makeModalsHide('ReportOverviewDeleteModal');

export const modalShowDashboardShareLinks = (dashboardId) => makeModalsShow('DashboardShareLinksModal', { dashboardId });
export const modalHideDashboardShareLinks = () => makeModalsHide('DashboardShareLinksModal');

export const modalShowDashboardMetricNoteModal = (dashboardMetricId, editMode = false, readOnly = false) => makeModalsShow('DashboardMetricNoteModal', { dashboardMetricId, editMode, readOnly }, dashboardMetricId);
// export const modalHideDashboardMetricNoteModal = (dashboardMetricId) => makeModalsHide('DashboardMetricNoteModal', dashboardMetricId);

export const modalShowDataPushTaskDelete = (dataPushTaskId) => makeModalsShow('DataPushTaskDeleteModal', { dataPushTaskId });
export const modalHideDataPushTaskDelete = () => makeModalsHide('DataPushTaskDeleteModal');

export const modalShowDataPushTaskAdd = () => makeModalsShow('AddDataPushTaskModal');
export const modalHideDataPushTaskAdd = () => makeModalsHide('AddDataPushTaskModal');

export const modalShowDataPushTaskEdit = (dataPushTaskId) => makeModalsShow('EditDataPushTaskModal', { dataPushTaskId });
export const modalHideDataPushTaskEdit = () => makeModalsHide('EditDataPushTaskModal');

export const modalShowDataPushTaskDestinationAdd = () => makeModalsShow('AddDataPushTaskDestinationModal');
export const modalHideDataPushTaskDestinationAdd = () => makeModalsHide('AddDataPushTaskDestinationModal');

export const modalShowDataPushTaskDestinationEdit = (destinationId) => makeModalsShow('EditDataPushTaskDestinationModal', { destinationId });
export const modalHideDataPushTaskDestinationEdit = () => makeModalsHide('EditDataPushTaskDestinationModal');

export const modalShowGoogleBigQueryDestinationCreateTable = (dataPushTaskId, destinationId) => makeModalsShow('CreateGoogleBigQueryDestinationTableModal', { dataPushTaskId, destinationId });
export const modalHideGoogleBigQueryDestinationCreateTable = () => makeModalsHide('CreateGoogleBigQueryDestinationTableModal');

export const modalShowDataPushTaskDryRun = (dataPushTaskId) => makeModalsShow('DataPushTaskDryRunModal', { dataPushTaskId });
export const modalHideDataPushTaskDryRun = () => makeModalsHide('DataPushTaskDryRunModal');

export const modalShowDataPushTaskScheduleNow = (dataPushTaskId) => makeModalsShow('DataPushTaskScheduleNowModal', { dataPushTaskId });
export const modalHideDataPushTaskScheduleNow = () => makeModalsHide('DataPushTaskScheduleNowModal');

export const modalShowSocialNetworkLoginUseCaseSelection = (platformType, isExternalAuthentication = false) => makeModalsShow('SocialNetworkLoginUseCaseSelectionModal', { platformType, isExternalAuthentication });
export const modalAuthUserShowPagesConfirmed = () => makeModalsShow('AuthUserShowPagesConfirmedModal');

export const modalShowAdAccountAdd = () => makeModalsShowAsRoute('AdAccountAddModal');
export const modalHideAdAccountAdd = () => makeModalsHideAsRoute('AdAccountAddModal');

export const modalShowAuthAdAccountSelection = (platformType) => makeModalsShow('AuthAdAccountSelectionModal', { platformType });

export const modalAdAccountAddResult = (addedAdAccountIds, failedSearchedAdAccountLinks) => makeModalsShow('AdAccountAddResultModal', { addedAdAccountIds, failedSearchedAdAccountLinks });

export const modalShowActivateBulkAdAccountUseCases = (adAccountIds) => makeModalsShow('ActivateBulkAdAccountUseCasesModal', { adAccountIds });
export const modalHideActivateBulkAdAccountUseCases = () => makeModalsHide('ActivateBulkAdAccountUseCasesModal');

export const modalShowTwitterLogin = (onContinueAction) => makeModalsShow('TwitterLoginModal', { onContinueAction });

export const modalShowEmailChange = (connection, userId) => makeModalsShow('EmailChangeModal', { connection, userId });
export const modalHideEmailChange = () => makeModalsHide('EmailChangeModal');

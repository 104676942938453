import _isArray from 'lodash/isArray';
import _isFunction from 'lodash/isFunction';
import { modalShowUserPermissionError } from 'src/actions/overlays';
import { selectIsReadOnly } from 'src/selectors/loggedInUser';

export const ENSURE_PERMISSION = 'ENSURE_PERMISSION';

const isUserReadOnly = (state) => selectIsReadOnly(state) === true;

export const writePermissions = {
    editGroup: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot edit groups.' : undefined),
    addProfile: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot add profiles.' : undefined),
    addAdAccount: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot add Ad accounts.' : undefined),
    customizeProfile: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot customize profiles.' : undefined),
    deleteProfile: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot delete profiles.' : undefined),
    assignProfileToGroups: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot assign profiles to groups.' : undefined),
    createAuthTransaction: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot create new authentications.' : undefined),
    addUpdateAuthUsers: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot authenticate users.' : undefined),
    deleteAuthUser: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot delete an authenticated user.' : undefined),
    deactivateBrokenUseCase: (state) => (isUserReadOnly(state) ? 'You are a read only user and cannot deactivate broken use cases.' : undefined),
    updateProfileUseCases: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not add/remove use cases for a profile.' : undefined),
    deletePostTag: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not remove tags.' : undefined),
    addPostTag: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not add tag.' : undefined),
    archivePostTag: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not archive/unarchive tag.' : undefined),
    updateTagsOnPost: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not update tags on posts.' : undefined),
    deletePostTagRule: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not delete rules.' : undefined),
    changePostTagAutomationState: (state) => (isUserReadOnly(state) ? 'You are a read only user and can not change the automation state.' : undefined)
};

export const ensurePermission = (actionToPerform, permissionCallback) => (...args) => ({
    type: ENSURE_PERMISSION,
    payload: {
        actionToPerform: actionToPerform(...args),
        permissionCallback
    }
});

const ensurePermissionHandler = (store, permissionCallback, actionToPerform) => {
    const state = store.getState();

    const permissionCallbackQueue = _isFunction(permissionCallback) ? [permissionCallback] : permissionCallback;
    let error;
    permissionCallbackQueue.forEach((callback) => {
        if (!error) {
            error = callback(state, actionToPerform);
        }
    });
    if (error) {
        store.dispatch(modalShowUserPermissionError(error));
    } else {
        store.dispatch(actionToPerform);
    }
};

const userPermissionCheckMiddleware = (store) => (next) => (action) => {
    const { type, payload } = action;

    if (type === ENSURE_PERMISSION && (_isFunction(payload.permissionCallback) || _isArray(payload.permissionCallback)) && payload.actionToPerform) {
        ensurePermissionHandler(store, payload.permissionCallback, payload.actionToPerform);
    }
    return next(action);
};

export default userPermissionCheckMiddleware;

import { createFormAction } from 'redux-form-saga';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const CREATE_ONBOARDING_USER_REQUEST = 'CREATE_ONBOARDING_USER_REQUEST';
export const CREATE_ONBOARDING_USER_SUCCESS = 'CREATE_ONBOARDING_USER_SUCCESS';
export const CREATE_ONBOARDING_USER_ERROR = 'CREATE_ONBOARDING_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const TRANSFER_AND_DELETE_REQUEST = 'TRANSFER_AND_DELETE_REQUEST';
export const TRANSFER_AND_DELETE_SUCCESS = 'TRANSFER_AND_DELETE_SUCCESS';
export const TRANSFER_AND_DELETE_ERROR = 'TRANSFER_AND_DELETE_ERROR';

export const RESEND_USER_INVITATION_REQUEST = 'RESEND_USER_INVITATION_REQUEST';
export const RESEND_USER_INVITATION_SUCCESS = 'RESEND_USER_INVITATION_SUCCESS';
export const RESEND_USER_INVITATION_ERROR = 'RESEND_USER_INVITATION_ERROR';

export const createUserRequest = (formData) => ({
    type: CREATE_USER_REQUEST,
    payload: { formData }
});

export const createUserSuccess = (user) => ({
    type: CREATE_USER_SUCCESS,
    payload: { user }
});

export const createUserError = (error) => ({
    type: CREATE_USER_ERROR,
    payload: error
});

export const updateUserRequest = (formData) => ({
    type: UPDATE_USER_REQUEST,
    payload: { formData }
});

export const createUserRequestFormAction = createFormAction(
    createUserRequest,
    [
        CREATE_USER_SUCCESS,
        CREATE_USER_ERROR
    ]
);

export const updateUserRequestFormAction = createFormAction(
    updateUserRequest,
    [
        UPDATE_USER_SUCCESS,
        UPDATE_USER_ERROR
    ]
);

export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: { user }
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    payload: error
});

export const deleteUserRequest = (userId) => ({
    type: DELETE_USER_REQUEST,
    payload: { userId }
});

export const deleteUserSuccess = (userId, email) => ({
    type: DELETE_USER_SUCCESS,
    payload: { userId, email }
});

export const deleteUserError = (userId, error) => ({
    type: DELETE_USER_ERROR,
    payload: { userId, error }
});

export const transferAndDeleteUserRequest = (formData) => ({
    type: TRANSFER_AND_DELETE_REQUEST,
    payload: {
        formData
    }
});

export const transferAndDeleteUserSuccess = (deleteUserId, email) => ({
    type: TRANSFER_AND_DELETE_SUCCESS,
    payload: {
        deleteUserId,
        email
    }
});

export const transferAndDeleteUserError = (error) => ({
    type: TRANSFER_AND_DELETE_ERROR,
    payload: error
});

export const transferAndDeleteUserRequestFormAction = createFormAction(
    transferAndDeleteUserRequest,
    [TRANSFER_AND_DELETE_SUCCESS, TRANSFER_AND_DELETE_ERROR]
);

export const resendUserInvitationRequest = (userId) => ({
    type: RESEND_USER_INVITATION_REQUEST,
    payload: {
        userId
    }
});

export const resendUserInvitationSuccess = (userId, user) => ({
    type: RESEND_USER_INVITATION_SUCCESS,
    payload: {
        userId, user
    }
});

export const resendUserInvitationError = (userId, error) => ({
    type: RESEND_USER_INVITATION_ERROR,
    payload: {
        userId, error
    }
});

export const createOnboardingUserRequest = ({ email }) => ({
    type: CREATE_ONBOARDING_USER_REQUEST,
    payload: { email }
});

export const createOnboardingUserSuccess = (user) => ({
    type: CREATE_ONBOARDING_USER_SUCCESS,
    payload: { user }
});

export const createOnboardingUserError = (error) => ({
    type: CREATE_ONBOARDING_USER_ERROR,
    payload: error
});

export const createOnboardingUserRequestFormAction = createFormAction(
    createOnboardingUserRequest,
    [CREATE_ONBOARDING_USER_SUCCESS, CREATE_ONBOARDING_USER_ERROR]
);

import { createAction } from '@reduxjs/toolkit';
import { createFormAction } from 'redux-form-saga';

export const CREATE_AUTH_TRANSACTION_REQUEST = 'CREATE_AUTH_TRANSACTION_REQUEST';
export const CREATE_AUTH_TRANSACTION_SUCCESS = 'CREATE_AUTH_TRANSACTION_SUCCESS';
export const CREATE_AUTH_TRANSACTION_ERROR = 'CREATE_AUTH_TRANSACTION_ERROR';

export const CREATE_BASIC_AUTH_TRANSACTION_REQUEST = 'CREATE_BASIC_AUTH_TRANSACTION_REQUEST';
export const CREATE_BASIC_AUTH_TRANSACTION_SUCCESS = 'CREATE_BASIC_AUTH_TRANSACTION_SUCCESS';
export const CREATE_BASIC_AUTH_TRANSACTION_ERROR = 'CREATE_BASIC_AUTH_TRANSACTION_ERROR';

export const CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST = 'CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST';
export const CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS = 'CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS';
export const CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_ERROR = 'CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_ERROR';

export const CLOSE_AUTH_TRANSACTION_REQUEST = 'CLOSE_AUTH_TRANSACTION_REQUEST';
export const CLOSE_AUTH_TRANSACTION_SUCCESS = 'CLOSE_AUTH_TRANSACTION_SUCCESS';
export const CLOSE_AUTH_TRANSACTION_ERROR = 'CLOSE_AUTH_TRANSACTION_ERROR';

export const CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST = 'CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST';
export const CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS = 'CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS';
export const CLOSE_EXTERNAL_AUTH_TRANSACTION_ERROR = 'CLOSE_EXTERNAL_AUTH_TRANSACTION_ERROR';

export const CLEAN_CLOSE_AUTH_TRANSACTION_STATE = 'CLEAN_CLOSE_AUTH_TRANSACTION_STATE';

export const GET_AUTH_TRANSACTION_BY_HASH_REQUEST = 'GET_AUTH_TRANSACTION_BY_HASH_REQUEST';
export const GET_AUTH_TRANSACTION_BY_HASH_SUCCESS = 'GET_AUTH_TRANSACTION_BY_HASH_SUCCESS';
export const GET_AUTH_TRANSACTION_BY_HASH_ERROR = 'GET_AUTH_TRANSACTION_BY_HASH_ERROR';

export const UPDATE_AUTH_TRANSACTION_NAME_REQUEST = 'UPDATE_AUTH_TRANSACTION_NAME_REQUEST';
export const UPDATE_AUTH_TRANSACTION_NAME_SUCCESS = 'UPDATE_AUTH_TRANSACTION_NAME_SUCCESS';
export const UPDATE_AUTH_TRANSACTION_NAME_ERROR = 'UPDATE_AUTH_TRANSACTION_NAME_ERROR';

export const AUTH_TRANSACTION_REOPEN_REQUEST = 'AUTH_TRANSACTION_REOPEN_REQUEST';
export const AUTH_TRANSACTION_REOPEN_SUCCESS = 'AUTH_TRANSACTION_REOPEN_SUCCESS';
export const AUTH_TRANSACTION_REOPEN_ERROR = 'AUTH_TRANSACTION_REOPEN_ERROR';

export const loadAuthTransactionsRequest = createAction('LOAD_AUTH_TRANSACTIONS_REQUEST_BACKGROUND');
export const loadAuthTransactionsSuccess = createAction('LOAD_AUTH_TRANSACTIONS_SUCCESS_BACKGROUND', (authTransactions, profileAuthTransactionRequirements, adAccountAuthTransactionRequirements) => ({ payload: { authTransactions, profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } }));
export const loadAuthTransactionsError = createAction('LOAD_AUTH_TRANSACTIONS_ERROR_BACKGROUND', (error) => ({ payload: error }));

export const cleanCloseAuthTransactionState = (id) => ({
    type: CLEAN_CLOSE_AUTH_TRANSACTION_STATE,
    payload: { id }
});

export const getAuthTransactionByHashRequest = (hash) => ({
    type: GET_AUTH_TRANSACTION_BY_HASH_REQUEST,
    payload: { hash }
});

export const getAuthTransactionByHashSuccess = (hash, profiles, adAccounts, useCases, platformPermissions, authTransaction, authTransactionRequirements) => ({
    type: GET_AUTH_TRANSACTION_BY_HASH_SUCCESS,
    payload: {
        hash,
        profiles,
        adAccounts,
        useCases,
        platformPermissions,
        authTransaction,
        authTransactionRequirements
    }
});

export const getAuthTransactionByHashError = (hash, error) => ({
    type: GET_AUTH_TRANSACTION_BY_HASH_ERROR,
    payload: { hash, error }
});

export const createAuthTransactionRequest = (formValues) => ({
    type: CREATE_AUTH_TRANSACTION_REQUEST,
    payload: { formValues }
});

export const createAuthTransactionSuccess = (authTransaction, authTransactionRequirements) => ({
    type: CREATE_AUTH_TRANSACTION_SUCCESS,
    payload: { authTransaction, authTransactionRequirements }
});

export const createAuthTransactionError = (error) => ({
    type: CREATE_AUTH_TRANSACTION_ERROR,
    payload: error
});

export const createAuthTransactionRequestFormAction = createFormAction(
    createAuthTransactionRequest,
    [CREATE_AUTH_TRANSACTION_SUCCESS, CREATE_AUTH_TRANSACTION_ERROR]
);

export const createBasicAuthTransactionRequest = (basicAuthTransactionRequirements) => ({
    type: CREATE_BASIC_AUTH_TRANSACTION_REQUEST,
    payload: { basicAuthTransactionRequirements }
});

export const createBasicAuthTransactionSuccess = (authTransaction, authTransactionRequirements) => ({
    type: CREATE_BASIC_AUTH_TRANSACTION_SUCCESS,
    payload: { authTransaction, authTransactionRequirements }
});

export const createBasicAuthTransactionError = (error) => ({
    type: CREATE_BASIC_AUTH_TRANSACTION_ERROR,
    payload: error
});

export const closeAuthTransactionRequest = (id) => ({
    type: CLOSE_AUTH_TRANSACTION_REQUEST,
    payload: { id }
});

export const closeAuthTransactionSuccess = (id, authTransaction) => ({
    type: CLOSE_AUTH_TRANSACTION_SUCCESS,
    payload: { id, authTransaction }
});

export const closeAuthTransactionError = (id, error) => ({
    type: CLOSE_AUTH_TRANSACTION_ERROR,
    payload: { id, error }
});

export const closeExternalAuthTransactionRequest = (id, hash) => ({
    type: CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST,
    payload: { id, hash }
});

export const closeExternalAuthTransactionSuccess = (id, authTransaction) => ({
    type: CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS,
    payload: { id, authTransaction }
});

export const closeExternalAuthTransactionError = (id, error) => ({
    type: CLOSE_EXTERNAL_AUTH_TRANSACTION_ERROR,
    payload: { id, error }
});

export const updateAuthTransactionNameRequest = (authTransactionId, name) => ({
    type: UPDATE_AUTH_TRANSACTION_NAME_REQUEST,
    payload: {
        authTransactionId, name
    }
});

export const updateAuthTransactionNameSuccess = (authTransactionId, name) => ({
    type: UPDATE_AUTH_TRANSACTION_NAME_SUCCESS,
    payload: { authTransactionId, name }
});

export const updateAuthTransactionNameError = (authTransactionId, error) => ({
    type: UPDATE_AUTH_TRANSACTION_NAME_ERROR,
    payload: { authTransactionId, error }
});

export const authTransactionReopenRequest = (id) => ({
    type: AUTH_TRANSACTION_REOPEN_REQUEST,
    payload: { id }
});

export const authTransactionReopenSuccess = (id, authTransaction) => ({
    type: AUTH_TRANSACTION_REOPEN_SUCCESS,
    payload: { id, authTransaction }
});

export const authTransactionReopenError = (id, error) => ({
    type: AUTH_TRANSACTION_REOPEN_ERROR,
    payload: { id, error }
});

export const createOnboardingBasicAuthTransactionRequest = (basicAuthTransactionRequirements) => ({
    type: CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST,
    payload: { basicAuthTransactionRequirements }
});

export const createOnboardingBasicAuthTransactionSuccess = (authTransaction, authTransactionRequirements) => ({
    type: CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS,
    payload: { authTransaction, authTransactionRequirements }
});

export const createOnboardingBasicAuthTransactionError = (error) => ({
    type: CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_ERROR,
    payload: error
});

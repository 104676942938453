import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectAuthAdAccountById } from 'src/selectors/authAdAccount';
import { makeSelectAuthUsersAuthAdAccountUseCasesByAuthAdAccountId } from 'src/selectors/authUserAuthAdAccountUseCases';
import AuthUserAuthAccountUseCaseRow from 'src/components/views/public/AuthUserAuthAccountUseCaseRow';

const AuthUserAuthAdAccountUseCaseRow = (props) => {
    const {
        authAdAccount, authUserAuthAdAccountUseCases, platformUseCases
    } = props;
    return (
        <AuthUserAuthAccountUseCaseRow
          authAccount={authAdAccount}
          platformUseCases={platformUseCases}
          authUserAuthAccountUseCases={authUserAuthAdAccountUseCases}
          accountType="adAccounts"
        />
    );
};

AuthUserAuthAdAccountUseCaseRow.propTypes = {
    authAdAccountId: PropTypes.string.isRequired,
    authAdAccount: customPropTypes.authAdAccount.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    authUserAuthAdAccountUseCases: customPropTypes.authUserAuthAdAccountUseCases.isRequired
};

const makeMapStateToProps = () => {
    const selectAuthAdAccountById = makeSelectAuthAdAccountById();
    const selectAuthUsersAuthAdAccountUseCasesByAuthAdAccountId = makeSelectAuthUsersAuthAdAccountUseCasesByAuthAdAccountId();
    return (state, ownProps) => ({
        authAdAccount: selectAuthAdAccountById(state, ownProps.authAdAccountId),
        authUserAuthAdAccountUseCases: selectAuthUsersAuthAdAccountUseCasesByAuthAdAccountId(state, ownProps.authAdAccountId)
    });
};

export default connect(makeMapStateToProps)(AuthUserAuthAdAccountUseCaseRow);

import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import { selectUseCasesByPlatformWithBasicInsights } from 'src/selectors/useCases';
import AuthUserAuthProfileUseCaseRow from 'src/components/views/public/authProfileAuthUserConnectionState/AuthUserAuthProfileUseCaseRow';
import ListTable from 'src/components/listTable/ListTable';

const AuthUserAuthProfileUseCasesState = (props) => {
    const {
        authProfileIds,
        platformUseCases,
        platformType
    } = props;

    return (
        <ListTable header={(
            <PlatformUseCasesTableHeader
              platformUseCases={platformUseCases}
              showUseCaseDetail
              platformType={platformType}
            />
        )}
        >
            {
                authProfileIds.map((authProfileId) => (
                    <AuthUserAuthProfileUseCaseRow
                      key={authProfileId}
                      authProfileId={authProfileId}
                      platformUseCases={platformUseCases}
                    />
                ))
            }
        </ListTable>
    );
};

AuthUserAuthProfileUseCasesState.propTypes = {
    platformUseCases: customPropTypes.useCases.isRequired,
    authProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    platformType: customPropTypes.platformTypes.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatformWithBasicInsights(state, ownProps.platformType, false),
});

export default connect(mapStateToProps)(AuthUserAuthProfileUseCasesState);

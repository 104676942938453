import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/public/authUserConnectionState.scss';
import {
    selectExternalNetworkAuthenticationConnectState,
    selectCreatedByUserName,
    getChosenAuthAdAccountIdsByPlatform
} from 'src/selectors/externalNetworkAuthentication';
import classnames from 'classnames';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import AuthUserGrantedPermissionConfirmationInfo from 'src/components/views/public/AuthUserGrantedPermissionConfirmationInfo';
import AuthAccountEmptyState from 'src/components/views/public/AuthAccountEmptyState';
import AuthUserAuthAdAccountUseCasesState from 'src/components/views/public/authAdAccountAuthUserConnectionState/AuthUserAuthAdAccountUseCasesState';

const AuthAdAccountAuthUserConnectionState = (props) => {
    const { authAdAccountIdsByPlatformType, connectState } = props;
    const { meta } = authAdAccountIdsByPlatformType;
    const { error, isPending } = connectState;
    const hasAnyAuthUserAuthenticated = meta.length > 0;

    return (
        <div className={classnames(styles.wrapper, { [styles.loading]: isPending })}>
            <div className={styles.error}>
                {
                    error && <Feedback content={error.message} type="error" />
                }
            </div>
            {
                hasAnyAuthUserAuthenticated && <div className={styles.border} />
            }
            {
                meta.length > 0 && (
                    <div className={styles.bottomBorder}>
                        <AuthUserAuthAdAccountUseCasesState authAdAccountIds={meta} platformType="meta" />
                    </div>
                )
            }
            {
                meta.length === 0 && (
                    <AuthAccountEmptyState />
                )
            }
            {
                hasAnyAuthUserAuthenticated && (
                    <div className={styles.authenticatedInfo}>
                        <AuthUserGrantedPermissionConfirmationInfo accountType="adAccounts" />
                    </div>
                )
            }
        </div>
    );
};

AuthAdAccountAuthUserConnectionState.propTypes = {
    authAdAccountIdsByPlatformType: customPropTypes.authAdAccountByPlatformType.isRequired,
    connectState: customPropTypes.asyncDataLoadingState.isRequired,
    createdByUserName: PropTypes.string.isRequired
};

const makeMapStateToProps = () => (state) => ({
    authAdAccountIdsByPlatformType: getChosenAuthAdAccountIdsByPlatform(state),
    connectState: selectExternalNetworkAuthenticationConnectState(state),
    createdByUserName: selectCreatedByUserName(state)
});

export default connect(makeMapStateToProps)(AuthAdAccountAuthUserConnectionState);

import { createAction } from '@reduxjs/toolkit';
import { createFormAction } from 'redux-form-saga';

export const adAccountDeleteRequest = createAction('AD_ACCOUNT_DELETE_REQUEST', (adAccountIds) => ({ payload: { adAccountIds } }));
export const adAccountDeleteSuccess = createAction('AD_ACCOUNT_DELETE_SUCCESS', (adAccountIds) => ({ payload: { adAccountIds } }));
export const addAccountDeleteError = createAction('AD_ACCOUNT_DELETE_ERROR', (error) => ({ payload: error }));

export const adAccountAddRequest = createAction('AD_ACCOUNT_ADD_REQUEST_BACKGROUND', (adAccountId) => ({ payload: { adAccountId } }));
export const adAccountAddSuccess = createAction('AD_ACCOUNT_ADD_SUCCESS_BACKGROUND', (adAccountId) => ({ payload: { adAccountId } }));
export const adAccountAddError = createAction('AD_ACCOUNT_ADD_ERROR_BACKGROUND', (adAccountId, error) => ({ payload: { adAccountId, error } }));
export const adAccountAddCleanup = createAction('AD_ACCOUNT_ADD_CLEANUP_BACKGROUND', (adAccountId) => ({ payload: { adAccountId } }));

export const adAccountBulkAddRequest = createAction('AD_ACCOUNT_BULK_ADD_REQUEST', (checkedAdAccountIds) => ({ payload: { checkedAdAccountIds } }));
export const adAccountBulkAddSuccess = createAction('AD_ACCOUNT_BULK_ADD_SUCCESS', (adAccounts) => ({ payload: { adAccounts } }));
export const adAccountBulkAddError = createAction('AD_ACCOUNT_BULK_ADD_ERROR', (error) => ({ payload: error }));

export const adAccountDeleteFormAction = createFormAction(
    (values) => adAccountDeleteRequest(values.accountIds),
    [adAccountDeleteSuccess.type, addAccountDeleteError.type]
);

export const adAccountForceDeleteRequest = createAction('AD_ACCOUNT_FORCE_DELETE_REQUEST', (adAccountIds) => ({ payload: { adAccountIds } }));
export const adAccountForceDeleteSuccess = createAction('AD_ACCOUNT_FORCE_DELETE_SUCCESS', (adAccountIds) => ({ payload: { adAccountIds } }));
export const adAccountForceDeleteError = createAction('AD_ACCOUNT_FORCE_DELETE_ERROR', (error) => ({ payload: error }));

export const adAccountForceDeleteFormAction = createFormAction(
    (values) => adAccountForceDeleteRequest(values.ids),
    [adAccountForceDeleteSuccess.type, adAccountForceDeleteError.type]
);

export const externalNetworkAdAccountAuthenticationLinkGetRequest = createAction('EXTERNAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_LINK_GET_REQUEST');
export const externalNetworkAdAccountAuthenticationLinkGetSuccess = createAction('EXTERNAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_LINK_GET_SUCCESS');
export const externalNetworkAdAccountAuthenticationLinkGetError = createAction('EXTERNAL_NETWORK_AD_ACCOUNT_AUTHENTICATION_LINK_GET_ERROR');

export const adAccountAddToOrRemoveFromGroupRequest = createAction('AD_ACCOUNT_ADD_TO_OR_REMOVE_FROM_GROUP_REQUEST', (adAccountIds, selectedGroupOptions) => ({ payload: { adAccountIds, selectedGroupOptions } }));
export const adAccountAddToOrRemoveFromGroupSuccess = createAction('AD_ACCOUNT_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS', (adAccountAddOrRemoveResults) => ({ payload: { adAccountAddOrRemoveResults } }));
export const adAccountAddToOrRemoveFromGroupError = createAction('AD_ACCOUNT_ADD_TO_OR_REMOVE_FROM_GROUP_ERROR', (error) => ({ error }));

export const adAccountCustomizeRequest = createAction('AD_ACCOUNT_CUSTOMIZE_REQUEST', ({
    accountId,
    appendix,
    favorite,
    color
}) => ({
    payload: {
        adAccountId: accountId, appendix, favorite, color
    }
}));

export const adAccountCustomizeSuccess = createAction('AD_ACCOUNT_CUSTOMIZE_SUCCESS', (adAccount) => ({
    payload: {
        adAccount
    }
}));

export const adAccountCustomizeError = createAction('AD_ACCOUNT_CUSTOMIZE_ERROR', (error) => ({ payload: error }));

export const adAccountCustomizeFormAction = createFormAction(
    adAccountCustomizeRequest,
    [adAccountCustomizeSuccess.type, adAccountCustomizeError.type]
);

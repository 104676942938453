import _toInteger from 'lodash/toInteger';

export const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
};

export const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
};

export const getLines = (value) => value.split('\n').map((item) => item.trim());

export const getNewLinesWithoutEmptyStrings = (value) => {
    const lines = getLines(value.trim());
    const cleanedLines = [];
    lines.forEach((line) => {
        if (line !== '') {
            cleanedLines.push(line);
        }
    });
    return cleanedLines;
};

export const stringArrayToIntArray = (array) => array.map((element) => (_toInteger(element)));
export const integerArrayToStringArray = (array) => array.map((element) => `${element}`);

export const getFoundedEntities = (ids, entitiesById) => {
    const foundEntities = [];
    ids.forEach((id) => {
        if (entitiesById[id] && entitiesById[id] !== null && entitiesById[id] !== false) {
            foundEntities.push(entitiesById[id]);
        }
    });
    return foundEntities;
};

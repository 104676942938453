import { createSelector } from 'reselect';

export const getAuthUsersAuthProfileUseCases = (state) => state.entities.authUsersAuthProfileUseCases;

export const makeSelectAuthUsersAuthProfileUseCasesByProfileId = () => createSelector(
    [
        getAuthUsersAuthProfileUseCases,
        (_, profileId) => profileId
    ],
    (allProfilesUseCasesAuthUsers, profileId) => allProfilesUseCasesAuthUsers.filter((profilesUseCasesAuthUser) => profilesUseCasesAuthUser.profileId === profileId)
);

export const makeSelectAuthUsersAuthProfileUseCasesByAuthProfileId = () => createSelector(
    [
        getAuthUsersAuthProfileUseCases,
        (_, authProfileId) => authProfileId
    ],
    (allProfilesUseCasesAuthUsers, authProfileId) => allProfilesUseCasesAuthUsers.filter((profilesUseCasesAuthUser) => profilesUseCasesAuthUser.authProfileId === authProfileId)
);

export const makeSelectAuthUserAuthProfileUseCasesByAuthUserId = () => createSelector(
    [
        getAuthUsersAuthProfileUseCases,
        (_, authUserId) => authUserId
    ],
    (authUsersAuthProfileUseCases, authUserId) => authUsersAuthProfileUseCases.filter((authUsersAuthProfileUseCase) => authUsersAuthProfileUseCase.userId === authUserId)
);

export const makeSelectAuthUserAuthProfileUseCasesByAuthUserIdAndProfileId = () => createSelector(
    [
        getAuthUsersAuthProfileUseCases,
        (_, authUserId) => authUserId,
        (_, __, profileId) => profileId
    ], (authUserAuthProfileUseCases, authUserId, profileId) => authUserAuthProfileUseCases.filter((authUserAuthProfileUseCase) => authUserAuthProfileUseCase.userId === authUserId && authUserAuthProfileUseCase.profileId === profileId)
);

import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Feedback from 'src/components/feedback/Feedback';
import Footer from 'src/components/Footer';
import loadingIndicator from 'src/resources/loading-indicators/loading-pie-chart.gif';
import PropTypes from 'prop-types';
import PublicHeader from 'src/components/header/PublicHeader';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/public/externalAuthentication.scss';
import { selectExternalNetworkAuthenticationBootstrappingState } from 'src/selectors/externalNetworkAuthentication';
import { connect } from 'react-redux';

const ExternalSocialNetworkAccountAuthenticationView = (props) => {
    const { hash, children } = props;
    const { bootstrappingState } = props;
    let content = <div>initial pages</div>;
    if (!hash) {
        content = <Feedback content="No hash key found." type="error" />;
    }
    if (bootstrappingState) {
        const { isPending, success, error } = bootstrappingState;
        if (isPending) {
            content = <div className={styles.states}><img alt="Validating link ..." src={loadingIndicator} /></div>;
        }

        if (!isPending && error) {
            content = <Feedback content={error.message} type="error" />;
        }

        if (!isPending && !error && success) {
            content = children;
        }
    }

    return (
        <ScrollView dataViewName="external-authentication">
            <PublicHeader />
            <div className={styles.wrapper}>
                {content}
            </div>
            <Footer />
        </ScrollView>
    );
};

ExternalSocialNetworkAccountAuthenticationView.propTypes = {
    children: PropTypes.node.isRequired,
    hash: PropTypes.string.isRequired,
    bootstrappingState: customPropTypes.asyncReducerState.isRequired
};

const mapStateToProps = (state) => ({
    bootstrappingState: selectExternalNetworkAuthenticationBootstrappingState(state)
});

export default connect(mapStateToProps)(ExternalSocialNetworkAccountAuthenticationView);

import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import _intersection from 'lodash/intersection';
import _uniq from 'lodash/uniq';
import { delay, getContext, select } from 'redux-saga/effects';
import { googleScopeUrl, snapScopUrl } from 'src/config';
import { selectScopesByPlatformPermissionsIds } from 'src/selectors/platformPermissions';
import { makeSelectUseCasesByIds } from 'src/selectors/useCases';
import { getUniquePlatformPermissionIds } from 'src/utils/useCases';

const selectUseCasesByIds = makeSelectUseCasesByIds();

const exclusiveDataSources = [
    'twitterMentions',
    'twitterKeyInteractingUsers',
    'youtubeAnalyticsVideos',
    'instagramInsights'
];

const longDataSources = [
    'facebookOwnPosts',
    'facebookUserPosts',
    'facebookComments',
    'facebookQuestions',
    'twitterOwnTweets',
    'twitterOwnReplies',
    'twitterQuestions',
    'youtubeVideos',
    'linkedInUpdates',
    'instagramOwnPosts',
    'instagramInsightsOwnPosts',
    'instagramInsightsStories',
    'facebookInsightsOwnPosts'
];

export function* handleAuthorizedServerRequest(serverRequest, to) {
    const authenticator = yield getContext('authenticator');
    return yield authenticator.handleAuthorizedServerRequest(serverRequest, to).then((response) => ({ response })).catch((serverError) => ({ serverError }));
}

export function* handleLogout() {
    const authenticator = yield getContext('authenticator');
    return yield authenticator.logout().then(() => ({ success: true })).catch(() => ({ success: false }));
}

export const handleExport = (parseBlobToBase64, blob) => parseBlobToBase64(blob).then((base64Blob) => ({ base64Blob })).catch((conversionError) => ({ conversionError }));

export const handleObjectUrlFileRequest = (objectUrlFileRequest, url) => objectUrlFileRequest(url).then((blob) => ({ blob })).catch((error) => ({ error }));

export const createCallbackFunction = (callbacks, callbackName) => {
    if (callbacks.context && callbacks.context.mounted === true && typeof callbacks[callbackName] === 'function') {
        return callbacks[callbackName];
    }
    return () => {};
};

const appendJob = (requests, requestBucket, widgetParams) => {
    if (typeof requests[requestBucket] === 'undefined') {
        Object.assign(requests, { [requestBucket]: [] });
    }
    Object.assign(requests, { [requestBucket]: requests[requestBucket].concat(widgetParams) });
};

export const getWidgetsSplitIntoRequests = (widgetsByClassification) => {
    const requests = [];
    let requestBucket = 0;

    Object.keys(widgetsByClassification).forEach((classification) => {
        const widgetByClassification = widgetsByClassification[classification];
        if (classification === 'exclusive') {
            for (let i = 0; i < widgetByClassification.length; i += 1) {
                appendJob(requests, requestBucket, widgetByClassification[i].jobs);
                requestBucket += 1;
            }
        } else {
            const widgetsByHash = widgetByClassification;
            let lowerBoundary = widgetsByHash.length - 1;
            for (let i = 0; i < widgetsByHash.length; i += 1) {
                const currentElement = widgetsByHash[i];
                if (i < lowerBoundary) {
                    if (currentElement.jobCount >= 10) {
                        appendJob(requests, requestBucket, currentElement.jobs);
                        requestBucket += 1;
                    } else {
                        appendJob(requests, requestBucket, currentElement.jobs);
                        let bucketCount = currentElement.jobCount;
                        for (let j = lowerBoundary; j > i; j -= 1) {
                            const elementToCheckIfItFitsIntoBucket = widgetsByHash[j];
                            if (elementToCheckIfItFitsIntoBucket.jobCount + bucketCount <= 10) {
                                appendJob(requests, requestBucket, elementToCheckIfItFitsIntoBucket.jobs);
                                bucketCount += elementToCheckIfItFitsIntoBucket.jobCount;
                                lowerBoundary -= 1;
                            } else {
                                break;
                            }
                        }
                        requestBucket += 1;
                    }
                } else if (i === lowerBoundary) {
                    // this is the case where only one item is left and needs to be added to the bucket without checking for additional buckets
                    appendJob(requests, requestBucket, currentElement.jobs);
                    requestBucket += 1;
                } else {
                    break;
                }
            }
        }
    });
    return requests;
};

export const getWidgetsGroupedByDataSourceUsage = (jobs) => {
    const jobsByHash = [];
    const jobsByClassification = {};
    jobs.forEach((job) => {
        const tableNamesFound = [];
        const metricClassification = {
            exclusive: false,
            long: false
        };
        const { dataSources } = job;

        // extract all data sources that are used within the qql query
        dataSources.forEach((dataSourceTableName) => {
            if (dataSourceTableName !== 'profiles') {
                tableNamesFound.push(dataSourceTableName);

                // Try to classify the metric by the longest running dataSource
                if (!metricClassification.exclusive) {
                    if (exclusiveDataSources.indexOf(dataSourceTableName) !== -1) {
                        metricClassification.exclusive = true;
                    }
                }
                if (!metricClassification.long) {
                    if (longDataSources.indexOf(dataSourceTableName) !== -1) {
                        metricClassification.long = true;
                    }
                }
            }
        });
        const hash = tableNamesFound.sort().join(',');

        let classification = 'short';
        if (metricClassification.exclusive === true) {
            classification = 'exclusive';
        } else if (metricClassification.long === true) {
            classification = 'long';
        }

        if (!_has(jobsByHash, hash)) {
            jobsByHash[hash] = {
                hash,
                jobs: [],
                jobCount: 0,
                classification
            };
        }

        jobsByHash[hash].jobs.push(job);
        jobsByHash[hash].jobCount += 1;
    });

    // split hashes by classification

    Object.keys(jobsByHash).forEach((hash) => {
        if (!_has(jobsByClassification, jobsByHash[hash].classification)) {
            jobsByClassification[jobsByHash[hash].classification] = [];
        }
        jobsByClassification[jobsByHash[hash].classification].push(jobsByHash[hash]);
    });

    // sort the classified hashes by their number of jobs descending

    Object.keys(jobsByClassification).forEach((classifcation) => {
        jobsByClassification[classifcation].sort((objectA, objectB) => objectB.jobCount - objectA.jobCount);
    });
    return jobsByClassification;
};

export const getBaseUrl = () => {
    const { hostname, protocol } = window.location;
    return `${protocol}//${hostname}`;
};

export const mapTikTokCallbackUrlAlias = (baseUrl) => {
    if (baseUrl.indexOf('app.quintly.lo') !== -1) {
        return 'localq';
    }

    if (baseUrl.indexOf('staging-raji-app') !== -1) {
        return 'raji';
    }

    if (baseUrl.indexOf('staging-hasib-app') !== -1) {
        return 'hasib';
    }

    if (baseUrl.indexOf('staging-hendrik-app') !== -1) {
        return 'hendrik';
    }
    return 'q';
};

export const loadScript = (src) => new Promise((resolve, reject) => {
    const js = document.createElement('script');
    js.src = src;
    js.onload = resolve;
    js.onerror = reject;
    document.head.appendChild(js);
});

export const handleApiRequests = (serverRequest) => serverRequest().then((response) => ({ response })).catch((serverError) => ({ serverError }));

export const createFacebookLoginRequest = (options) => () => new Promise((resolve, reject) => {
    window.FB.login((response) => {
        if (response.authResponse) {
            resolve(response);
        } else {
            reject(response);
        }
    }, { scope: options, auth_type: 'reauthorize' });
});

export const notAbleToLoadSDKmessage = 'We were not able to load the Facebook JavaScript SDK. You may be using a browser plugin like ad blockers which block these libraries. To allow these libraries to load we strongly suggest you disable any ad blockers for our website.';

export const parseReportDateSelection = (dateSelection) => {
    const newDateSelection = Object.assign({}, dateSelection);

    if (!_has(newDateSelection, 'from')) {
        newDateSelection.from = null;
    }
    if (!_has(newDateSelection, 'to')) {
        newDateSelection.to = null;
    }
    if (!_has(newDateSelection, 'dynamicDate')) {
        newDateSelection.dynamicDate = null;
    }
    return newDateSelection;
};

export function parseProfileSelection(profileSelection) {
    let profileIds = null;
    let groupId = null;

    const profileParts = profileSelection.split('-');
    if (profileParts[0] === 'group') {
        ({ 1: groupId } = profileParts);
    } else if (profileParts[0] === 'profile') {
        profileIds = [profileParts[1]];
    }

    return { groupId, profileIds };
}

// courtesy of: https://davidwalsh.name/query-string-javascript
function getUrlParameter(query, name) {
    const nameTemp = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${nameTemp}=([^&#]*)`);
    const results = regex.exec(query);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const urlSearchParams = (query, name) => {
    if (_has(window, 'URLSearchParams')) {
        const urlParams = new window.URLSearchParams(query);
        return urlParams.get(name);
    }
    return getUrlParameter(query, name);
};

export const getMetriIdsFromDashboardMetrics = (dashboardMetrics) => {
    const metricIds = [];
    Object.keys(dashboardMetrics).forEach((id) => {
        const dashboardMetric = dashboardMetrics[id];
        const { metricId } = dashboardMetric.entity;
        metricIds.push(metricId);
    });
    return _uniq(metricIds);
};

export const getUniqueUseCaseIdsWithAuthTransactionReqIds = (authTransactionRequirementsPerProfile) => {
    const useCaseIds = [];
    const authTransactionRequirementIds = [];
    Object.keys(authTransactionRequirementsPerProfile).forEach((profileId) => {
        const authTransactionRequirements = authTransactionRequirementsPerProfile[profileId];
        authTransactionRequirements.forEach((authTransactionRequirement) => {
            const { id, useCaseId } = authTransactionRequirement;
            if (!_includes(useCaseIds, useCaseId)) {
                useCaseIds.push((useCaseId));
            }
            authTransactionRequirementIds.push(parseInt(id, 10));
        });
    });
    return {
        useCaseIds,
        authTransactionRequirementIds
    };
};

export const getChangedOptions = (selectedOptionIds, initialSelectedOptionIds, initalIndeterminateOptionIds, touched) => {
    const addedIds = _difference(selectedOptionIds, initialSelectedOptionIds);
    const removedIds = _difference(initialSelectedOptionIds, selectedOptionIds);
    // detect cases that were indeterminated to start, touched, and have no value now
    const changedIndeterminated = _intersection(touched, initalIndeterminateOptionIds);
    const indeterminatedToDelete = _difference(changedIndeterminated, selectedOptionIds);
    const removedIdsWithIntedeterminated = [...removedIds, ...indeterminatedToDelete];

    return {
        addedIds,
        removedIds: removedIdsWithIntedeterminated
    };
};

export const getAddedAndRemovedIdsFromSelectedOptions = (initialSelectedIds, initialIndeterminateIds, selectedOptions) => {
    const selectedIds = [];
    const addedIndeterminateIds = [];

    const allSelectedOptionIds = [];
    selectedOptions.forEach(({ option, isIndeterminate }) => {
        allSelectedOptionIds.push(option.id);
        if (_includes(initialIndeterminateIds, option.id) && !isIndeterminate) {
            addedIndeterminateIds.push(option.id);
        }
        if (!isIndeterminate) {
            selectedIds.push(option.id);
        }
    });

    const removedIndeterminateIds = _difference(initialIndeterminateIds, allSelectedOptionIds);
    const touchedIndeterminateIds = [...removedIndeterminateIds, ...addedIndeterminateIds];

    return getChangedOptions(selectedIds, initialSelectedIds, initialIndeterminateIds, touchedIndeterminateIds);
};

export function* getFileBlob(objectUrlFileRequest, url) {
    if (url.indexOf('blob:') !== -1) {
        const { blob, error } = yield handleObjectUrlFileRequest(objectUrlFileRequest, url);
        if (blob) {
            return blob;
        }
        if (error) {
            throw new Error('Logo file could not be processed.');
        }
    }
    return null;
}

export const getMetricRequest = (request) => ({
    id: request.identifier,
    // deprecated, remove parameters layers
    parameters: request.params,
    filter: request.filter,
    dependency: request.dependency
});

export const getDashboardMetricIdOrNullFromMetricRequest = (request) => _get(request, 'dependency.dashboardMetricId', null);

export const getScopesStringByEnvironment = (scopes, environment) => {
    if (environment === 'graph') {
        return scopes.join(',');
    }
    if (environment === 'linkedIn') {
        return scopes.join(' ');
    }

    if (environment === 'youtube') {
        return scopes.map((scope) => `${googleScopeUrl}${scope}`).join(' ');
    }

    if (environment === 'snapchat') {
        return scopes.map((scope) => `${snapScopUrl}${scope}`).join(' ');
    }

    if (environment === 'tiktok') {
        return scopes.join(',');
    }

    if (environment === 'threads') {
        return scopes.join(',');
    }

    return null;
};

export function* getScopesByUseCases(useCaseIds, platformType) {
    const useCases = yield select(selectUseCasesByIds, useCaseIds);
    const platformPermissionIds = getUniquePlatformPermissionIds(useCases);
    const scopes = yield select(selectScopesByPlatformPermissionsIds, platformPermissionIds);
    return getScopesStringByEnvironment(scopes, platformType);
}

const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
};

export function* popUpWindowWithCallbackUrlAction(url, network) {
    const left = (window.innerWidth / 2) - (700 / 2);
    const top = (window.innerHeight / 2) - (600 / 2);
    const popUp = window.open(
        url,
        `Login to ${network}`,
        `status=1, height=600, width=700, top=${top}, left=${left}, resizable=0`
    );

    if (!popUp) {
        return {
            response: null,
            serverError: {
                errorType: 'popupBlocked',
                message: 'It looks like your browser blocks pop-ups. Please allow pop-ups to continue logging in.'
            }
        };
    }

    let status = {
        response: null,
        serverError: {
            errorType: 'AccessDenied',
            message: `Login to ${network} was unsuccessful. Please try again.`
        }
    };

    window.onmessage = function onmessage(e) {
        if (isJSON(e.data)) {
            const data = JSON.parse(e.data);
            if (data.success) {
                status = {
                    response: data.response
                };
            }
            if (!data.success) {
                status = {
                    serverError: {
                        errorType: data.errorType || 'unknownError',
                        message: data.message || 'Unknown error. Please try again or contact our support at support@facelift-bbt.com'
                    }
                };
            }
        }
    };

    while (!popUp.closed) {
        yield delay(1000);
    }
    window.onmessage = null;
    return status;
}

export const gatherBatchedResults = (collectedResponses) => {
    const accountIdsWithErrors = [];
    const successfullyOperations = [];
    collectedResponses.forEach((responseContainer) => {
        const {
            response, serverError, profileId, adAccountId
        } = responseContainer;
        const accountId = adAccountId || profileId;
        if (response) {
            const { jsonData } = response;
            const {
                successAdded, successRemoved, errorAdded, errorRemoved
            } = jsonData.status;

            if (successAdded.length > 0 || successRemoved.length > 0) {
                successfullyOperations.push({ accountId, successAdded, successRemoved });
            }

            if (errorAdded.length > 0 || errorRemoved.length > 0) {
                accountIdsWithErrors.push(accountId);
            }
        }
        if (serverError) {
            accountIdsWithErrors.push(accountId);
        }
    });
    return { errors: accountIdsWithErrors, success: successfullyOperations };
};

import * as customPropTypes from 'src/customPropTypes';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const Login = ({ location }) => {
    const {
        loginWithRedirect
    } = useAuth0();

    useEffect(() => {
        window.location.href = loginWithRedirect({
            prompt: 'login', login_hint: location.query.email, organization: location.query.organization, invitation: location.query.invitation
        });
    }, []);

    return null;
};

Login.propTypes = {
    location: customPropTypes.location.isRequired
};

export default Login;

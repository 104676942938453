import { createFormAction } from 'redux-form-saga';

export const POST_TAG_ADD_REQUEST = 'POST_TAG_ADD_REQUEST';
export const POST_TAG_ADD_SUCCESS = 'POST_TAG_ADD_SUCCESS';
export const POST_TAG_ADD_ERROR = 'POST_TAG_ADD_ERROR';

export const POST_TAG_RENAME_REQUEST = 'POST_TAG_RENAME_REQUEST';
export const POST_TAG_RENAME_SUCCESS = 'POST_TAG_RENAME_SUCCESS';
export const POST_TAG_RENAME_ERROR = 'POST_TAG_RENAME_ERROR';

export const POST_TAG_EDIT_REQUEST = 'POST_TAG_EDIT_REQUEST';
export const POST_TAG_EDIT_SUCCESS = 'POST_TAG_EDIT_SUCCESS';
export const POST_TAG_EDIT_ERROR = 'POST_TAG_EDIT_ERROR';

export const POST_TAGS_REMOVE_REQUEST = 'POST_TAGS_REMOVE_REQUEST';
export const POST_TAGS_REMOVE_SUCCESS = 'POST_TAGS_REMOVE_SUCCESS';
export const POST_TAGS_REMOVE_ERROR = 'POST_TAGS_REMOVE_ERROR';

export const POST_TAG_FORCE_REMOVE_REQUEST = 'POST_TAG_FORCE_REMOVE_REQUEST';
export const POST_TAG_FORCE_REMOVE_SUCCESS = 'POST_TAG_FORCE_REMOVE_SUCCESS';
export const POST_TAG_FORCE_REMOVE_ERROR = 'POST_TAG_FORCE_REMOVE_ERROR';

export const POST_TAGS_ARCHIVE_REQUEST = 'POST_TAGS_ARCHIVE_REQUEST';
export const POST_TAGS_ARCHIVE_SUCCESS = 'POST_TAGS_ARCHIVE_SUCCESS';
export const POST_TAGS_ARCHIVE_ERROR = 'POST_TAGS_ARCHIVE_ERROR';

export const POST_TAGS_FORCE_ARCHIVE_REQUEST = 'POST_TAGS_FORCE_ARCHIVE_REQUEST';
export const POST_TAGS_FORCE_ARCHIVE_SUCCESS = 'POST_TAGS_FORCE_ARCHIVE_SUCCESS';
export const POST_TAGS_FORCE_ARCHIVE_ERROR = 'POST_TAGS_FORCE_ARCHIVE_ERROR';

export const POST_TAGS_UNARCHIVE_REQUEST = 'POST_TAGS_UNARCHIVE_REQUEST';
export const POST_TAGS_UNARCHIVE_SUCCESS = 'POST_TAGS_UNARCHIVE_SUCCESS';
export const POST_TAGS_UNARCHIVE_ERROR = 'POST_TAGS_UNARCHIVE_ERROR';

export const POST_TAG_AUTOMATION_ACTIVATE_REQUEST = 'POST_TAG_AUTOMATION_ACTIVATE_REQUEST';
export const POST_TAG_AUTOMATION_ACTIVATE_SUCCESS = 'POST_TAG_AUTOMATION_ACTIVATE_SUCCESS';
export const POST_TAG_AUTOMATION_ACTIVATE_ERROR = 'POST_TAG_AUTOMATION_ACTIVATE_ERROR';

export const POST_TAG_AUTOMATION_DEACTIVATE_REQUEST = 'POST_TAG_AUTOMATION_DEACTIVATE_REQUEST';
export const POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS = 'POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS';
export const POST_TAG_AUTOMATION_DEACTIVATE_ERROR = 'POST_TAG_AUTOMATION_DEACTIVATE_ERROR';

export const SORT_POST_TAGS = 'SORT_POST_TAGS';
export const TOGGLE_ARCHIVED_POST_TAGS_FILTER = 'TOGGLE_ARCHIVED_POST_TAGS_FILTER';
export const POST_TAG_RULE_IDS_CLEAN_UP = 'POST_TAG_RULE_IDS_CLEAN_UP';
export const DEACTIVATE_AUTOMATION_IF_LAST_POST_TAG_RULE_REMOVED = 'DEACTIVATE_AUTOMATION_IF_LAST_POST_TAG_RULE_REMOVED';

export const POST_SEARCH_BY_TAG_REQUEST = 'POST_SEARCH_BY_TAG_REQUEST';
export const POST_SEARCH_BY_TAG_SUCCESS = 'POST_SEARCH_BY_TAG_SUCCESS';
export const POST_SEARCH_BY_TAG_ERROR = 'POST_SEARCH_BY_TAG_ERROR';

export const postTagAddRequest = ({ name }) => ({
    type: POST_TAG_ADD_REQUEST,
    payload: { name }
});

export const postTagAddSuccess = (postTag) => ({
    type: POST_TAG_ADD_SUCCESS,
    payload: { postTag }
});

export const postTagAddError = (error) => ({
    type: POST_TAG_ADD_ERROR,
    payload: error
});

export const postTagAddRequestFormAction = createFormAction(postTagAddRequest, [POST_TAG_ADD_SUCCESS, POST_TAG_ADD_ERROR]);

export const postTagEditRequest = ({
    id, name, archived, automation, automationInitial
}) => ({
    type: POST_TAG_EDIT_REQUEST,
    payload: {
        id, name, archived, automation, automationInitial
    }
});

export const postTagEditSuccess = (postTag = null) => ({
    type: POST_TAG_EDIT_SUCCESS,
    payload: { postTag }
});

export const postTagEditError = (error) => ({
    type: POST_TAG_EDIT_ERROR,
    payload: error
});

export const postTagEditRequestFormAction = createFormAction(postTagEditRequest, [POST_TAG_EDIT_SUCCESS, POST_TAG_EDIT_ERROR]);

export const postTagAutomationActivateRequest = (postTagId, ignoreCheck = false, showModal = true) => ({
    type: POST_TAG_AUTOMATION_ACTIVATE_REQUEST,
    payload: { postTagId, ignoreCheck, showModal }
});

export const postTagAutomationActivateSuccess = (postTag, postTagId) => ({
    type: POST_TAG_AUTOMATION_ACTIVATE_SUCCESS,
    payload: { postTag, postTagId }
});

export const postTagAutomationActivateError = (error, postTagId) => ({
    type: POST_TAG_AUTOMATION_ACTIVATE_ERROR,
    payload: { error, postTagId }
});

export const postTagAutomationDeactivateRequest = (postTagId) => ({
    type: POST_TAG_AUTOMATION_DEACTIVATE_REQUEST,
    payload: { postTagId }
});

export const postTagAutomationDeactivateSuccess = (postTag, postTagId) => ({
    type: POST_TAG_AUTOMATION_DEACTIVATE_SUCCESS,
    payload: { postTag, postTagId }
});

export const postTagAutomationDeactivateError = (error, postTagId) => ({
    type: POST_TAG_AUTOMATION_DEACTIVATE_ERROR,
    payload: { error, postTagId }
});

export const postTagsRemoveRequest = ({ postTagIds }) => ({
    type: POST_TAGS_REMOVE_REQUEST,
    payload: { postTagIds }
});

export const postTagsRemoveSuccess = (ids) => ({
    type: POST_TAGS_REMOVE_SUCCESS,
    payload: { ids }
});

export const postTagsRemoveError = (error) => ({
    type: POST_TAGS_REMOVE_ERROR,
    payload: error
});

export const postTagsRemoveRequestFormAction = createFormAction(postTagsRemoveRequest, [POST_TAGS_REMOVE_SUCCESS, POST_TAGS_REMOVE_ERROR]);

export const postTagForceRemoveRequest = ({ ids: postTagIds }) => ({
    type: POST_TAG_FORCE_REMOVE_REQUEST,
    payload: { postTagIds }
});

export const postTagForceRemoveSuccess = (ids) => ({
    type: POST_TAG_FORCE_REMOVE_SUCCESS,
    payload: { ids }
});

export const postTagForceRemoveError = (error) => ({
    type: POST_TAG_FORCE_REMOVE_ERROR,
    payload: error
});

export const postTagsForceRemoveRequestFormAction = createFormAction(postTagForceRemoveRequest, [POST_TAG_FORCE_REMOVE_SUCCESS, POST_TAG_FORCE_REMOVE_ERROR]);

export const sortPostTags = (sortBy) => ({
    type: SORT_POST_TAGS,
    payload: { sortBy }
});

export const toggleArchivedPostTagsFilter = () => ({
    type: TOGGLE_ARCHIVED_POST_TAGS_FILTER
});

export const postTagsArchiveRequest = (postTagIds) => ({
    type: POST_TAGS_ARCHIVE_REQUEST,
    payload: { postTagIds }
});

export const postTagsArchiveSuccess = (postTags) => ({
    type: POST_TAGS_ARCHIVE_SUCCESS,
    payload: { postTags }
});

export const postTagsArchiveError = (error) => ({
    type: POST_TAGS_ARCHIVE_ERROR,
    payload: { error }
});

export const postTagsForceArchiveRequest = ({ ids: postTagIds }) => ({
    type: POST_TAGS_FORCE_ARCHIVE_REQUEST,
    payload: { postTagIds }
});

export const postTagsForceArchiveSuccess = (postTags) => ({
    type: POST_TAGS_FORCE_ARCHIVE_SUCCESS,
    payload: { postTags }
});

export const postTagsForceArchiveError = (error) => ({
    type: POST_TAGS_FORCE_ARCHIVE_ERROR,
    payload: error
});

export const postTagsForceArchiveRequestFormAction = createFormAction(postTagsForceArchiveRequest, [POST_TAGS_FORCE_ARCHIVE_SUCCESS, POST_TAGS_FORCE_ARCHIVE_ERROR]);

export const postTagsUnarchiveRequest = (postTagIds) => ({
    type: POST_TAGS_UNARCHIVE_REQUEST,
    payload: { postTagIds }
});

export const postTagsUnarchiveSuccess = (postTags) => ({
    type: POST_TAGS_UNARCHIVE_SUCCESS,
    payload: { postTags }
});

export const postTagsUnarchiveError = (error) => ({
    type: POST_TAGS_UNARCHIVE_ERROR,
    payload: { error }
});

export const postTagRuleIdCleanUp = (postTagRuleIds) => ({
    type: POST_TAG_RULE_IDS_CLEAN_UP,
    payload: { postTagRuleIds }
});

export const deactivateAutomationIfLastPostTagRuleRemoved = (postTagId) => ({
    type: DEACTIVATE_AUTOMATION_IF_LAST_POST_TAG_RULE_REMOVED,
    payload: { postTagId }
});

export const postSearchByTagRequest = (postTagId, delayRequest = false) => ({
    type: POST_SEARCH_BY_TAG_REQUEST,
    payload: { postTagId, delayRequest }
});

export const postSearchByTagSuccess = (postTagId, posts, postPostTags) => ({
    type: POST_SEARCH_BY_TAG_SUCCESS,
    payload: { postTagId, posts, postPostTags }
});

export const postSearchByTagError = (postTagId, error) => ({
    type: POST_SEARCH_BY_TAG_ERROR,
    payload: { postTagId, error }
});

import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _toPath from 'lodash/toPath';
import { formName as metricBuilderFormName } from 'src/utils/metricBuilder';
import { postTagRuleAddFormName } from 'src/utils/postTagRule';
import { postTagEditFormName } from 'src/utils/postTag';

const setInWithPath = (
    state,
    value,
    path,
    pathIndex
) => {
    if (pathIndex >= path.length) {
        return value;
    }
    const first = path[pathIndex];
    const firstState = state && (Array.isArray(state) ? state[Number(first)] : state[first]);
    const next = setInWithPath(firstState, value, path, pathIndex + 1);

    if (!state) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(first)) {
            return { [first]: next };
        }
        const initialized = [];
        initialized[parseInt(first, 10)] = next;
        return initialized;
    }

    if (Array.isArray(state)) {
        const copy = [].concat(state);
        copy[parseInt(first, 10)] = next;
        return copy;
    }

    return Object.assign({}, state, { [first]: next });
};

export const setIn = (state, field, value) => setInWithPath(state, value, _toPath(field), 0);

export const createAsyncStatesReducerForAction = (
    payloadIdExtractor,
    startActions = [],
    endActions = [],
    shouldReducerTrigger = () => true
) => (state = {}, action) => {
    const { type, payload } = action;
    if (startActions.indexOf(type) >= 0 && shouldReducerTrigger(action)) {
        const id = payloadIdExtractor(payload);
        return Object.assign({}, state, { [id]: { isPending: true } });
    }
    if (endActions.indexOf(type) >= 0 && shouldReducerTrigger(action)) {
        const id = payloadIdExtractor(payload);
        return Object.assign({}, state, { [id]: { isPending: false } });
    }
    return state;
};

export const createAsyncStatesReducerForActionsWithErrorAndSuccessStates = (
    payloadIdExtractor,
    requestActions = [],
    successActions = [],
    errorActions = [],
    omitActions = []
) => (state = {}, action) => {
    const { type, payload } = action;
    if (requestActions.indexOf(type) >= 0) {
        const id = payloadIdExtractor(payload);
        return Object.assign({}, state, {
            [id]: {
                isPending: true, error: null, success: false, requested: true
            }
        });
    }
    if (successActions.indexOf(type) >= 0) {
        const id = payloadIdExtractor(payload);
        return Object.assign({}, state, {
            [id]: {
                isPending: false, error: null, success: true, data: _get(payload, 'data', {}), requested: true
            }
        });
    }
    if (errorActions.indexOf(type) >= 0) {
        const id = payloadIdExtractor(payload);
        const { error } = payload;
        return Object.assign({}, state, {
            [id]: {
                isPending: false, error, success: false, requested: true
            }
        });
    }

    if (omitActions.indexOf(type) >= 0) {
        const id = payloadIdExtractor(payload);
        return _omit(state, id);
    }
    return state;
};

export const initialState = {
    loading: true, data: null, error: null, requested: false
};

export const createAsyncReducer = (requestAction, successAction, errorAction) => (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case requestAction: {
            return Object.assign({}, state, {
                loading: true,
                data: null,
                error: null,
                requested: true
            });
        }
        case successAction: {
            const { results } = payload;
            return Object.assign({}, state, {
                loading: false,
                data: { results },
                error: null,
                requested: true
            });
        }
        case errorAction: {
            return Object.assign({}, state, {
                loading: false,
                data: null,
                error: payload.error,
                requested: true
            });
        }
        default:
            return state;
    }
};

export const metricBuilderFormPlugin = (state = {}, action) => {
    switch (action.type) {
        case '@@redux-form/SUBMIT':
            if (action.meta.form === metricBuilderFormName && action.meta.buttonClicked) {
                return setIn(state, 'buttonClicked', action.meta.buttonClicked);
            }
            return state;

        default:
            return state;
    }
};

export const postTagRuleAddFormPlugin = (state = {}, action) => {
    switch (action.type) {
        case '@@redux-form/SUBMIT': {
            if (action.meta.form === postTagRuleAddFormName && action.meta.ignoreCheck) {
                return setIn(state, 'ignoreCheck', action.meta.ignoreCheck);
            }
            return state;
        }
        default:
            return state;
    }
};

export const postTagEditFormPlugin = (state = {}, action) => {
    switch (action.type) {
        case '@@redux-form/SUBMIT': {
            if (action.meta.form === postTagEditFormName && action.meta.ignoreCheck) {
                return setIn(state, 'ignoreCheck', action.meta.ignoreCheck);
            }
            return state;
        }
        default:
            return state;
    }
};

export const loginRoute = '/login';
export const folderRoute = '/folder';
export const foldersRoute = '/folders';
export const dashboardRoute = '/dashboard';
export const dashboardsRoute = '/dashboards';
export const userDashboardsRoute = '/user-dashboards';
export const SharedDashboardsRoute = '/shared-dashboards';

export const dashboardRoutes = [
    folderRoute,
    foldersRoute,
    dashboardRoute,
    dashboardsRoute,
    userDashboardsRoute,
    SharedDashboardsRoute
];

export const reportRoute = '/reports';

export const reportRoutes = [
    reportRoute
];

export const discoverRoute = '/discover';
export const discoverMetricsRoute = '/discover/metrics';
export const discoverMetricRoute = '/discover/metric';
export const discoverMetricsSearchRoute = '/discover/metrics/search';
export const discoverPublicMetricsRoute = '/discover/public-metrics';
export const discoverPublicMetricsSearchRoute = '/discover/public-metrics/search';
export const discoverCustomMetricsRoute = '/discover/custom-metrics';
export const discoverCustomMetricsSearchRoute = '/discover/custom-metrics/search';
export const discoverDashboardsRoute = '/discover/dashboards';
export const discoverDashboardsSearchRoute = '/discover/dashboards/search';

export const discoverRoutes = [
    discoverRoute,
    discoverMetricsRoute,
    discoverMetricsSearchRoute,
    discoverPublicMetricsRoute,
    discoverPublicMetricsSearchRoute,
    discoverCustomMetricsRoute,
    discoverCustomMetricsSearchRoute,
    discoverDashboardsRoute,
    discoverDashboardsSearchRoute
];

export const analysisRoute = '/analysis';
export const customMetricsRoute = '/custom-metrics';
export const metricBuilderRoute = '/metric-builder';
export const metricBuilderRoutes = [metricBuilderRoute, customMetricsRoute];
export const analysisRoutes = [
    ...dashboardRoutes,
    ...reportRoutes,
    ...metricBuilderRoutes,
    ...discoverRoutes,
    analysisRoute,
];

export const profilesRoute = '/profiles';
export const adAccountsRoute = '/ad-accounts';
export const authenticationRoute = '/authentications';
export const accountRoutes = [profilesRoute, adAccountsRoute, authenticationRoute];

export const dataSourcesRoute = '/data-sources';
export const dataRoutes = [dataSourcesRoute];

export const tagManagerRoute = '/post-tag-manager';

export const tagsFacelift = '/facelift-tags';

export const postsRoute = '/posts';
export const tagsRoutes = [postsRoute, tagManagerRoute, tagsFacelift];

export const dataPushTasksRoute = '/data-push-tasks';
export const apiRoute = '/access-api';
export const integrationRoutes = [apiRoute, dataPushTasksRoute];
export const dataPushTaskRoutes = [dataPushTasksRoute];

export const userNotificationsRoute = '/user-notifications';
export const notificationRoutes = [userNotificationsRoute];

export const settingsRoute = '/settings';
export const userAccountRoute = '/settings/account';
export const securityRoute = '/settings/security';
export const preferenceRoute = '/settings/preferences';
export const userPersonalRoutes = [
    userAccountRoute,
    securityRoute,
    preferenceRoute
];

export const usersRoute = '/users';
export const spacesRoute = '/spaces';
export const planRoute = '/settings/plan';
export const billingRoute = '/settings/billing';

export const userAdminRoutes = [
    usersRoute,
    spacesRoute,
    planRoute,
    billingRoute
];

export const userRoutes = [
    ...userPersonalRoutes,
    ...userAdminRoutes
];

import { createSelector } from 'reselect';

export const getAuthProfileIdsFromStore = (state) => state.entities.authProfiles.allIds;
export const getAuthProfilesFromStore = (state) => state.entities.authProfiles.byId;

export const makeSelectAuthProfilesById = () => createSelector(
    [
        getAuthProfilesFromStore,
        (_, id) => id
    ],
    (authProfiles, id) => authProfiles[id] || false
);

export const makeSelectAuthProfileIdsByPlatformType = () => createSelector(
    [
        getAuthProfilesFromStore
    ],
    (authProfiles) => {
        const platformSpecificAuthProfileIds = {
            facebook: [],
            instagram: [],
            twitter: [],
            youtube: [],
            linkedIn: [],
            snapchatShow: [],
            tiktok: [],
            threads: [],
        };

        Object.values(authProfiles).forEach((authProfile) => {
            platformSpecificAuthProfileIds[authProfile.platformType].push(authProfile.id);
        });
        return platformSpecificAuthProfileIds;
    }
);

import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import { selectUseCasesByPlatformWithBasicInsights } from 'src/selectors/useCases';
import ListTable from 'src/components/listTable/ListTable';
import AuthUserAuthAdAccountUseCaseRow from 'src/components/views/public/authAdAccountAuthUserConnectionState/AuthUserAuthAdAccountUseCaseRow';
import Button from 'src/components/buttons/Button';
import { modalShowAuthAdAccountSelection } from 'src/actions/overlays';

const AuthUserAuthAdAccountUseCasesState = (props) => {
    const {
        authAdAccountIds,
        platformUseCases,
        platformType
    } = props;

    const handleOnEditSelectionClick = () => {
        const { modalShowAuthAdAccountSelectionAction } = props;
        modalShowAuthAdAccountSelectionAction(platformType);
    };

    return (
        <ListTable header={(
            <PlatformUseCasesTableHeader
              platformUseCases={platformUseCases}
              showUseCaseDetail
              platformType={platformType}
              connectButton={<Button label="Edit selection" onClick={handleOnEditSelectionClick} icon="edit" small />}
            />
        )}
        >
            {
                authAdAccountIds.map((authAdAccountId) => (
                    <AuthUserAuthAdAccountUseCaseRow
                      key={authAdAccountId}
                      authAdAccountId={authAdAccountId}
                      platformUseCases={platformUseCases}
                    />
                ))
            }
        </ListTable>
    );
};

AuthUserAuthAdAccountUseCasesState.propTypes = {
    platformUseCases: customPropTypes.useCases.isRequired,
    authAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    platformType: customPropTypes.platformTypes.isRequired,
    modalShowAuthAdAccountSelectionAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatformWithBasicInsights(state, ownProps.platformType, false)
});

export default connect(mapStateToProps, {
    modalShowAuthAdAccountSelectionAction: modalShowAuthAdAccountSelection
})(AuthUserAuthAdAccountUseCasesState);

import React, { useEffect } from 'react';
import * as authTransactionActions from 'src/actions/authTransactions';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import ExternalAuthentication from 'src/components/views/public/ExternalAuthentication';
import Feedback from 'src/components/feedback/Feedback';
import Footer from 'src/components/Footer';
import loadingIndicator from 'src/resources/loading-indicators/loading-pie-chart.gif';
import PropTypes from 'prop-types';
import PublicHeader from 'src/components/header/PublicHeader';
import ScrollView from 'src/components/ScrollView';
import { selectFetchByHash } from 'src/selectors/authTransactions';
import styles from 'src/stylesheets/views/public/externalAuthentication.scss';

const ExternalAuthenticationView = (props) => {
    const { getAuthTransactionByHashRequestAction, hash } = props;
    useEffect(() => {
        if (hash) {
            getAuthTransactionByHashRequestAction(hash);
        }
    }, []);

    const { authTransactionfetchDataState } = props;

    let content = <div>initial pages</div>;
    if (!hash) {
        content = <Feedback content="No hash key found." type="error" />;
    }
    if (authTransactionfetchDataState) {
        const { isPending, success, error } = authTransactionfetchDataState;
        if (isPending) {
            content = <div className={styles.states}><img alt="Validating link ..." src={loadingIndicator} /></div>;
        }

        if (!isPending && error) {
            content = <div className={styles.states}><Feedback content={error} type="error" /></div>;
        }

        if (!isPending && !error && success) {
            content = (
                <ExternalAuthentication hash={hash} />
            );
        }
    }

    return (
        <ScrollView dataViewName="external-authentication">
            <PublicHeader />
            <div className={styles.wrapper}>
                {content}
            </div>
            <Footer />
        </ScrollView>
    );
};

ExternalAuthenticationView.propTypes = {
    hash: PropTypes.string.isRequired,
    authTransactionfetchDataState: PropTypes.oneOfType([PropTypes.bool, customPropTypes.asyncReducerState]).isRequired,
    getAuthTransactionByHashRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { query } = ownProps.location;
    const hash = query.hash ? query.hash : null;
    const authTransactionfetchDataState = selectFetchByHash(state, hash);
    return {
        hash,
        authTransactionfetchDataState
    };
};

export default connect(mapStateToProps, {
    getAuthTransactionByHashRequestAction: authTransactionActions.getAuthTransactionByHashRequest
})(ExternalAuthenticationView);

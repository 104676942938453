import React, { useEffect } from 'react';
import { externalNetworkAuthenticationBootstrapRequest } from 'src/actions/socialNetworkProfileAuthentication';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SocialNetworkProfileAuthentication from 'src/components/views/public/SocialNetworkProfileAuthentication';
import ExternalSocialNetworkAccountAuthenticationView from 'src/components/views/public/ExternalSocialNetworkAccountAuthenticationView';

const ExternalSocialNetworkProfileAuthenticationView = (props) => {
    const { externalNetworkAuthenticationBootstrapRequestAction, hash, stateHash } = props;
    useEffect(() => {
        if (hash) {
            externalNetworkAuthenticationBootstrapRequestAction(hash);
        }
    }, []);

    return (
        <ExternalSocialNetworkAccountAuthenticationView hash={hash}>
            <SocialNetworkProfileAuthentication stateHash={stateHash} />
        </ExternalSocialNetworkAccountAuthenticationView>
    );
};

ExternalSocialNetworkProfileAuthenticationView.propTypes = {
    hash: PropTypes.string.isRequired,
    stateHash: PropTypes.string,
    externalNetworkAuthenticationBootstrapRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { query } = ownProps.location;
    const hash = query.hash ? query.hash : null;
    const stateHash = query.stateHash ? query.stateHash : undefined;
    return {
        hash,
        stateHash
    };
};

export default connect(mapStateToProps, {
    externalNetworkAuthenticationBootstrapRequestAction: externalNetworkAuthenticationBootstrapRequest
})(ExternalSocialNetworkProfileAuthenticationView);

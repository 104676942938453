import { createFormAction } from 'redux-form-saga';

export const DASHBOARD_REPORT_ADD_REQUEST = 'DASHBOARD_REPORT_ADD_REQUEST';
export const DASHBOARD_REPORT_ADD_SUCCESS = 'DASHBOARD_REPORT_ADD_SUCCESS';
export const DASHBOARD_REPORT_ADD_ERROR = 'DASHBOARD_REPORT_ADD_ERROR';

export const DASHBOARD_REPORT_UPDATE_REQUEST = 'DASHBOARD_REPORT_UPDATE_REQUEST';
export const DASHBOARD_REPORT_UPDATE_SUCCESS = 'DASHBOARD_REPORT_UPDATE_SUCCESS';
export const DASHBOARD_REPORT_UPDATE_ERROR = 'DASHBOARD_REPORT_UPDATE_ERROR';

export const DASHBOARD_REPORT_DEACTIVATE_REQUEST = 'DASHBOARD_REPORT_DEACTIVATE_REQUEST';
export const DASHBOARD_REPORT_DEACTIVATE_SUCCESS = 'DASHBOARD_REPORT_DEACTIVATE_SUCCESS';
export const DASHBOARD_REPORT_DEACTIVATE_ERROR = 'DASHBOARD_REPORT_DEACTIVATE_ERROR';

export const DASHBOARD_REPORT_ACTIVATE_REQUEST = 'DASHBOARD_REPORT_ACTIVATE_REQUEST';
export const DASHBOARD_REPORT_ACTIVATE_SUCCESS = 'DASHBOARD_REPORT_ACTIVATE_SUCCESS';
export const DASHBOARD_REPORT_ACTIVATE_ERROR = 'DASHBOARD_REPORT_ACTIVATE_ERROR';

export const DASHBOARD_REPORT_DELETE_REQUEST = 'DASHBOARD_REPORT_DELETE_REQUEST';
export const DASHBOARD_REPORT_DELETE_SUCCESS = 'DASHBOARD_REPORT_DELETE_SUCCESS';
export const DASHBOARD_REPORT_DELETE_ERROR = 'DASHBOARD_REPORT_DELETE_ERROR';

export const DASHBOARD_REPORTS_CLEANUP = 'DASHBOARD_REPORTS_CLEANUP';

export const dashboardReportAddRequest = (formValues) => ({
    type: DASHBOARD_REPORT_ADD_REQUEST,
    payload: { formValues }
});

export const dashboardReportAddSuccess = (dashboardReport) => ({
    type: DASHBOARD_REPORT_ADD_SUCCESS,
    payload: { dashboardReport }
});

export const dashboardReportAddError = (error) => ({
    type: DASHBOARD_REPORT_ADD_ERROR,
    payload: error
});

export const dashboardReportAddRequestFormAction = createFormAction(dashboardReportAddRequest, [
    DASHBOARD_REPORT_ADD_SUCCESS, DASHBOARD_REPORT_ADD_ERROR
]);

export const dashboardReportUpdateRequest = (formValues) => ({
    type: DASHBOARD_REPORT_UPDATE_REQUEST,
    payload: { formValues }
});

export const dashboardReportUpdateSuccess = (dashboardReport) => ({
    type: DASHBOARD_REPORT_UPDATE_SUCCESS,
    payload: { dashboardReport }
});

export const dashboardReportUpdateError = (error) => ({
    type: DASHBOARD_REPORT_UPDATE_ERROR,
    payload: error
});

export const dashboardReportUpdateRequestFormAction = createFormAction(dashboardReportUpdateRequest, [
    DASHBOARD_REPORT_UPDATE_SUCCESS, DASHBOARD_REPORT_UPDATE_ERROR
]);

export const dashboardReportDeactivateRequest = (dashboardReportId) => ({
    type: DASHBOARD_REPORT_DEACTIVATE_REQUEST,
    payload: { dashboardReportId }
});

export const dashboardReportDeactivateSuccess = (dashboardReport) => ({
    type: DASHBOARD_REPORT_DEACTIVATE_SUCCESS,
    payload: { dashboardReport }
});

export const dashboardReportDeactivateError = (error) => ({
    type: DASHBOARD_REPORT_DEACTIVATE_ERROR,
    payload: { error }
});

export const dashboardReportActivateRequest = (dashboardReportId) => ({
    type: DASHBOARD_REPORT_ACTIVATE_REQUEST,
    payload: { dashboardReportId }
});

export const dashboardReportActivateSuccess = (dashboardReport) => ({
    type: DASHBOARD_REPORT_ACTIVATE_SUCCESS,
    payload: { dashboardReport }
});

export const dashboardReportActivateError = (error) => ({
    type: DASHBOARD_REPORT_ACTIVATE_ERROR,
    payload: { error }
});

export const dashboardReportDeleteRequest = ({ dashboardReportId }) => ({
    type: DASHBOARD_REPORT_DELETE_REQUEST,
    payload: { dashboardReportId }
});

export const dashboardReportDeleteSuccess = (dashboardReportId, dashboardId) => ({
    type: DASHBOARD_REPORT_DELETE_SUCCESS,
    payload: { dashboardReportId, dashboardId }
});

export const dashboardReportDeleteError = (error) => ({
    type: DASHBOARD_REPORT_DELETE_ERROR,
    payload: error
});

export const dashboardReportDeleteRequestFormAction = createFormAction(dashboardReportDeleteRequest, [DASHBOARD_REPORT_DELETE_SUCCESS, DASHBOARD_REPORT_DELETE_ERROR]);

export const dashboardReportsCleanup = (dashboardReportIds) => ({
    type: DASHBOARD_REPORTS_CLEANUP,
    payload: { dashboardReportIds }
});

import { makeSelectAuthTransactionByHash, selectClosingState } from 'src/selectors/authTransactions';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayAction from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import NetworksAuthTransactionRequirementsConnect from 'src/components/authTransactions/NetworksAuthTransactionRequirementsConnect';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/public/externalAuthentication.scss';
import AuthTransactionTimeInfo from 'src/components/authTransactions/AuthTransactionTimeInfo';

const ExternalAuthentication = (props) => {
    const {
        modalsShowExternalAuthTransactionCloseAction,
        authTransaction,
        closingState,
    } = props;
    const { createdByUserName } = authTransaction;
    const { isPending, error, success } = closingState;
    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {createdByUserName} sent you a request to authenticate the following profiles
                </div>
                <div className={styles.descriptions}>
                    <div className={styles.left}>
                        To authenticate, continue to the social networks connection page by clicking on the buttons below.
                    </div>
                    <div className={styles.right}>
                        <AuthTransactionTimeInfo authTransaction={authTransaction} showCreatorInfo={false} prefix="The link" />
                    </div>
                </div>
            </div>
            <NetworksAuthTransactionRequirementsConnect isPublic authTransaction={authTransaction} />
            {
                success
                && (
                <div className={styles.feedback}>
                    <Feedback content="Authentication request has been successfully closed." type="success" />
                </div>
                )
            }
            {
                (error && !isPending)
                && (
                <div className={styles.feedback}>
                    <Feedback content={error} type="error" />
                </div>
                )
            }
            {
                !success
                && (
                <div className={styles.buttonWrapper}>
                    <div className={styles.info}>
                        All done? Click here to notify the sender.
                    </div>
                    <Button
                      label="Done"
                      loading={isPending}
                      action
                      stretch
                      onClick={() => { modalsShowExternalAuthTransactionCloseAction(authTransaction.id); }}
                    />
                </div>
                )
            }
        </div>
    );
};

ExternalAuthentication.propTypes = {
    hash: PropTypes.string.isRequired,
    authTransaction: PropTypes.oneOfType([PropTypes.bool, customPropTypes.authTransaction]).isRequired,
    closingState: customPropTypes.asyncReducerState,
    modalsShowExternalAuthTransactionCloseAction: PropTypes.func.isRequired
};

ExternalAuthentication.defaultProps = {
    closingState: {}
};

const mapStateToProps = () => {
    const selectAuthTransactionByHash = makeSelectAuthTransactionByHash();
    return (state, ownProps) => {
        const authTransaction = selectAuthTransactionByHash(state, ownProps.hash);
        const closingState = selectClosingState(state, authTransaction.id);
        return {
            authTransaction,
            closingState
        };
    };
};

export default connect(mapStateToProps, {
    modalsShowExternalAuthTransactionCloseAction: overlayAction.modalsShowExternalAuthTransactionClose,
})(ExternalAuthentication);

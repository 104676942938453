import { applyMiddleware, compose, createStore } from 'redux';
import { browserHistory } from 'react-router';
import createSagaMiddleware from 'redux-saga';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import publicApp from 'src/reducers/app.public';
import { createAuthenticator } from 'src/requestHandling/utils';
import { reportError } from 'src/utils/reportError';
import { routerMiddleware } from 'react-router-redux';
import sagas from 'src/sagas/app.public';

const sagaMiddleware = createSagaMiddleware({
    onError(error) { reportError(error); },
    context: {
        authenticator: createAuthenticator({
            getAccessTokenSilently: async () => 'public', logout: () => {}
        }, () => () => {}, 'Basic')
    }
});

const finalCreateStore = compose(applyMiddleware(
    routerMiddleware(browserHistory),
    loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'],
    }),
    sagaMiddleware,
))(createStore);

export default function configureStore(initialState) {
    const finalStore = finalCreateStore(publicApp, initialState);
    sagas.map((saga) => sagaMiddleware.run(saga));
    return finalStore;
}

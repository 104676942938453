import { loginRoute } from 'src/routePaths';

export const requestUrl = (process.env.NODE_ENV === 'production') ? '' : 'https://app.quintly.lo';

export const loginUrl = (process.env.NODE_ENV === 'production') ? loginRoute : `https://app.quintly.lo${loginRoute}`;

export const appUrl = (process.env.NODE_ENV === 'production') ? '/' : 'https://app.quintly.lo:8090';
export const appFullUrl = (process.env.NODE_ENV === 'production') ? window.location.origin : 'https://app.quintly.lo:8090';

export const onboardingUrl = (process.env.NODE_ENV === 'production') ? '/onboarding' : 'https://app.quintly.lo:8091';
export const onboardingFinishedUrl = (process.env.NODE_ENV === 'production') ? '/dashboards' : 'https://app.quintly.lo:8090/dashboards';
export const publicAppUrl = (process.env.NODE_ENV === 'production') ? window.location.origin : 'https://app.quintly.lo:8092';

export const metricVisualizationImagesUrl = 'https://s3.amazonaws.com/quintly-discover-images/metrics/visualizations/';
export const dashboardTemplateImagesUrl = 'https://s3.amazonaws.com/quintly-discover-images/dashboards/screenshots/';
export const dashboardTemplatePlaceholderImagesUrl = 'https://s3.amazonaws.com/quintly-discover-images/dashboards/screenshots/placeholder.png';

export const googleClientId = '179042116754.apps.googleusercontent.com';
export const googleOauthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
export const googleScopeUrl = 'https://www.googleapis.com/auth/';
export const googleResponseType = 'code';
export const googlePrompt = 'consent';
export const googleAccessType = 'offline';

export const snapchatClientId = (process.env.NODE_ENV === 'production') ? 'e219f631-698b-43c8-82c4-7308f52e6031' : '7a56bc03-9f0c-4d19-b46d-a252b6f73e47';
export const snapScopUrl = 'https://auth.snapchat.com/oauth2/api/';
export const snapchatOauthUrl = 'https://accounts.snapchat.com/accounts/oauth2/auth';
export const snapchatResponseType = 'code';

export const linkedInClientId = '86sw43nqg4qyo6';
export const linkedInOauthUrl = 'https://www.linkedin.com/oauth/v2/authorization';
export const linkedInResponseType = 'code';

export const tiktokOauthUrl = 'https://www.tiktok.com/v2/auth/authorize';
export const tiktokClientKey = '7192684179597819906';
export const tiktokResponseType = 'code';

export const threadsOauthUrl = 'https://threads.net/oauth/authorize';
export const threadsClientKey = '488240536902788';
export const threadsResponseType = 'code';

export const sentryConfig = {
    dsn: 'https://12b4495814ce4283be326ec477346dd7@sentry.io/266801',
    environment: process.env.NODE_ENV,
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.'
    ],
    ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    maxBreadcrumbs: 10
};

export const facebookSdkOptions = {
    appId: '274486962569350',
    status: true,
    cookie: true,
    version: 'v16.0'
};

export const devToolConfig = {
    features: {
        pause: false, // start/pause recording of dispatched actions
        lock: false, // lock/unlock dispatching actions and side effects
        persist: false, // persist states on page reloading
        export: false, // export history of actions in a file
        import: false, // import history of actions from a file
        jump: true, // jump back and forth (time travelling)
        skip: false, // skip (cancel) actions
        reorder: false, // drag and drop actions in the history list
        dispatch: false, // dispatch custom actions or action creators
        test: false // generate tests for the selected actions
    },
    maxAge: 50
};

export const encodeUrlParams = (params) => Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { socialNetworkAdAccountAuthenticationConnectRequest } from 'src/actions/socialNetworkAdAccountAuthentication';
import AdAccountSocialNetworkButtons from 'src/components/views/adAccounts/AdAccountSocialNetworkButtons';
import SocialNetworkAuthenticationHeader from 'src/components/views/public/SocialNetworkAuthenticationHeader';
import styles from 'src/stylesheets/views/public/externalSocialNetworkAuthentication.scss';
import AuthAdAccountAuthUserConnectionState
    from 'src/components/views/public/authAdAccountAuthUserConnectionState/AuthAdAccountAuthUserConnectionState';

const SocialNetworkAdAccountAuthentication = (props) => {
    const { socialNetworkAdAccountAuthenticationConnectRequestAction } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <SocialNetworkAuthenticationHeader accountType="adAccounts" />
                    <AdAccountSocialNetworkButtons action={socialNetworkAdAccountAuthenticationConnectRequestAction} />
                </div>
                <AuthAdAccountAuthUserConnectionState />
            </div>
        </div>
    );
};

SocialNetworkAdAccountAuthentication.propTypes = {
    socialNetworkAdAccountAuthenticationConnectRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    socialNetworkAdAccountAuthenticationConnectRequestAction: socialNetworkAdAccountAuthenticationConnectRequest
})(SocialNetworkAdAccountAuthentication);

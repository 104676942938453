import React from 'react';
import styles from 'src/stylesheets/views/public/authUserConnectionState.scss';
import { selectCreatedByUserName } from 'src/selectors/externalNetworkAuthentication';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthAccountEmptyState = ({ createdByUserName }) => (
    <div className={styles.empty}>
        <div className={styles.emptyInfoTitle}>Why are we asking you to log into your account?</div>
        <div className={styles.emptyInfoText}>
            To access private data, we require the account to be authenticated by an admin user.&nbsp;
            {createdByUserName} does not have admin rights and therefore is asking you to authenticate with your permissions.
        </div>
    </div>
);

AuthAccountEmptyState.propTypes = {
    createdByUserName: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    createdByUserName: selectCreatedByUserName(state)
});

export default connect(mapStateToProps)(AuthAccountEmptyState);

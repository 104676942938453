import ErrorPage from 'src/components/views/public/ErrorPage';
import Impersonate from 'src/components/views/public/Impersonate';
import { Redirect, Route } from 'react-router';
import AppPublic from 'src/components/AppPublic';
import ExternalAuthTransactionView from 'src/components/views/public/ExternalAuthTransactionView';
import Login from 'src/components/views/public/Login';
import PageNotFound from 'src/components/views/PageNotFound';
import React from 'react';
import UserRegistration from 'src/components/views/public/UserRegistration';
import ExternalSocialNetworkProfileAuthenticationView from 'src/components/views/public/ExternalSocialNetworkProfileAuthenticationView';
import ExternalSocialNetworkAdAccountAuthenticationView from 'src/components/views/public/ExternalSocialNetworkAdAccountAuthenticationView';

export default (
    <div>
        <Redirect from="/" to="login" />
        <Route path="/" component={AppPublic}>
            <Route path="impersonate/:userId/:spaceId" components={{ content: Impersonate }} />
            <Route path="login" components={{ content: Login }} />
            <Route path="register" components={{ content: UserRegistration }} />
            <Route path="external-authentication" components={{ content: ExternalAuthTransactionView }} />
            <Route path="external-profile-authentication" components={{ content: ExternalSocialNetworkProfileAuthenticationView }} />
            <Route path="external-ad-account-authentication" components={{ content: ExternalSocialNetworkAdAccountAuthenticationView }} />
            <Route path="error-page" components={{ content: ErrorPage }} />
            <Route path="*" components={{ content: PageNotFound }} />
        </Route>
    </div>
);

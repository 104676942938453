import { createFormAction } from 'redux-form-saga';

export const DASHBOARD_METRICS_DELETE_REQUEST = 'DASHBOARD_METRICS_DELETE_REQUEST';
export const DASHBOARD_METRICS_DELETE_SUCCESS = 'DASHBOARD_METRICS_DELETE_SUCCESS';
export const DASHBOARD_METRICS_DELETE_ERROR = 'DASHBOARD_METRICS_DELETE_ERROR';

export const DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST = 'DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST';
export const DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS = 'DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS';
export const DASHBOARD_METRICS_UPDATE_LAYOUT_ERROR = 'DASHBOARD_METRICS_UPDATE_LAYOUT_ERROR';

export const DASHBOARD_METRICS_UPDATE_NOTES_REQUEST = 'DASHBOARD_METRICS_UPDATE_NOTES_REQUEST';
export const DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS = 'DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS';
export const DASHBOARD_METRICS_UPDATE_NOTES_ERROR = 'DASHBOARD_METRICS_UPDATE_NOTES_ERROR';

export const DASHBOARD_METRICS_ADD_REQUEST = 'DASHBOARD_METRICS_ADD_REQUEST';
export const DASHBOARD_METRICS_ADD_SUCCESS = 'DASHBOARD_METRICS_ADD_SUCCESS';
export const DASHBOARD_METRICS_ADD_ERROR = 'DASHBOARD_METRICS_ADD_ERROR';

export const DASHBOARD_METRICS_UPDATE_SETTINGS_REQUEST = 'DASHBOARD_METRICS_UPDATE_SETTINGS_REQUEST';
export const DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS = 'DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS';
export const DASHBOARD_METRICS_UPDATE_SETTINGS_ERROR = 'DASHBOARD_METRICS_UPDATE_SETTINGS_ERROR';

export const DASHBOARD_METRIC_CLONE_REQUEST = 'DASHBOARD_METRIC_CLONE_REQUEST';
export const DASHBOARD_METRIC_CLONE_SUCCESS = 'DASHBOARD_METRIC_CLONE_SUCCESS';
export const DASHBOARD_METRIC_CLONE_ERROR = 'DASHBOARD_METRIC_CLONE_ERROR';

export const DASHBOARD_METRIC_MOVE_REQUEST = 'DASHBOARD_METRIC_MOVE_REQUEST';
export const DASHBOARD_METRIC_MOVE_SUCCESS = 'DASHBOARD_METRIC_MOVE_SUCCESS';
export const DASHBOARD_METRIC_MOVE_ERROR = 'DASHBOARD_METRIC_MOVE_ERROR';

export const DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE = 'DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE';

export const dashboardMetricsDeleteRequest = (dashboardId, dashboardMetricId, metricId, callbacks = {}) => ({
    type: DASHBOARD_METRICS_DELETE_REQUEST,
    payload: { dashboardId, dashboardMetricId, metricId },
    meta: { callbacks }
});

export const dashboardMetricsDeleteSuccess = (dashboardId, dashboardMetricId, metricId) => ({
    type: DASHBOARD_METRICS_DELETE_SUCCESS,
    payload: { dashboardId, dashboardMetricId, metricId }
});

export const dashboardMetricsDeleteError = (dashboardId, dashboardMetricId, error) => ({
    type: DASHBOARD_METRICS_DELETE_ERROR,
    payload: { dashboardId, dashboardMetricId, error }
});

export const dashboardMetricsUpdateLayoutRequest = (dashboardId, newLayout) => ({
    type: DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST,
    payload: { dashboardId, newLayout }
});

export const dashboardMetricsUpdateLayoutSuccess = (dashboardId, newLayout, orderedDashboardWidgetIds) => ({
    type: DASHBOARD_METRICS_UPDATE_LAYOUT_SUCCESS,
    payload: { dashboardId, newLayout, orderedDashboardWidgetIds }
});

export const dashboardMetricsUpdateLayoutError = (dashboardId, newLayout, error) => ({
    type: DASHBOARD_METRICS_UPDATE_LAYOUT_ERROR,
    payload: { dashboardId, newLayout, error }
});

export const dashboardMetricsAddRequest = (dashboardId, metricId, isTable) => ({
    type: DASHBOARD_METRICS_ADD_REQUEST,
    payload: { dashboardId, metricId, isTable }
});

export const dashboardMetricsAddSuccess = (dashboardId, dashboardMetric, metric) => ({
    type: DASHBOARD_METRICS_ADD_SUCCESS,
    payload: { dashboardId, dashboardMetric, metric }
});

export const dashboardMetricsAddError = (dashboardId, error) => ({
    type: DASHBOARD_METRICS_ADD_ERROR,
    payload: { dashboardId, error }
});

export const dashboardMetricsUpdateSettingsRequest = (payload) => ({
    type: DASHBOARD_METRICS_UPDATE_SETTINGS_REQUEST,
    payload
});

export const dashboardMetricsUpdateSettingsSuccess = (dashboardMetricId, newSettings) => ({
    type: DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS,
    payload: { dashboardMetricId, newSettings }
});

export const dashboardMetricsUpdateSettingsError = (error) => ({
    type: DASHBOARD_METRICS_UPDATE_SETTINGS_ERROR,
    payload: error
});

export const dashboardMetricsCleanupAfterDashboardDelete = (relatedDashboardMetricIds) => ({
    type: DASHBOARD_METRICS_CLEANUP_AFTER_DASHBOARD_DELETE,
    payload: { relatedDashboardMetricIds }
});

export const metricSettingsTileUpdateRequestFormAction = createFormAction(
    dashboardMetricsUpdateSettingsRequest,
    [DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS, DASHBOARD_METRICS_UPDATE_SETTINGS_ERROR]
);

export const dashboardMetricCloneRequest = ({
    dashboardMetricId, alias, sourceDashboardId, destinationDashboardId
}) => ({
    type: DASHBOARD_METRIC_CLONE_REQUEST,
    payload: {
        dashboardMetricId, alias, sourceDashboardId, destinationDashboardId
    }
});

export const dashboardMetricCloneSuccess = (dashboardMetric) => ({
    type: DASHBOARD_METRIC_CLONE_SUCCESS,
    payload: { dashboardMetric }
});

export const dashboardMetricCloneError = (error) => ({
    type: DASHBOARD_METRIC_CLONE_ERROR,
    payload: error
});

export const dashboardMetricCloneRequestFormAction = createFormAction(
    dashboardMetricCloneRequest,
    [DASHBOARD_METRIC_CLONE_SUCCESS, DASHBOARD_METRIC_CLONE_ERROR]
);

export const dashboardMetricMoveRequest = (dashboardMetricId, sourceDashboardId, destinationDashboardId) => ({
    type: DASHBOARD_METRIC_MOVE_REQUEST,
    payload: {
        dashboardMetricId, sourceDashboardId, destinationDashboardId
    }
});

export const dashboardMetricMoveSuccess = (dashboardMetric, sourceDashboardId, removedDashboardMetricId) => ({
    type: DASHBOARD_METRIC_MOVE_SUCCESS,
    payload: { dashboardMetric, sourceDashboardId, removedDashboardMetricId }
});

export const dashboardMetricMoveError = (error) => ({
    type: DASHBOARD_METRIC_MOVE_ERROR,
    payload: { error }
});

export const dashboardMetricsUpdateNotesRequest = (dashboardMetricId, dashboardId, notes) => (
    {
        type: DASHBOARD_METRICS_UPDATE_NOTES_REQUEST,
        payload: {
            dashboardMetricId, dashboardId, notes
        }
    }
);

export const dashboardMetricsUpdateNotesSuccess = (dashboardMetricId, newSettings) => (
    {
        type: DASHBOARD_METRICS_UPDATE_NOTES_SUCCESS,
        payload: {
            dashboardMetricId,
            newSettings
        }
    }
);

export const dashboardMetricsUpdateNotesError = (dashboardMetricId) => (
    {
        type: DASHBOARD_METRICS_UPDATE_NOTES_ERROR,
        payload: {
            dashboardMetricId
        }
    }
);

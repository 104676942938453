export const TIKTOK_AUTH_USER_CONNECT_REQUEST = 'TIKTOK_AUTH_USER_CONNECT_REQUEST';
export const TIKTOK_AUTH_USER_CONNECT_SUCCESS = 'TIKTOK_AUTH_USER_CONNECT_SUCCESS';
export const TIKTOK_AUTH_USER_CONNECT_ERROR = 'TIKTOK_AUTH_USER_CONNECT_ERROR';

export const TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const TIKTOK_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'TIKTOK_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const tiktokAuthUserConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: TIKTOK_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const tiktokAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
) => ({
    type: TIKTOK_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const tiktokAuthUserConnectError = (identifier, error) => ({
    type: TIKTOK_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const tiktokAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: TIKTOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const tiktokAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const tiktokAuthUserExternalConnectError = (identifier, error) => ({
    type: TIKTOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});

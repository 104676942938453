import quintlyPublicLogo from 'src/resources/images/facelift-logo-public.svg';
import React from 'react';
import styles from 'src/stylesheets/publicHeader.scss';

const PublicHeader = () => (
    <div className={styles.header}>
        <img className={styles.logo} src={quintlyPublicLogo} alt="empty" />
    </div>
);

export default PublicHeader;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { externalSocialNetworkByHashRequest } from 'src/actions/socialNetworkProfileAuthentication';
import { makeSelectAuthProfileIdsByPlatformType } from 'src/selectors/authProfiles';
import * as customPropTypes from 'src/customPropTypes';
import AuthUserAuthProfileUseCasesState from 'src/components/views/public/authProfileAuthUserConnectionState/AuthUserAuthProfileUseCasesState';
import styles from 'src/stylesheets/views/public/authUserConnectionState.scss';
import { selectExternalNetworkAuthenticationConnectState } from 'src/selectors/externalNetworkAuthentication';
import classnames from 'classnames';
import Feedback from 'src/components/feedback/Feedback';
import AuthUserGrantedPermissionConfirmationInfo from 'src/components/views/public/AuthUserGrantedPermissionConfirmationInfo';
import AuthAccountEmptyState from 'src/components/views/public/AuthAccountEmptyState';
import PropTypes from 'prop-types';

const AuthUserAuthProfileConnectedStates = (props) => {
    const {
        authProfileIdsByPlatformType,
        connectState,
        stateHash,
        externalSocialNetworkByHashRequestAction
    } = props;

    useEffect(() => {
        if (stateHash) {
            externalSocialNetworkByHashRequestAction(stateHash);
        }
    }, []);

    const {
        facebook, instagram, linkedIn, twitter, youtube, tiktok, snapchatShow, threads
    } = authProfileIdsByPlatformType;
    const { error, isPending } = connectState;
    const hasAnyAuthUserAuthenticated = (
        facebook.length > 0
        || instagram.length > 0
        || youtube.length > 0
        || twitter.length > 0
        || linkedIn.length > 0
        || tiktok.length > 0
        || threads.length > 0
    );

    return (
        <div className={classnames(styles.wrapper, { [styles.loading]: isPending })}>
            <div className={styles.error}>
                {
                    error && <Feedback content={error.message} type="error" />
                }
            </div>
            {
                hasAnyAuthUserAuthenticated && <div className={styles.border} />
            }
            {
                facebook.length > 0 && (
                    <div className={styles.bottomBorder}>
                        <AuthUserAuthProfileUseCasesState platformType="facebook" authProfileIds={facebook} />
                    </div>
                )
            }
            {
                instagram.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="instagram" authProfileIds={instagram} />
                    </div>
                )
            }
            {
                youtube.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="youtube" authProfileIds={youtube} />
                    </div>
                )
            }
            {
                snapchatShow.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="snapchatShow" authProfileIds={snapchatShow} />
                    </div>
                )
            }
            {
                twitter.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="twitter" authProfileIds={twitter} />
                    </div>
                )
            }
            {
                linkedIn.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="linkedIn" authProfileIds={linkedIn} />
                    </div>
                )
            }
            {
                tiktok.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="tiktok" authProfileIds={tiktok} />
                    </div>
                )
            }
            {
                threads.length > 0 && (
                    <div className={classnames(styles.bottomBorder, styles.topPadded)}>
                        <AuthUserAuthProfileUseCasesState platformType="threads" authProfileIds={threads} />
                    </div>
                )
            }
            {
                (
                    facebook.length === 0
                    && instagram.length === 0
                    && youtube.length === 0
                    && twitter.length === 0
                    && linkedIn.length === 0
                    && tiktok.length === 0
                    && threads.length === 0
                ) && <AuthAccountEmptyState />
            }
            {
                hasAnyAuthUserAuthenticated && (
                    <div className={styles.authenticatedInfo}>
                        <AuthUserGrantedPermissionConfirmationInfo accountType="profiles" />
                    </div>
                )
            }
        </div>
    );
};

AuthUserAuthProfileConnectedStates.propTypes = {
    authProfileIdsByPlatformType: customPropTypes.authProfileIdsByPlatformType.isRequired,
    externalSocialNetworkByHashRequestAction: PropTypes.func.isRequired,
    connectState: customPropTypes.asyncDataLoadingState.isRequired,
    stateHash: PropTypes.string
};

const makeMapStateToProps = () => {
    const selectAuthProfileIdsByPlatformType = makeSelectAuthProfileIdsByPlatformType();
    return (state) => ({
        authProfileIdsByPlatformType: selectAuthProfileIdsByPlatformType(state),
        connectState: selectExternalNetworkAuthenticationConnectState(state)
    });
};

export default connect(makeMapStateToProps, {
    externalSocialNetworkByHashRequestAction: externalSocialNetworkByHashRequest
})(AuthUserAuthProfileConnectedStates);

import React, { useEffect } from 'react';
import { socialNetworkAdAccountAuthenticationBootstrapRequest } from 'src/actions/socialNetworkAdAccountAuthentication';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExternalSocialNetworkAccountAuthenticationView from 'src/components/views/public/ExternalSocialNetworkAccountAuthenticationView';
import SocialNetworkAdAccountAuthentication from 'src/components/views/public/SocialNetworkAdAccountAuthentication';

const ExternalSocialNetworkProfileAuthenticationView = (props) => {
    const { socialNetworkAdAccountAuthenticationBootstrapRequestAction, hash } = props;
    useEffect(() => {
        if (hash) {
            socialNetworkAdAccountAuthenticationBootstrapRequestAction(hash);
        }
    }, []);

    return (
        <ExternalSocialNetworkAccountAuthenticationView hash={hash}>
            <SocialNetworkAdAccountAuthentication />
        </ExternalSocialNetworkAccountAuthenticationView>
    );
};

ExternalSocialNetworkProfileAuthenticationView.propTypes = {
    hash: PropTypes.string.isRequired,
    socialNetworkAdAccountAuthenticationBootstrapRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { query } = ownProps.location;
    const hash = query.hash ? query.hash : null;
    return { hash };
};

export default connect(mapStateToProps, {
    socialNetworkAdAccountAuthenticationBootstrapRequestAction: socialNetworkAdAccountAuthenticationBootstrapRequest,
})(ExternalSocialNetworkProfileAuthenticationView);

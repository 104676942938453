export const PROFILE_SEARCH_BY_KEYWORD_REQUEST = 'PROFILE_SEARCH_BY_KEYWORD_REQUEST';
export const PROFILE_SEARCH_BY_KEYWORD_SUCCESS = 'PROFILE_SEARCH_BY_KEYWORD_SUCCESS';
export const PROFILE_SEARCH_BY_KEYWORD_ERROR = 'PROFILE_SEARCH_BY_KEYWORD_ERROR';

export const PROFILE_SEARCH_BY_URLS_REQUEST = 'PROFILE_SEARCH_BY_URLS_REQUEST';
export const PROFILE_SEARCH_BY_URLS_SUCCESS = 'PROFILE_SEARCH_BY_URLS_SUCCESS';
export const PROFILE_SEARCH_BY_URLS_ERROR = 'PROFILE_SEARCH_BY_URLS_ERROR';

export const PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST = 'PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST';
export const PROFILE_SEARCH_BY_AUTH_PROFILES_ERROR = 'PROFILE_SEARCH_BY_AUTH_PROFILES_ERROR';
export const PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS = 'PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS';

export const PROFILE_SEARCH_SUCCESS = 'PROFILE_SEARCH_SUCCESS';
export const PROFILE_SEARCH_RESET_INPUT = 'PROFILE_SEARCH_RESET_INPUT';
export const PROFILE_SEARCH_FILTER_CHANGE = 'PROFILE_SEARCH_FILTER_CHANGE';
export const ACCOUNT_SEARCH_RESET = 'ACCOUNT_SEARCH_RESET';

export const SOCIAL_NETWORK_SEARCH_RESET = 'SOCIAL_NETWORK_SEARCH_RESET';

export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST = 'SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST';
export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_ERROR = 'SOCIAL_NETWORK_PROFILE_SHOW_LIST_ERROR';
export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_SUCCESS = 'FACEBOOK_PROFILE_SHOW_LIST_SUCCESS';

export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST = 'SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST';
export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS = 'SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS';
export const SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR = 'SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR';

export const SOCIAL_NETWORK_SEARCH_GRAPH_FORCE_CONNECT_REQUEST = 'SOCIAL_NETWORK_SEARCH_GRAPH_FORCE_CONNECT_REQUEST';
export const SOCIAL_NETWORK_SEARCH_LINKED_IN_FORCE_CONNECT_REQUEST = 'SOCIAL_NETWORK_SEARCH_LINKED_IN_FORCE_CONNECT_REQUEST';
export const SOCIAL_NETWORK_SEARCH_SNAPCHAT_FORCE_CONNECT_REQUEST = 'SOCIAL_NETWORK_SEARCH_SNAPCHAT_FORCE_CONNECT_REQUEST';

export const profileSearchByKeywordRequest = (profileSearchInput) => ({
    type: PROFILE_SEARCH_BY_KEYWORD_REQUEST,
    payload: {
        profileSearchInput
    }
});

export const profileSearchByKeywordSuccess = (profileSearchInput, searchResults, resultType, hints, errors = []) => ({
    type: PROFILE_SEARCH_BY_KEYWORD_SUCCESS,
    payload: {
        profileSearchInput,
        searchResults,
        resultType,
        hints,
        errors
    }
});

export const profileSearchByKeywordError = (profileSearchInput, error) => ({
    type: PROFILE_SEARCH_BY_KEYWORD_ERROR,
    payload: {
        profileSearchInput,
        error
    }
});

export const profileSearchSuccess = (searchResults) => ({
    type: PROFILE_SEARCH_SUCCESS,
    payload: { searchResults }
});

export const profileSearchByUrlsRequest = (profileSearchInput) => ({
    type: PROFILE_SEARCH_BY_URLS_REQUEST,
    payload: {
        profileSearchInput
    }
});

export const profileSearchByUrlsSuccess = (failedLinks, outOfProfileLimitLinks, hints) => ({
    type: PROFILE_SEARCH_BY_URLS_SUCCESS,
    payload: {
        failedLinks,
        outOfProfileLimitLinks,
        hints
    }
});

export const profileSearchByUrlsError = (error) => ({
    type: PROFILE_SEARCH_BY_URLS_ERROR,
    payload: { error }
});

export const profileSearchResetInput = () => ({
    type: PROFILE_SEARCH_RESET_INPUT
});

export const profileSearchReset = () => ({
    type: ACCOUNT_SEARCH_RESET
});

export const profileSearchFilterChange = (filterBy) => ({
    type: PROFILE_SEARCH_FILTER_CHANGE,
    payload: { filterBy }
});

export const profileSearchByAuthProfilesRequest = () => ({
    type: PROFILE_SEARCH_BY_AUTH_PROFILES_REQUEST
});

export const profileSearchByAuthProfilesSuccess = (searchResults) => ({
    type: PROFILE_SEARCH_BY_AUTH_PROFILES_SUCCESS,
    payload: {
        searchResults
    }
});

export const profileSearchByAuthProfilesError = (error) => ({
    type: PROFILE_SEARCH_BY_AUTH_PROFILES_ERROR,
    payload: { error }
});

export const socialNetworkSearchReset = () => ({
    type: SOCIAL_NETWORK_SEARCH_RESET
});

export const socialNetworkProfileShowListRequest = (network, useCaseIds) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_REQUEST,
    payload: { network, useCaseIds }
});

export const socialNetworkProfileShowListSuccess = (searchResults = {}) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_SUCCESS,
    payload: { searchResults }
});

export const socialNetworkProfileShowListError = (error) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_ERROR,
    payload: { error }
});

export const socialNetworkProfileShowListByStateRequest = (state) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_REQUEST,
    payload: { state, network: 'threads' }
});

export const socialNetworkProfileShowListByStateSuccess = (searchResults = {}) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_SUCCESS,
    payload: { searchResults }
});

export const socialNetworkProfileShowListByStateError = (error) => ({
    type: SOCIAL_NETWORK_PROFILE_SHOW_LIST_BY_STATE_ERROR,
    payload: { error }
});

export const socialNetworkSearchGraphForceConnectRequest = (accessToken) => ({
    type: SOCIAL_NETWORK_SEARCH_GRAPH_FORCE_CONNECT_REQUEST,
    payload: { accessToken }
});

export const socialNetworkSearchLinkedInForceConnectRequest = (
    accessToken,
    refreshToken,
    validUntil,
    refreshTokenValidUntil,
    platformUserId,
    platformUserName
) => ({
    type: SOCIAL_NETWORK_SEARCH_LINKED_IN_FORCE_CONNECT_REQUEST,
    payload: {
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName
    }
});

export const socialNetworkSearchSnapchatForceConnectRequest = (
    accessToken,
    expiresIn,
    refreshAccessToken,
    platformUserId,
    displayName
) => ({
    type: SOCIAL_NETWORK_SEARCH_SNAPCHAT_FORCE_CONNECT_REQUEST,
    payload: {
        accessToken,
        expiresIn,
        refreshAccessToken,
        platformUserId,
        displayName
    }
});

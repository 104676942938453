import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { isAuthTransactionOpen } from 'src/utils/authTransactionUtils';
import { makeStringSorter } from 'src/selectors/utils';

export const getAuthTransactions = (state) => state.entities.authTransactions.byId;
export const getAuthTransactionIds = (state) => state.entities.authTransactions.allIds;
export const getAsyncStatesByAction = (state) => state.entities.authTransactions.asyncStatesByAction;

export const getAuthTransactionById = (state, id) => {
    const transactions = getAuthTransactions(state);
    return transactions[id] || false;
};

export const makeSelectAuthTransactions = () => createSelector(
    [
        getAuthTransactionIds,
        getAuthTransactions
    ],
    (authTransactionIds, authTransactions) => authTransactionIds.map((authTransactionId) => authTransactions[authTransactionId])
);

export const makeSelectAuthTransactionByHash = () => {
    const selectAuthTransactions = makeSelectAuthTransactions();
    return createSelector(
        [
            selectAuthTransactions,
            (_, hash) => hash
        ],
        (authTransactions, hash) => {
            let authTransaction = false;

            for (let i = 0; i <= authTransactions.length; i++) {
                const currentAuthTransaction = authTransactions[i];
                if (currentAuthTransaction.hash === hash) {
                    authTransaction = currentAuthTransaction;
                    break;
                }
            }

            return authTransaction;
        }
    );
};

export const makeSelectAuthTransactionById = () => createSelector(
    [
        getAuthTransactionById
    ],
    (transaction) => transaction
);

export const selectClosingState = createSelector(
    [
        (state) => getAsyncStatesByAction(state).close,
        (_, id) => id
    ],
    (asyncActions, authTransactionId) => _get(asyncActions, authTransactionId, {})
);

export const selectIsReopeningState = createSelector(
    [
        (state) => getAsyncStatesByAction(state).reopen,
        (_, id) => id
    ],
    (asyncActions, authTransactionId) => _get(asyncActions, [authTransactionId, 'isPending'], false)
);

export const selectFetchByHash = createSelector(
    [
        (state) => getAsyncStatesByAction(state).fetchByHash,
        (_, identifier) => identifier
    ],
    (asyncActions, identifier) => _get(asyncActions, identifier, false)
);

export const makeSelectOpenedAndClosedTransactions = (numberOfItems = false) => {
    const selectAuthTransactions = makeSelectAuthTransactions();
    return createSelector(
        [
            selectAuthTransactions
        ],
        (authTransactions) => {
            const sortedByCreateTime = authTransactions.slice().sort(makeStringSorter('createTime', 'desc'));
            const openedAuthTransactions = [];
            const closedAuthTransactions = [];
            sortedByCreateTime.forEach((authTransaction) => {
                const { isOpen, validUntil } = authTransaction;
                const isAuthOpen = isAuthTransactionOpen(isOpen, validUntil);
                if (isAuthOpen) {
                    openedAuthTransactions.push(authTransaction);
                } else {
                    closedAuthTransactions.push(authTransaction);
                }
            });
            return {
                openedAuthTransactions: numberOfItems ? openedAuthTransactions.slice(0, numberOfItems) : openedAuthTransactions,
                closedAuthTransactions: numberOfItems ? closedAuthTransactions.slice(0, numberOfItems) : closedAuthTransactions
            };
        }
    );
};

export const makeSelectIsAuthTransactionNameUpdating = () => createSelector(
    [
        (state) => getAsyncStatesByAction(state).updateName,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, [id, 'isPending'], false)
);

export const makeSelectIsBasicAuthTransactionCreating = () => createSelector(
    [
        (state) => getAsyncStatesByAction(state).createBasic
    ],
    (asyncStates) => _get(asyncStates, ['state', 'isPending'], false)
);

import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectAuthProfilesById } from 'src/selectors/authProfiles';
import { makeSelectAuthUsersAuthProfileUseCasesByAuthProfileId } from 'src/selectors/authUsersAuthProfileUseCases';
import AuthUserAuthAccountUseCaseRow from 'src/components/views/public/AuthUserAuthAccountUseCaseRow';

const AuthUserAuthProfileUseCaseRow = (props) => {
    const {
        authProfile, authUserAuthProfileUseCases, platformUseCases
    } = props;
    return (
        <AuthUserAuthAccountUseCaseRow
          accountType="profiles"
          authAccount={authProfile}
          platformUseCases={platformUseCases}
          authUserAuthAccountUseCases={authUserAuthProfileUseCases}
        />
    );
};

AuthUserAuthProfileUseCaseRow.propTypes = {
    authProfileId: PropTypes.string.isRequired,
    authProfile: customPropTypes.authProfile.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    authUserAuthProfileUseCases: customPropTypes.authUserAuthProfileUseCases.isRequired
};

const makeMapStateToProps = () => {
    const selectAuthProfilesById = makeSelectAuthProfilesById();
    const selectAuthUsersAuthProfileUseCasesByAuthProfileId = makeSelectAuthUsersAuthProfileUseCasesByAuthProfileId();
    return (state, ownProps) => ({
        authProfile: selectAuthProfilesById(state, ownProps.authProfileId),
        authUserAuthProfileUseCases: selectAuthUsersAuthProfileUseCasesByAuthProfileId(state, ownProps.authProfileId)
    });
};

export default connect(makeMapStateToProps)(AuthUserAuthProfileUseCaseRow);

import { createFormAction } from 'redux-form-saga';

export const DASHBOARD_ADD_REQUEST = 'DASHBOARD_ADD_REQUEST';
export const DASHBOARD_ADD_SUCCESS = 'DASHBOARD_ADD_SUCCESS';
export const DASHBOARD_ADD_ERROR = 'DASHBOARD_ADD_ERROR';

export const DASHBOARD_EDIT_REQUEST = 'DASHBOARD_EDIT_REQUEST';
export const DASHBOARD_EDIT_SUCCESS = 'DASHBOARD_EDIT_SUCCESS';
export const DASHBOARD_EDIT_ERROR = 'DASHBOARD_EDIT_ERROR';

export const DASHBOARD_CLONE_REQUEST = 'DASHBOARD_CLONE_REQUEST';
export const DASHBOARD_CLONE_SUCCESS = 'DASHBOARD_CLONE_SUCCESS';
export const DASHBOARD_CLONE_ERROR = 'DASHBOARD_CLONE_ERROR';

export const DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST = 'DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST';
export const DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS = 'DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS';
export const DASHBOARD_CLONE_DASHBOARD_TEMPLATE_ERROR = 'DASHBOARD_CLONE_DASHBOARD_TEMPLATE_ERROR';

export const DASHBOARDS_GET_SUCCESS = 'DASHBOARDS_GET_SUCCESS';
export const DASHBOARDS_GET_ERROR = 'DASHBOARDS_GET_ERROR';

export const DASHBOARD_METRIC_IDS_CLEANUP_AFTER_CUSTOM_METRIC_DELETE = 'DASHBOARD_METRIC_IDS_CLEANUP_AFTER_CUSTOM_METRIC_DELETE';

export const dashboardAddRequest = ({
    name, customLogo, folderId, jumpToDashboard
}) => ({
    type: DASHBOARD_ADD_REQUEST,
    payload: {
        name, customLogo, folderId, jumpToDashboard
    }
});

export const dashboardAddSuccess = (dashboard, dashboardMetric, sharedDashboard, userDashboard) => ({
    type: DASHBOARD_ADD_SUCCESS,
    payload: {
        dashboard, dashboardMetric, sharedDashboard, userDashboard
    }
});

export const dashboardAddError = (error) => ({
    type: DASHBOARD_ADD_ERROR,
    payload: error
});

export const dashboardAddRequestFormAction = createFormAction(dashboardAddRequest, [DASHBOARD_ADD_SUCCESS, DASHBOARD_ADD_ERROR]);

export const dashboardEditRequest = ({
    id, name, customLogo
}) => ({
    type: DASHBOARD_EDIT_REQUEST,
    payload: {
        id, name, customLogo
    }
});

export const dashboardEditRequestFormAction = createFormAction(dashboardEditRequest, [DASHBOARD_EDIT_SUCCESS, DASHBOARD_EDIT_ERROR]);

export const dashboardEditSuccess = (dashboard, dashboardReports) => ({
    type: DASHBOARD_EDIT_SUCCESS,
    payload: {
        dashboard,
        dashboardReports
    },
});

export const dashboardEditError = (error) => ({
    type: DASHBOARD_EDIT_ERROR,
    payload: error
});

export const dashboardCloneRequest = (
    cloneDashboardId, folderId
) => ({
    type: DASHBOARD_CLONE_REQUEST,
    payload: {
        cloneDashboardId, folderId
    }
});

export const dashboardCloneSuccess = (cloneDashboardId, dashboard, dashboardMetrics, sharedDashboard, userDashboard) => ({
    type: DASHBOARD_CLONE_SUCCESS,
    payload: {
        cloneDashboardId, dashboard, dashboardMetrics, sharedDashboard, userDashboard
    }
});

export const dashboardCloneError = (cloneDashboardId, error) => ({
    type: DASHBOARD_CLONE_ERROR,
    payload: {
        cloneDashboardId, error
    }
});

export const dashboardCloneDashboardTemplateRequest = (dashboardTemplateId, folderId) => ({
    type: DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST,
    payload: { dashboardTemplateId, folderId }
});

export const dashboardCloneDashboardTemplateSuccess = (dashboardTemplateId, dashboard, dashboardMetrics, sharedDashboard, userDashboard, metrics) => ({
    type: DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS,
    payload: {
        dashboardTemplateId, dashboard, dashboardMetrics, sharedDashboard, userDashboard, metrics
    }
});

export const dashboardCloneDashboardTemplateError = (dashboardTemplateId, error) => ({
    type: DASHBOARD_CLONE_DASHBOARD_TEMPLATE_ERROR,
    payload: { dashboardTemplateId, error }
});

export const dashboardMetricIdsCleanUpAfterCustomMetricDelete = (dashboards) => ({
    type: DASHBOARD_METRIC_IDS_CLEANUP_AFTER_CUSTOM_METRIC_DELETE,
    payload: { dashboards }
});

export const dashboardsGetSuccess = (dashboards, dashboardReports, dashboardMetrics) => ({
    type: DASHBOARDS_GET_SUCCESS,
    payload: {
        dashboards,
        dashboardReports,
        dashboardMetrics
    }
});

export const dashboardsGetError = (error) => ({
    type: DASHBOARDS_GET_ERROR,
    payload: {
        error
    }
});

import { authTransactionRequirements } from 'src/reducers/entities/authTransactionRequirements';
import { authTransactions } from 'src/reducers/entities/authTransactions';
import { authUsers } from 'src/reducers/entities/authUsers';
import { combineReducers } from 'redux';
import { platformPermissions } from 'src/reducers/entities/platformPermissions';
import { profiles } from 'src/reducers/entities/profiles';
import { adAccounts } from 'src/reducers/entities/adAccounts';
import { accountUseCases } from 'src/reducers/entities/accountUseCases';
import { profileUseCasesAuthUsers } from 'src/reducers/entities/profileUseCasesAuthUsers';
import { useCases } from 'src/reducers/entities/useCases';
import { authProfiles } from 'src/reducers/entities/authProfiles';
import { authUsersAuthProfileUseCases } from 'src/reducers/entities/authUsersAuthProfileUseCases';
import { authAdAccounts } from 'src/reducers/entities/authAdAccount';
import { authUsersAuthAdAccountUseCases } from 'src/reducers/entities/authUsersAuthAdAccountUseCases';

export const entities = combineReducers({
    accountUseCases,
    authUsers,
    authTransactions,
    authTransactionRequirements,
    profiles,
    adAccounts,
    profileUseCasesAuthUsers,
    platformPermissions,
    useCases,
    authProfiles,
    authUsersAuthProfileUseCases,
    authAdAccounts,
    authUsersAuthAdAccountUseCases
});
